/* @flow */

import React from 'react';

import createSapien from '../sapien';

import type { TConfig } from '../sapien/types';

const Sapien = (props: TConfig) => (
  <div>{createSapien(props)}</div>
);

export default Sapien;
