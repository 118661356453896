/* @flow */

/*
 * This file lists the actions to retrieve data records from the
 * database. It makes read-only calls to the loopback API
 * Called mainly from src/pages/viewInjections/viewInjections.js
 *
 * MODIFIED
 *   $Date: Mon Mar 30 00:57:40 IDT 2020$
 *
 */

import axios from 'axios';

import type { Dispatch, GetState, ThunkAction, ReduxState } from '../types';

// const API_URL = 'https://jsonplaceholder.typicode.com/users';

const shuffleArr = arr => {
  const ind = [...Array(arr.length).keys()].sort(() => 0.5 - Math.random());
  return ind.map(i => arr[ind[i]]);
};

// Export this for unit testing more easily
/* istanbul ignore next */
export const fetchRecords = ({
  URI,
  prefix = '',
  shuffle = false
}): ThunkAction => async (dispatch: Dispatch) => {
  dispatch({ type: `${prefix}RECORDS_REQUESTING`, prefix });

  try {
    const { data } = await axios.get(URI);

    let data_ = data;
    if (data_) {
      if (!data_.length) {
        // no data for this experiment
        // throw { type: 'myException', message: 'no data'}; // generates an exception
        dispatch({ type: `${prefix}RECORDS_SUCCESS`, prefix, data: data_ });
        return;
      }

      if (shuffle) {
        // generate a random sample of indices in the array
        data_ = shuffleArr(data_);
      }

      // if (size > 0) data_ = data_.slice(0, size);
    }
    // else dispatch with the non-manipulated data
    /* istanbul ignore next */
    dispatch({ type: `${prefix}RECORDS_SUCCESS`, prefix, data: data_ });
  } catch (err) {
    /* istanbul ignore next */
    dispatch({ type: `${prefix}RECORDS_FAILURE`, prefix, err: err.message });
  }
};

/* istanbul ignore next */
const shouldFetchRecords = (state: ReduxState, prefix = ''): boolean => {
  console.log('ACTIONS state', state);
  const pattern = new RegExp(`${prefix}RECORDS_(REQUESTING|SUCCESS)`);
  const ind = state.dataRecords.readyStatus.findIndex(p => pattern.test(p));
  if (ind >= 0) return false;

  return true;
};

/* istanbul ignore next */
export const fetchRecordsIfNeeded = (opts): ThunkAction => (
  dispatch: Dispatch,
  getState: GetState
) => {
  const API_URL = `${getState().api.apiUrl}/catalogs`;
  let URI;
  let override = false; // true - always get a fresh set of images
  const query = [];
  if (opts) {
    URI = `${opts.URL ? opts.URL : API_URL}`;
    // const token = opts.token ? `access_token=${opts.token}` : '';
    //query.push(token);
    //query.push(opts.filter);
    //URI += query.join('&');
    override = opts.override ? opts.override : false;
  }
  console.log('query:', query);
  console.log('opts:', opts);
  // const prefix = opts && opts.prefix ? opts.prefix : 'RECORDS';
  /* istanbul ignore next */
  if (override || shouldFetchRecords(getState(), opts.prefix))
    return dispatch(fetchRecords({ URI, ...opts }));

  /* istanbul ignore next */
  return new Promise(res => res(new Error('no data records this time')));
};

/*
# per hierarchy level
$HDR = 'Accept: application/json';
$out .= "database totals\n";
$out .= "-" x 15 . "\n";
for $h ( 4, 8, 12, 16 ) { # hierarchy label lengths of the 4 levels that we have

  # type of catalog object
  $what_type = '{"where" : {"hierarchy" : {"regexp" : "^.{' . $h . '}$"} } }';
  $what_type = escape_json $what_type;
  $cmd = qq( curl -X GET -H $HDR 'http://localhost:3306/api/catalogs/findOne?filter=$what_type&access_token=$access_token' 2> /dev/null );
  #print "$cmd";
  chomp ( $res = qx($cmd) );
  $res_decoded = decode_json $res;
  my $a = $res_decoded->{type};

  # count of catalog objects of type $what_type
  $where ='{"hierarchy" : {"regexp" : "^.{' . $h .'}$"} }';
  $where = escape_json $where;
  $cmd = qq( curl -X GET -H $HDR 'http://localhost:3306/api/catalogs/count?where=$where&access_token=$access_token' 2> /dev/null );
  #print "$cmd";
  chomp ( $res = qx($cmd) );
    $res_decoded = decode_json $res;
  my $b = $res_decoded->{count};

  $out .= "$a: $b\n";

}
*/
