/*
 * MODIFIED
 *    $Date: $
 */

import Crossref from './Crossref';

export default Crossref;


