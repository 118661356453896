/*
 * MODIFIED
 *    $Date: $
 */

import _ from 'lodash';

const initialState = {
  readyStatus: ['SESSION_INVALID'], // status of each slot loading
  err: null,
  session: {},
  timing: {} // latest loading time
};

export default (state: State = initialState, action: Action): State => {
  console.log('REDUCER_STATE', state, action);
  const key = 'session';
  const pattern = new RegExp(`^SESSION`);
  const ind = state.readyStatus.findIndex(p => pattern.test(p));
  const newReadyStatus =
    ind >= 0
      ? state.readyStatus.map(e => (pattern.test(e) ? action.type : e))
      : [...state.readyStatus, action.type];
  const newTiming = _.assign({}, state.timing, {
    [key]: action.time ? action.time : new Date().getTime()
  });
  console.log('REDUCER_TOTALS READY STATUS', newReadyStatus);
  console.log('REDUCER_TOTALS TIMING STATUS', newTiming);

  switch (action.type) {
    case `SESSION_REQUESTING`:
    case `SESSION_NEEDUPDATE`:
      console.log(action.type);
      return _.assign({}, state, {
        timing: newTiming,
        readyStatus: newReadyStatus
      });
    case `SESSION_FAILURE`:
      console.log(action.type);
      return _.assign({}, state, {
        readyStatus: newReadyStatus,
        timing: newTiming,
        err: action.err
      });
    case `SESSION_SUCCESS`:
      console.log(action.type);
      return _.assign({}, state, {
        readyStatus: newReadyStatus,
        timing: newTiming,
        [key]: action.data
      });
    default:
      return state;
  }
}
