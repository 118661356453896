/*
 * MODIFIED
 *    $Date: Thu Jul 15 16:13:55 IDT 2021$
 */

import  React from 'react';
import { Route, Switch } from "react-router-dom";
import { useLocation, useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';

import Gene from './pages/Gene';
import DiseaseHeatmapContent from './pages/Disease';
import Contact from './pages/Contact';
import Cite from './pages/Cite';
import { Home, FAQ } from './pages/Home';
import Crossref from './pages/Crossref';
import SearchPage from './pages/Search';
import Trial from './pages/trial';

const Routes = ({ api, session }) => {
  /*
  let location = useLocation();
  let match = useRouteMatch();
  console.log( 'LOCAT', location )
  console.log( 'MATCH', match )
  */
  return (
	<Switch>
	    <Route exact path="/" component={Home} />
	    <Route path="(/g)?/gene/:geneId"
	      render={(props) => <Gene api={api} session={session}/>}
	    />
	    <Route path="(/g)?/disease/:disease"
	      render={(props) => <DiseaseHeatmapContent api={api} session={session} />}
	    />
	    <Route path="(/g)?/Contact" component={Contact} />
	    <Route path="(/g)?/Cite" component={Cite} />
	    <Route path="(/g)?/Crossref" component={Crossref} />
	    <Route path="(/g)?/FAQ" component={FAQ} />
	    <Route path="(/g)?/Search"
              render={() => <SearchPage api={api} session={session} />}
              />
	    <Route path="/trial" 
	      render={(props) => <Trial api={api} session={session} />}
	      />
	</Switch>
  );
}

Routes.defaultProps = {
  api: {
    apiHost: 'http://localhost:8000',
    apiUrl: '/api'
  },
  session: null
};

Routes.propTypes = {
  api: PropTypes.instanceOf(Object),
  session: PropTypes.instanceOf(Object)
};

export default Routes;
