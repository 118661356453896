/* eslint no-unused-vars: warn */
/* eslint no-nested-ternary: warn */

/*
 * MODIFIED
 *    $Date: Thu May 14 16:02:31 IDT 2020$
 */

import React from 'react';
import {
  useTable, /* utils, */ useExpanded, useSortBy
} from 'react-table';
import PropTypes from 'prop-types';

// import CssBaseline from '@material-ui/core/CssBaseline'
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {makeStyles} from '@material-ui/core/styles';
// import { useDispatch, useSelector } from 'react-redux';
//import './index.scss';

const LETTERS = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'];

const useStyles2 = makeStyles({
  root: {
    width: '100%'
  },
  table: {
    minWidth: 500,
    '@media print': {
      height: '100%'
    }
  },
  tableWrapper: {
    overflowX: 'visible',
    '@media print': {
      overflowY: 'visible',
      boxShadow: 'none'
    }
  }
});

// ----------------------------------------------------------------------
function Table({
  columns,
  data,
  rowOnLoad = (props) => props,
  // highlight,
  // modified,
  wantSort = false
}) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    // getTableHeaderProps,
    headerGroups,
    rows,
    prepareRow,
    // flatColumns,
    state: {expanded}
  } = useTable(
    {
      columns,
      data
    },
    useSortBy,
    useExpanded
  );

  // const dataRecords = useSelector(state => state.dataRecords);
  // const dispatch = useDispatch();
  // we cannot import dispatch or any other hook inside
  // renderRowSubComponent like we wanted. For more info:
  // https://stackoverflow.com/questions/57397395/react-has-detected-a-change-in-the-order-of-hooks-but-hooks-seem-to-be-invoked

  /*
  const newExpandedState = rows.map(r => r.isExpanded);
   const [lastExpanded] = newExpandedState
    .map((r, i) => ({ k: i, v: r !== rowsExpandedState[i] }))
    .filter(({ v }) => v); */
  // rowsExpandedState = newExpandedState;

  const debug = false;
  const classes = useStyles2();

  // Render the UI for your table
  return (
    <>
      {debug && (
        <pre>
          <code>{JSON.stringify({
            expanded
          }, null, 2)}</code>
        </pre>
      )}
      <div className={classes.tableWrapper}>
        <MaUTable
          stickyHeader
          className={classes.table}
          aria-label="sticky table"
          {...getTableProps(
            {
              style: {
                width: '100%'
              }
            } /* force the table to minimize width */
          )}
        >
          <TableHead style={{
	    padding: 0
	    /* , display: 'block' */
	  }}>
            {headerGroups.map((headerGroup, i) => (
              <React.Fragment key={`row${LETTERS[i % 26]}`}>
                <TableRow
                  {...headerGroup.getHeaderGroupProps( )}
                  style={{
                    height: 10, padding: 0
                  }}
                >
                  {headerGroup.headers.map(column => (
                    <TableCell
                      {...column.getHeaderProps(wantSort ? column.getSortByToggleProps({
                        className: column.className
                      }) : {
                        className: column.className
                      } )}
                      style={{
                        height: 'auto !important',
                        padding: '0 16px 0 16px',
                        fontWeight: 'bold'
                      }}
                    >
                      {column.render('Header')}
		      { wantSort ?
		      <span>
                          {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                        </span> : null }
                    </TableCell>
                  ))}
                </TableRow>
              </React.Fragment>
            ))}
          </TableHead>
          <TableBody
            style={
              {
                /* display:'block'  */
              }
            }
          >
            {rows.map(row => {
              prepareRow(row);
              /* ...row.getRowProps() */
              return (
                <React.Fragment key={`row${row.index}`}>
                  <TableRow
                    {...row.getRowProps( rowOnLoad )}
                  >
                    {row.cells.map(cell => (
                      <TableCell
                        {...cell.getCellProps({
                          className:
                            typeof cell.column.className === 'function'
                              ? cell.column.className(cell.value)
                              : cell.column.className
                        })}
                        style={{
                          height: 'auto !important',
                          padding: '0 16px 0 16px'
                        }}
                      >
                        {typeof cell.value ==  "number" ?
			    parseInt(cell.value) !== cell.value ? // !int ?
			    cell.value.toPrecision(3)
			  //( Math.abs(cell.value) < 1e-15 ? '<1e-15' : cell.value.toPrecision(3) )
			    : cell.render('Cell')
			  : cell.render('Cell')}
                      </TableCell>
                    ))}
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </MaUTable>
      </div>
    </>
  );
}

Table.propTypes = {
  columns: PropTypes.instanceOf(Array),
  modified: PropTypes.number
};

Table.defaultProps = {
  columns: null,
  modified: 1
};

export default Table;
