/*
 * MODIFIED
 *    $Date: $
 */

import React from 'react';

const Failed = ({
  title, ...rest
}) => (
  <div className="alert-box" {...rest} >{title}</div> 
);

export default Failed;
