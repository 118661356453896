/*
 * MODIFIED
 *    $Date: Wed Nov 18 11:38:14 IST 2020$
 */

import React, {
  useState,
  createRef,
} from 'react';
//import { Nav } from 'react-bootstrap';
import { ContextMenuTrigger } from "react-contextmenu";
import { DynamicContextMenu } from '../../components';
import './Contact.css';

const hash = (subj) => subj.split('').reverse().join('')
//const email = ''
const liame = 'li.ca.ijuh.sc@cirbaf_rp'; // hash(email);

const redirect = (mailAddress) => 
  window.location.href = `mailto:${hash(mailAddress)}`;

const MENU_TYPE = 'DYNAMIC';

  const StyleRTL = {
    direction: 'rtl',
    unicodeBidi: 'bidi-override',
    display: 'contents'
  };

/*
const HiddenSubjectLink = (props) => {

  const { onItemClick, children } = props;
  const [ firstChild, ] = children;

  return (
    <>
	<Nav.Link
	  href={null}
	  onClick={ () => onItemClick(firstChild) }
	  style={StyleRTL}
	>{children}</Nav.Link>
    </>);
}
*/

  const specialHidden = { 
    height:0,
    position:'absolute',
    zIndex: -1,
    opacity: 0.1
  }

const collect = props => props;

const Contact = () => {

  const textArea = createRef();
  const [logs, setLog] = useState([]);
  const [copySuccess, setCopySuccess] = useState('');

  // handle the click inside the context menu
  const handleClick = (e, data, target) => {

      //copyToClipboard(e)
      if (data.action === 'Copy') {
	textArea.current.value = hash(textArea.current.value) // dev hack - for some reason this does not change the actual form's value (so no need to re-hash after we select and copy)
	textArea.current.select();
	document.execCommand('copy');
	setCopySuccess('Copied!');

	setLog( [`${data.action} 1 ${data.name}`, ...logs ] );
	/*
	  return this.setState(({ logs }) => ({
	      logs: [`${data.action} 1 ${data.name}`, ...logs]
	  })); */
      }
      setLog( [` ${data.name} cannot be ${data.action.toLowerCase()}`, ...logs ] );
    /*
      this.setState(({ logs }) => ({
	  logs: [` ${data.name} cannot be ${data.action.toLowerCase()}`, ...logs]
      })); 
      */
      return ;
  }

  return (
    <>
      <div className="fabricWatermark">
      <h1 className="h4" style={{ marginTop: 10 }}>How to contact the developers?</h1>
	<div className="fabricPageContainer">
	  <p>This database project is part of the undertakings at the <a href="https://michal-linial.huji.ac.il" target="_blank" rel="noopener noreferrer">Linial lab</a></p>
	  For any inquiry, be it academic or technical, please direct your questions to&nbsp;
	    <button className="pure-display noselect">
	      <ContextMenuTrigger
		  id={MENU_TYPE} holdToDisplay={1000}
		  name={liame} wrapper={hash} onItemClick={handleClick}
		  allowRemoval={false}
		  collect={collect}
		  className="pure-display"
		>
		  <span style={StyleRTL} onClick={() => redirect(liame)}>
		  {liame}
		</span>
	      </ContextMenuTrigger>
	      <form>
		<textarea style={specialHidden}
		onChange={() => {}}
		ref={textArea}
		value={liame}
	      /></form>
	    </button>
	    <DynamicContextMenu> </DynamicContextMenu>
	</div>
      <br/>
      </div>
    </>
  );
}

export default Contact;
