/*
 * MODIFIED
 *    $Date: Thu Aug  6 23:10:37 IDT 2020$
 */

import React, { useState } from "react";
import { Search } from '../../components';
import {
  Container, Row, Col //, ButtonGroup, DropdownButton, Dropdown
} from 'react-bootstrap';
import './SearchPage.css';

const SearchPage = ( {api} ) => {

  const [ checked, setChecked ] = useState(true);

  return (
    <>
    <Container>
      <Row>
	<Col>
          <strong>Search for a gene</strong>
	  <Search api={api} selectAll={checked}/>
	</Col>
      </Row>
      <br/>
      <Row style={{ color: '#999' }}>
	<Col>
	  <div style={{
	    textAlign: 'left',
	    border: '1px dashed #ddd',
	    padding: 10,
	    borderRadius: 7
	  }}>
	    <h5>Search examples</h5>
	    <p>Gene Symbol &quot;MIB1&quot; (finds MIB1)</p>
	    <p>Gene Symbol &quot;BRCA&quot; (finds BRCA1, BRCC3, BRAT1, BRAP, &hellip;)</p>
	    <p>Uniprot id &quot;Q86YT6&quot; (finds MIB1)</p>
	    <h5>Options</h5>
	    <p>UniProt symbols are always looked up. Additionally, other search categories can be amployed</p>
	    <p>You can<button className="pure-display" onClick={ () => setChecked(true) }>check</button>or<button className="pure-display" onClick={ () => setChecked(false) }>uncheck</button>the search categories for finer lookup. For example: Searching for &quot;TIG&quot; with only <b>gene_symbol</b> checked will find TIGAR, TIGD1 .. TIGD7, and TIGIT.</p>
	    <div>
	      <h6>UCSC coordinates</h6>
		<ul style={{ listStyle: 'none' }}>
		  <li>chromosome: &quot;chr3&quot; (finds genes in chr3, chr13)</li>
		  <li>start pattern: &quot;chr8:122&quot; (finds chr8:122*, chr18:122*)</li>
		  <li>coordinate range pattern &quot;chr12:121-1210&quot;</li>
		</ul>
	    </div>
	  </div>
	</Col>
	<br />
      </Row>
    </Container>
    </>
  )
}

export default SearchPage;
