/* @flow */

/*
 * MODIFIED
 *    $Date: Tue Dec 17 14:00:56 PST 2019$
 */

import _ from 'lodash';

import type { Home, Action } from '../types';

type State = Home;

const initialState = {
  readyStatus: ['TOTALS_INVALID'], // status of each slot loading
  err: null,
  total: [], // latest slot listing (a new total name is assigned to each recorset)
  timing: {} // latest loading time
};

export default (state: State = initialState, action: Action): State => {
  console.log('REDUCER_STATE', state, action);
  const { prefix } = action;
  const key = prefix && prefix.length ? `total${prefix}` : 'dummy';

  // replace or add the new readyStatus into the state variable
  const pattern = new RegExp(`^${prefix}TOTALS`);
  const ind = state.readyStatus.findIndex(p => pattern.test(p));
  const newReadyStatus =
    ind >= 0
      ? state.readyStatus.map(e => (pattern.test(e) ? action.type : e))
      : [...state.readyStatus, action.type];
  const newTiming = _.assign({}, state.timing, {
    [key]: action.time ? action.time : new Date().getTime()
  });
  console.log('REDUCER_TOTALS READY STATUS', newReadyStatus);
  console.log('REDUCER_TOTALS TIMING STATUS', newTiming);

  switch (action.type) {
    case `${prefix}TOTALS_REQUESTING`:
    case `${prefix}TOTALS_NEEDUPDATE`:
      console.log(action.type);
      return _.assign({}, state, {
        timing: newTiming,
        readyStatus: newReadyStatus
      });
    case `${prefix}TOTALS_FAILURE`:
      console.log(action.type);
      return _.assign({}, state, {
        readyStatus: newReadyStatus,
        timing: newTiming,
        err: action.err
      });
    case `${prefix}TOTALS_SUCCESS`:
      console.log(action.type);
      return _.assign({}, state, {
        readyStatus: newReadyStatus,
        timing: newTiming,
        [key]: action.data
      });
    default:
      return state;
  }
};
