/* @flow */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import dataRecords from './data_records';
import totals from './totals';
import session from './session';
import api from './api'; // load the store's initial state with environment and config variables. see server.js, api.js

const reducers = {
  dataRecords,
  totals,
  session,
  api
};

export type Reducers = typeof reducers;
export default (history: Object) =>
  combineReducers({ router: connectRouter(history), ...reducers });
