/*
 *
 * MODIFIED
 *   $Date: $
 */

import ScrollToTop from './ScrollToTop';

export default ScrollToTop;

