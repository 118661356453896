/*
 * MODIFIED
 *    $Date: Sat Jul 25 23:55:33 IDT 2020$
 */

//import React from "react";

import PfamMap from './PfamMap';

export default PfamMap;
