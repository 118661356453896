/*
 * MODIFIED
 *    $Date: Sat Jul 25 23:14:38 IDT 2020$
 */

import  React from 'react';
import {
  useDispatch, useSelector
} from 'react-redux';
//import logo from './logo.svg';
import './App.css';

import { Header, ScrollToTop, Footer } from './components'

import { BrowserRouter as Router } from "react-router-dom";
import Routes from './routes';
import { sessionAction } from './actions';

// Get the initial state from server-side rendering

const isDev = process.env.NODE_ENV === 'development';

if (!isDev) {
  if (typeof console !== undefined) {
    //var console = {};
    console.log = function(){};
    if (typeof window !== undefined)
      window.console = console;
  }
}

console.log('[-----------------------------]', process.env)


function App() {
  let api;
  const apiHost = isDev ? 'http://localhost:8000' : '';
  console.log('APIHOST=', apiHost);
  api = { apiHost, apiUrl: '/api' }

  const dispatch = useDispatch();
  const session = useSelector(state => state.session);
  const {readyStatus} = session;
  /*
   if (!readyStatus.find(e => !e.match('SESSION_INVA')) )
    dispatch( fetchSessionIfNeeded() );
   */

  return (
    <div className="App">
      <Router>
	<>
	<Header />
	<ScrollToTop />
	<Routes className="Site-content" api={api} session={session} />
	<Footer />
	</>
      </Router>

    </div>
  );
}

const {fetchSessionIfNeeded} = sessionAction;

export default App;
