/* @flow */

/*
 * MODIFIED
 *    $Date: Tue Dec 17 14:00:56 PST 2019$
 */

import _ from 'lodash';

import type { Home, Action } from '../types';

type State = Home;

const initialState = {
  readyStatus: ['RECORDS_INVALID'], // status of each slot loading
  err: null,
  list: [], // latest slot listing (a new list name is assigned to each recorset)
  experimentId: null,
  timing: {} // latest loading time
};

export default (state: State = initialState, action: Action): State => {
  console.log('REDUCER_STATE', state, action);
  const { prefix } = action;
  const key = prefix && prefix.length ? `list${prefix}` : 'dummy';

  // replace or add the new readyStatus into the state variable
  const pattern = new RegExp(`^${prefix}RECORDS`);
  const ind = state.readyStatus.findIndex(p => pattern.test(p));
  const newReadyStatus =
    ind >= 0
      ? state.readyStatus.map(e => (pattern.test(e) ? action.type : e))
      : [...state.readyStatus, action.type];
  const newTiming = _.assign({}, state.timing, {
    [key]: action.time ? action.time : new Date().getTime()
  });
  console.log('REDUCER_NEW READY STATUS', newReadyStatus);
  console.log('REDUCER_NEW TIMING STATUS', newTiming);

  switch (action.type) {
    case `${prefix}RECORDS_REQUESTING`:
    case `${prefix}RECORDS_NEEDUPDATE`:
      console.log(action.type);
      return _.assign({}, state, {
        timing: newTiming,
        readyStatus: newReadyStatus
      });
    case `${prefix}RECORDS_FAILURE`:
      console.log(action.type);
      return _.assign({}, state, {
        readyStatus: newReadyStatus,
        timing: newTiming,
        err: action.err
      });
    case `${prefix}RECORDS_SUCCESS`:
      console.log(action.type);
      return _.assign({}, state, {
        readyStatus: newReadyStatus,
        list: action.data,
        timing: newTiming,
        [key]: action.data,
        experimentId: action.data.length ? action.data[0].experimentId : null // grab the expId from the 1st image
      });
    default:
      return state;
  }
};
