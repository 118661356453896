/* @flow */

export default {
  'Adrenal Gland': 'rgb(190, 48, 44)',
  'Bile Duct': 'rgb(213, 141, 0)',
  Bladder: 'rgb(105, 161, 236)',
  Blood: 'rgb(246, 40, 12)',
  Bone: 'rgb(156, 156, 156)',
  'Bone Marrow': 'rgb(240, 238, 55)',
  Brain: 'rgb(23, 153, 227)',
  Breast: 'rgb(223, 46, 189)',
  Cervix: 'rgb(215, 33, 93)',
  Colorectal: 'rgb(240, 119, 59)',
  Esophagus: 'rgb(52, 133, 34)',
  Eye: 'rgb(233, 110, 31)',
  'Head and Neck': 'rgb(240, 142, 26)',
  Kidney: 'rgb(38, 193, 86)',
  Liver: 'rgb(67, 221, 207)',
  Lung: 'rgb(158, 9, 219)',
  'Lymph Nodes': 'rgb(222, 61, 211)',
  'Nervous System': 'rgb(27, 114, 246)',
  Ovary: 'rgb(68, 213, 45)',
  Pancreas: 'rgb(199, 54, 207)',
  Pleura: 'rgb(215, 188, 88)',
  Prostate: 'rgb(60, 166, 205)',
  Skin: 'rgb(245, 42, 182)',
  'Soft Tissue': 'rgb(228, 222, 62)',
  Stomach: 'rgb(222, 175, 53)',
  Testis: 'rgb(218, 125, 58)',
  Thymus: 'rgb(61, 223, 204)',
  Thyroid: 'rgb(224, 75, 123)',
  Uterus: 'rgb(245, 97, 154)',
};
