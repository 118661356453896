/*
 * MODIFIED
 *    $Date: Sat Jul 25 23:19:03 IDT 2020$
 */

import React from 'react'
import Figue from '../Tabular/figue'
import _ from 'lodash'

function Clustering({
  columns,
  data
}) {
    // Data source: LinkedIn
  // Initial data are businesses that need to be clustered according to their size (nb of employees) and revenue (in mln$)

  // Create the labels and the vectors describing the data

  var labels = []; // new Array() ;
  var vectors = []; // new Array() ;
  if (_.isNil(data)) return null;
  const allKeys = _.keys(...data)
    .filter(k=>!/uniprot_id|gene_symbol|order/.test(k));
  [ ...Array(data.length).keys() ]
    .forEach( i =>
    /* for (var i = 0 ; i < data.length ; i++) */ {
    const D = data[i];
    labels[i] = D['gene_symbol'] ;
    vectors[i] = allKeys.map( k => k in D && 'pval' in D[k] ? +D[k]['pval'] : 1);
  } )

  var root = Figue.agglomerate(labels, vectors , Figue.EUCLIDIAN_DISTANCE,Figue.SINGLE_LINKAGE) ;

  var dendrogram = root.buildDendogram (2, false, true, false, false) ;
  //const lines = Figue.generateDendogram(root, 2, false ,true,false, false) ;
  //const order = lines.reverse().map( l => l.match(/([A-Z0-9]+)/g)).filter( l => l ).flat()

  return (<pre style={{ fontSize:'xx-small', width: '100%', overflow: 'scroll' }} >{dendrogram}</pre>);
}

export default Clustering;
