/*
 * MODIFIED
 *    $Date: Tue Jan 12 10:16:25 IST 2021$
 */

import React from 'react';
import './Cite.css';
import externalLinkIcon from '../Disease/external-link-ltr-icon.svg';

const Cite = () =>
  <><div className="fabricWatermark">
  <h1 className="h4" style={{ marginTop: 10 }}>How to cite this website?</h1>
  <div className="fabricPageContainer apa-ref">

  <h4 className="h5">The FABRIC framework (paper)</h4>
  <p>Nadav Brandes, Nathan Linial, Michal Linial, <strong>Quantifying gene selection in cancer through protein functional alteration bias</strong>, <em>Nucleic Acids Research</em>, Volume 47, Issue 13, 26 July 2019, Pages 6642–6655, <a href="https://doi.org/10.1093/nar/gkz546" target="_blank" rel="noopener noreferrer">doi.org/10.1093/nar/gkz546<img src={externalLinkIcon} alt='external link'/></a></p>

  <h4 className="h5">The FABRIC Portal (paper)</h4>
  <p>Guy Kelman, Nadav Brandes, and Michal Linial. <strong>The fabric cancer portal: a ranked catalogue of gene selection in tumors over the human coding genome</strong>. <em>Cancer Research</em>, page canres.3147.2020, 01 2020, <a href="http://doi.org/10.1158/0008-5472.CAN-20-3147" target="_blank" rel="noopener noreferrer">doi.org/10.1158/0008-5472.CAN-20-3147<img src={externalLinkIcon} alt='external link'/></a></p>

  <h4 className="h5">The repo</h4>
  <p>The software, written in python, is staged on <a href="https://github.com/nadavbra/fabric" target="_blank" rel="noopener noreferrer">GitHub<img src={externalLinkIcon} alt='external link'/></a></p>
  <h4 className="h5">The data</h4>
  <p>The data presented on this website are based on the Excel sheet <a href="https://academic.oup.com/nar/article/47/13/6642/5523008#supplementary-data" target="_blank" rel="noopener noreferrer">here<img src={externalLinkIcon} alt='external link'/></a></p>
  </div>
  <br/>
  </div>
  </>

export default Cite;
