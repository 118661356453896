/*
 * MODIFIED
 *    $Date: Thu Jul 15 18:06:28 IDT 2021$
 */

import  React, {
  useState,
  createRef,
  /* Component,
  Fragment, */
  useEffect
} from 'react';
import {
  useDispatch, useSelector
} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useParams} from "react-router";

import {
  Container, Row, Col, Card
} from 'react-bootstrap';
import {dataRecordsAction} from '../../actions';
import {columns, alpha} from '../../config/settings';

import {
  Table, Pfam
} from '../../components';
import {detect as detectBrowser} from 'detect-browser';
import './Gene.css';

import UCSClogo from './UCSC.png';
import GClogo from './GeneCards.png';
import Uniprotlogo from './uniprot.svg';
import RefSeqlogo from './RefSeq.png';
import cBioPortallogo from './cBioPortal.png';
import hallmarklogo from './CosmicHallmark.png';
import depmaplogo from './Depmap.svg';
import cansarlogo from './canSAR.svg';
import { ContextMenuTrigger } from "react-contextmenu";
import { DynamicContextMenu, QuestionMark, Failed } from '../../components';
import ReactTooltip from "react-tooltip";
import { useURIquery } from '../../hooks';
import magnify from '../../utils/magnify';

import _ from 'lodash';
import {
  BluColorPalette, RedColorPalette, MonoColorPalette
} from '../../utils/colorPalettes';

const MENU_TYPE = 'DYNAMIC';

const getIPDetails = () => {
  const obj = '';
    //fetch("https://api.ipify.org/?format=json")
    
    //.then( res => res.json() )
  //      .then( res => {
  //        console.log('IPADDRESS', res.ip);
  //        const ipAddress = res.ip;
  //        //fetch('http://api.wipmania.com/jsonp?callback=jsonpCallback')
  //
  //        fetch("https://ip-api.io/json/" + ipAddress,
  //	  {
  //            method: 'GET', 
  //	    referrerPolicy: "no-referrer",
  //	     headers: {
  //              'Host': "ip-api.io",
  //              'Connection': "keep-alive",
  //              'Cache-Control': "max-age=0",
  //              'Upgrade-Insecure-Requests': "1",
  //              'User-Agent': "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_12_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/80.0.3987.87 Safari/537.36",
  //              'Sec-Fetch-Dest': "document",
  //              'Accept': "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
  //              'Sec-Fetch-Site': "none",
  //              'Sec-Fetch-Mode': "navigate",
  //              'Sec-Fetch-User': "?1",
  //              'Accept-Encoding': "gzip, deflate, br",
  //              'Accept-Language': "en-US,en;q=0.9,he;q=0.8",
  //	     }
  //	  }
  //        ).then(res => res.json());
  //      });
  return (obj);
};

const collect = props => props;

const Gene = (props) => {
  let {geneId: G} = useParams();
  let [ sending, setSendRequest ] = useState(false);
  let history = useHistory();
  let query = useURIquery();

  let units = query.get("units")
  if (_.isNil(units)) units = 'gene_symbol';

  const {
    api = {
      apiHost: 'http://localhost:8000',
      apiUrl: '/api'
    }
  } = props;

  const anchor = G;

  // [b] diseases
  const opts_ = {
    totalkeys: 1,
    filter: null,
  };

  let optsDetail = _.assign({
  }, opts_, {
    prefix: 'geneDetail' + anchor,
    URL: `${api.apiHost}${api.apiUrl}/gene/${anchor}/?units=${units}`
  });

  let optsPfam = _.assign({
  }, opts_, {
    prefix: 'Pfam' + anchor,
    URL: `${api.apiHost}${api.apiUrl}/pfam/${anchor}/?units=${units}`
  });

  const optsDiseasesPerGene = _.assign({
  }, opts_, {
    prefix: anchor + 'diseases', 
    URL: `${api.apiHost}${api.apiUrl}/gene-detail/${anchor}/?units=${units}`
  });

  const [thisGene, setThisGene] = useState(G);
  const [logs, setLog] = useState([]);
  const [copySuccess, setCopySuccess] = useState('');
  const textAreas = [...Array(10).keys() ].map( () => createRef() );

  useEffect( () => {
    console.log('G HAS CHANGED', G);
    if ( thisGene !== G ) setThisGene(G);
  }, [G, thisGene] );

  const dispatch = useDispatch();
  const dataRecords = useSelector(state => state.dataRecords);
  const {readyStatus} = dataRecords;

  // dispatch database calls
  // [a] genes
  if (!readyStatus.find(e => e.match('geneDetail' + thisGene)) )
    dispatch( fetchRecordsIfNeeded(optsDetail));

  if (!readyStatus.find(e => e.match(anchor + 'diseases')))
    dispatch( fetchRecordsIfNeeded(optsDiseasesPerGene));

  if (!readyStatus.find(e => e.match('Pfam' + thisGene)) )
    dispatch( fetchRecordsIfNeeded(optsPfam));

  const handleClick = (e, data, target) => {
        const count = parseInt(target.getAttribute('data-count'), 10);

        if (data.action === 'Copy') {
	  //target.setAttribute('data-count', count + 1);
	  //copyToClipboard(e)
	  textAreas[count].current.select();
	  document.execCommand('copy');
	  setCopySuccess('Copied!');

	  setLog( [`${data.action} 1 ${data.name}`, ...logs ] );
	  /*
            return this.setState(({ logs }) => ({
                logs: [`${data.action} 1 ${data.name}`, ...logs]
	    })); */
        }
        if (data.action === 'Removed' && count > 0) {
	  target.setAttribute('data-count', count - 1);

	  setLog( [`${data.action} 1 ${data.name}`, ...logs ] );
	  return;
	  /*
            return this.setState(({ logs }) => ({
                logs: [`${data.action} 1 ${data.name}`, ...logs]
	    })); */
        }
	  setLog( [` ${data.name} cannot be ${data.action.toLowerCase()}`, ...logs ] );
      /*
        this.setState(({ logs }) => ({
            logs: [` ${data.name} cannot be ${data.action.toLowerCase()}`, ...logs]
	})); 
	*/
	return ;
    }

  const listing = dataRecords['listgeneDetail' + thisGene];
  let coord;
  // let A;
  let ucsc_href, uniprot_href, refseq_href, hallmark_href, depmap_href, cansar_href;
  let cards_href, cbioportal_href;
  console.log('+++++++++++LISTING', listing);
  let fro, to, chr, uniprot_id, refseq_id, gene_symbol;
  if (listing) {
    ({
      fro, to, chr, uniprot_id, refseq_id, gene_symbol
    } = listing);
    coord = `chr${chr}:${fro}-${to}`;
    const spacer = 0.7; // [%] slopping
    const range = (+to - +fro)*spacer;
    const fro_ = parseInt( +fro - range );
    const to_ = parseInt( +to + range );
    const coord_ = `chr${chr}:${fro_}-${to_}`;
    //https://genome.ucsc.edu/s/superk/hg38_FABRIC_colorSorted
    //ucsc_href = `https://genome.ucsc.edu/cgi-bin/hgTracks?db=hg38&lastVirtModeType=default&lastVirtModeExtraState=&virtModeType=default&virtMode=0&nonVirtPosition=&position=${coord_}`;
    ucsc_href = `https://genome.ucsc.edu/cgi-bin/hgTracks?db=hg38&lastVirtModeType=default&lastVirtModeExtraState=&virtModeType=default&virtMode=0&nonVirtPosition=&position=${coord_}&hgsid=851600273_lMfIhGOlaYE4BVMjBbn4n6hDz3L4`;
    cards_href = `https://www.genecards.org/cgi-bin/carddisp.pl?gene=${thisGene}`;
    cbioportal_href = `https://www.cbioportal.org/results/cancerTypesSummary?case_set_id=all&gene_list=${thisGene}&cancer_study_list=5c8a7d55e4b046111fee2296`;
    // A = <a href={ucsc_href}>{coord}</a>;
    uniprot_href= `https://www.uniprot.org/uniprot/${uniprot_id}`;
    refseq_href = `https://www.ncbi.nlm.nih.gov/refseq?term=${refseq_id}`;
    hallmark_href = `https://cancer.sanger.ac.uk/cosmic/census-page/${thisGene}`
    depmap_href = `https://depmap.org/portal/gene/${thisGene}`
    cansar_href = `https://cansarblack.icr.ac.uk/target/${uniprot_id}/synopsis/diseases`
  } else
    return null;

  const prfx = 'list' + thisGene + 'diseases';
  console.log('DIDIDIDID', dataRecords[prfx]);
  if (dataRecords[prfx] === undefined) return null;

  const rowFn = (row) => {
    const [ rowDisease ] = row.cells
        .filter( c => c.column.Header === 'cancer type' )
        .map( c => c.value )
	history.push('/disease/' + rowDisease + '?focus=' + uniprot_id)
    //style={{ cursor: 'pointer' }}
    //id={`${row.id + column.id}`}
    }

  const columns_ = columns.diseases2
    .map( c => _.assign({
    }, c, {
      sortType:'basic',
      /* Cell: cellFn */
    }) );

  columns_[0] = {
    Header: 'cancer type',
    accessor: 'disease',
    format: 'String',
    width: '15%',
    sortType: 'alphanumeric',
    /* Cell: cellFn */
  };

  const pfamGene = 'listPfam' + thisGene;

  const rowOnLoad = (props, inst) => {
    // console.log(inst.row.values);
    const {
      values: {
        disease, overall_z_value: zscore, overall_fdr_qval
      }
    } = inst.row;
    const mx = Math.log10(Number.MAX_VALUE).toFixed(2);
    const qval = overall_fdr_qval === 0 ?
      -mx :
      -Math.log10(overall_fdr_qval).toFixed(2);
    let props_ = props;
    _.assign( props_, { onClick: () => rowFn(inst.row) } )
    if (disease && disease === 'PanC') {
      _.assign(props_, {
        className: 'dashed-border'
      } );
    }
    const scale = { neg : 3, pos : 15 };
    const minVal = -Math.log10(alpha)
    if (qval && zscore) {
      if (zscore > 0)
        _.assign(props_, {
          style: {
	    background: qval <= minVal ? 'inherit' : BluColorPalette(qval, scale.neg),
	    color: MonoColorPalette(qval, scale.neg),
          }
        } );
      else
        _.assign(props_, {
          style: {
	    background: qval <= minVal ? 'inherit' : RedColorPalette(qval, scale.pos),
	    color: MonoColorPalette(qval, scale.pos),
          }
        } );
    }
    return (
      props_
    );
  };
  
  if (!sending) {
    console.log('detect', getIPDetails() );
    setSendRequest(true);
  }

  const attributes = {
            'data-count': 0,
            className: 'example-multiple-targets well'
        };
  let ik = -1;
  const specialHidden = { 
    height:0,
    position:'absolute',
    zIndex: -1,
    opacity: 0.1
  }

  return (
    <Container>
      <ReactTooltip html={true}/>
      <Row xs={1} md={2}>
	  <Col>
	    <br />
          <Card bg="light" style={{
            width: '100%', textAlign: 'initial'
          }}>
	  <Card.Header><h1 className="h5" style={{ marginTop: 'revert' }}>{gene_symbol}</h1></Card.Header>
	  <Card.Body>

	    {/*
	    <ContextMenu id="some_unique_identifier">
	      <MenuItem data={{foo: 'bar'}} onClick={handleClick}>
		Copy gene
	      </MenuItem>
	    </ContextMenu> */}

	    <Card.Title>{listing['gene_name']}</Card.Title>
	    <Card.Subtitle className="mb-2 text-muted" style={{ display: 'table', borderCollapse:'separate', borderSpacing:5 }}>

		<Card.Link href={uniprot_href} target="_blank">
	      <div key={++ik} className='pure-u-1-6'>
		  <ContextMenuTrigger
		      id={MENU_TYPE} holdToDisplay={1000}
		      name={uniprot_id} onItemClick={handleClick}
		      allowRemoval={ik % 2 === 0}
		      collect={collect}
		      attributes={_.assign({}, attributes, { 'data-count': ik } )}
		    >
		      <img src={Uniprotlogo} data-tip={`Uniprot ${uniprot_id}<br/>in a new tab`} style={{width:80}} alt='Uniprot' />
		  </ContextMenuTrigger>
		  <form>
		    <textarea style={specialHidden}
		    ref={textAreas[ik]}
		    value={uniprot_id}
		  /></form>
		</div>
	    </Card.Link>

		<Card.Link href={ucsc_href} target="_blank">
	      <div key={++ik} className='pure-u-1-6'>
		  <ContextMenuTrigger
		      id={MENU_TYPE} holdToDisplay={1000}
		      name={coord} onItemClick={handleClick}
		      allowRemoval={ik % 2 === 0}
		      collect={collect}
		      attributes={_.assign({}, attributes, { 'data-count': ik } )}
		    >
		      <img src={UCSClogo} alt="UCSC" data-tip={`UCSC ${coord}<br/>in a new tab`} style={{width:80}} />
		  <form>
		    <textarea style={specialHidden}
		      ref={textAreas[ik]}
		      value={coord}
		    /></form>
		  </ContextMenuTrigger>
		</div>
		</Card.Link>

		<Card.Link href={refseq_href} target="_blank">
	      <div key={++ik} className='pure-u-1-6'>
		  <ContextMenuTrigger
		      id={MENU_TYPE} holdToDisplay={1000}
		      name={refseq_id} onItemClick={handleClick}
		      allowRemoval={ik % 2 === 0}
		      collect={collect}
		      attributes={_.assign({}, attributes, { 'data-count': ik } )}
		    >
		      <img src={RefSeqlogo} data-tip={`RefSeq ${refseq_id}<br/>in a new tab`} style={{width:90}} alt='RefSeq' />
		  </ContextMenuTrigger>
		  <form>
		    <textarea style={specialHidden}
		    ref={textAreas[ik]}
		    value={refseq_id}
		  /></form>
		</div>
	    </Card.Link>

		<Card.Link href={cbioportal_href} target="_blank">
	      <div key={++ik} className='pure-u-1-6'>
		  <ContextMenuTrigger
		      id={MENU_TYPE} holdToDisplay={1000}
		      name={thisGene} onItemClick={handleClick}
		      allowRemoval={ik % 2 === 0}
		      collect={collect}
		      attributes={_.assign({}, attributes, { 'data-count': ik } )}
		    >
		      <img src={cBioPortallogo} data-tip={`cBio portal ${thisGene}<br/>in a new tab`}  style={{width:80}} alt='cBio Portal' />
		  <form>
		    <textarea style={specialHidden}
		      ref={textAreas[ik]}
		      value={thisGene}
		    /></form>
		  </ContextMenuTrigger>
		</div>
		</Card.Link>

		<Card.Link href={cards_href} target="_blank">
	      <div key={++ik} className='pure-u-1-6'>
		  <ContextMenuTrigger
		      id={MENU_TYPE} holdToDisplay={1000}
		      name={thisGene} onItemClick={handleClick}
		      allowRemoval={ik % 2 === 0}
		      collect={collect}
		      attributes={_.assign({}, attributes, { 'data-count': ik } )}
		    >
		      <img src={GClogo} data-tip={`GeneCards ${thisGene}<br/>in a new tab`} style={{width:50}} alt='GeneCards' />
		  </ContextMenuTrigger>
		  <form>
		    <textarea style={specialHidden}
		    ref={textAreas[ik]}
		    value={thisGene}
		  /></form>
		</div>
	    </Card.Link>

		<Card.Link href={hallmark_href} target="_blank">
	      <div key={++ik} className='pure-u-1-6'>
		  <ContextMenuTrigger
		      id={MENU_TYPE} holdToDisplay={1000}
		      name={thisGene} onItemClick={handleClick}
		      allowRemoval={ik % 2 === 0}
		      collect={collect}
		      attributes={_.assign({}, attributes, { 'data-count': ik } )}
		    >
		      <img src={hallmarklogo}  data-tip={`Cancer Hallmark (COSMIC) ${thisGene}<br/>in a new tab`} style={{width:50}} alt='census hallmark' />
		  </ContextMenuTrigger>
		  <form>
		    <textarea style={specialHidden}
		    ref={textAreas[ik]}
		    value={thisGene}
		  /></form>
		</div>
	    </Card.Link>

	    <Card.Link href={depmap_href} target="_blank">
	      <div key={++ik} className='pure-u-1-6'>
		  <ContextMenuTrigger
		      id={MENU_TYPE} holdToDisplay={1000}
		      name={thisGene} onItemClick={handleClick}
		      allowRemoval={ik % 2 === 0}
		      collect={collect}
		      attributes={_.assign({}, attributes, { 'data-count': ik } )}
		    >
		      <img src={depmaplogo}  data-tip={`depmap portal ${thisGene}<br/>in a new tab`} style={{width:100, background: '#2c3c60' }} alt='depmap'  />
		  </ContextMenuTrigger>
		  <form>
		    <textarea style={specialHidden}
		    ref={textAreas[ik]}
		    value={thisGene}
		  /></form>
		</div>
	    </Card.Link>

	    <Card.Link href={cansar_href} target="_blank">
	      <div key={++ik} className='pure-u-1-6' style={{ width: 100 }}>
		  <ContextMenuTrigger
		      id={MENU_TYPE} holdToDisplay={1000}
		      name={uniprot_id} onItemClick={handleClick}
		      allowRemoval={ik % 2 === 0}
		      collect={collect}
		      attributes={_.assign({}, attributes, { 'data-count': ik } )}
		    ><div data-tip={`canSAR ${uniprot_id}<br/>in a new tab`}>
		      <img src={cansarlogo}  style={{width:50 }} alt='CanSar' />
		      <span style={{ marginLeft: -7 }}>
			  can
			  <span
			    style={{
			      marginLeft: '-.4rem',
			      color: '#f9a200'
			    }}
			  >SAR</span>
		    </span></div>
		  </ContextMenuTrigger>
		  <form>
		    <textarea style={specialHidden}
		    ref={textAreas[ik]}
		    value={uniprot_id}
		  /></form>
		</div>
	    </Card.Link>


	    {/*
	    <ContextMenuTrigger id="Uniprot_trigger">
	      <img src={Uniprotlogo} style={{width:80}} />
	    </ContextMenuTrigger> */}

	    <p>
	    </p>
	    </Card.Subtitle>
	    <Card.Text>
	      Coding sequence length: {listing.cds_len} nt
	    </Card.Text>
	    <Card.Text>
	      <div className="img-magnifier-container">
		<img onLoad={() => magnify("genomic-loc", 3)} id="genomic-loc" src={`https://cdn.genecards.org/genomic-location-v5-3/${thisGene}-gene.png`} style={{ width: '100%', border: '#ccc 1px solid', borderRadius: 3 }} alt='expand' />
	    </div>
	    </Card.Text>
	  </Card.Body>
	      <span data-class="DarkTooltip"
		data-tip={`Click a logo to navigate to the respective website<br/>Right-click a logo to copy the gene identifier into the clipboard`}
		><QuestionMark top={-10} right={-6} />
	      </span>
          </Card>

	  <DynamicContextMenu> </DynamicContextMenu>
	  </Col>
	  <Col>
	    { dataRecords[pfamGene] !== undefined && Pfam &&
		<Pfam
		  s={dataRecords[pfamGene][0]}
		  gene={thisGene}
		  version={90}
		/>
          }
	  </Col>
      </Row>
      <br />
      <Row>
	  <Col>
	    { dataRecords[prfx] && dataRecords[prfx].length ? <>
		<Table
		  wantSort={true}
		  columns={columns_}
		  data={dataRecords[prfx]}
		  rowOnLoad={rowOnLoad}
		/> 
		<span data-class="DarkTooltip narrow"
		  data-tip={`Click a row to navigate<br/>to the disease page<br/>Column sort is active`}
		  ><QuestionMark top={-10} right={7} zIndex={99} />
		</span></> :
		<>
		<Failed title="No cancer driver information for this gene" />
		<span data-class="DarkTooltip narrow"
		  data-tip={`This gene does not have observed mutations<br/>You can go back to search for another gene`}
		  ><QuestionMark top={-10} right={7} zIndex={99} />
		</span></>
	    }
	  </Col>
      </Row>
      <br />
    </Container>
  );
};

const {fetchRecordsIfNeeded} = dataRecordsAction;

export default Gene;
