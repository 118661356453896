/*
 * MODIFIED
 *    $Date: Sun Aug 23 04:50:49 IDT 2020$
 */

/* @flow */

import React from 'react';
import {
  useDispatch, useSelector
} from 'react-redux';
import './Root.css';

import Sapien from '../@oncojs/react-sapien';
import {totalsAction} from '../../actions';

/*
const TCGADiseases = {
  GBM: "Glioblastoma Multiforme",
  SARC: "Sarcoma",
  READ: "Rectum Adenocarcinoma",
  MESO: "Mesothelioma",
  PanC: "Pan-Cancer (combination)"
}
*/

let data = [
  {
    _key: 'Kidney', abbr: 'KIRC,KIRP,KICH', _old_count: 1692, _old_file_count: 1692, _count: 53354, _file_count: 683
  },
  {
    _key: 'Brain', abbr: 'LGG,GBM', _old_count: 1133, _old_file_count: 1133, _count: 118321, _file_count: 901
  },
  {
    _key: 'Nervous System', abbr: 'PCPG', _old_count: 1120, _old_file_count: 1120, _count: 2411, _file_count: 179
  },
  {
    _key: 'Breast', abbr: 'BRCA', _old_count: 1098, _old_file_count: 1098, _count: 120988, _file_count: 986
  },
  {
    _key: 'Lung', abbr: 'LUAD,LUSC', _old_count: 1089, _old_file_count: 1089, _count: 389296, _file_count: 1059
  },
  {
    _key: 'Blood', abbr: 'LAML', _old_count: 923, _old_file_count: 923, _count: 9905, _file_count: 143
  },
  {
    _key: 'Colorectal', abbr: 'COAD,READ', _old_count: 635, _old_file_count: 635, _count: 329590, _file_count: 536
  },
  {
    _key: 'Uterus', abbr: 'UCEC,UCS', _old_count: 617, _old_file_count: 617, _count: 896826, _file_count: 587
  },
  {
    _key: 'Ovary', abbr: 'OV', _old_count: 608, _old_file_count: 608, _count: 75168, _file_count: 436
  },
  {
    _key: 'Head and Neck', abbr: 'HNSC', _old_count: 528, _old_file_count: 528, _count: 102309, _file_count: 508
  },
  {
    _key: 'Thyroid', abbr: 'THCA', _old_count: 507, _old_file_count: 507, _count: 10899, _file_count: 492
  },
  {
    _key: 'Prostate', abbr: 'PRAD', _old_count: 500, _old_file_count: 500, _count: 29286, _file_count: 495
  },
  {
    _key: 'Stomach', abbr: 'STAD', _old_count: 478, _old_file_count: 478, _count: 213144, _file_count: 437
  },
  {
    _key: 'Skin', abbr: 'SKCM', _old_count: 470, _old_file_count: 470, _count: 392571, _file_count: 467
  },
  {
    _key: 'Bladder', abbr: 'BLCA', _old_count: 412, _old_file_count: 412, _count: 134513, _file_count: 412
  },
  {
    _key: 'Bone', _old_count: 384, _old_file_count: 384, _count: 384, _file_count: 384
  },
  {
    _key: 'Liver', abbr: 'LIHC', _old_count: 377, _old_file_count: 377, _count: 54238, _file_count: 364
  },
  {
    _key: 'Cervix', abbr: 'CESC', _old_count: 308, _old_file_count: 308, _count: 103405, _file_count: 289
  },
  {
    _key: 'Adrenal Gland', abbr: 'ACC', _old_count: 271, _old_file_count: 271, _count: 10747, _file_count: 92
  },
  {
    _key: 'Soft Tissue', _old_count: 261, _old_file_count: 261, _count: 261, _file_count: 261
  },
  {
    _key: 'Bone Marrow', _old_count: 200, _old_file_count: 200, _count: 200, _file_count: 200
  },
  {
    _key: 'Pancreas', abbr: 'PAAD', _old_count: 185, _old_file_count: 185, _count: 29959, _file_count: 178
  },
  {
    _key: 'Esophagus', abbr: 'ESCA', _old_count: 185, _old_file_count: 185, _count: 45313, _file_count: 184
  },
  {
    _key: 'Testis', abbr: 'TGCT', _old_count: 150, _old_file_count: 150, _count: 3198, _file_count: 144
  },
  {
    _key: 'Thymus', abbr: 'THYM', _old_count: 124, _old_file_count: 124, _count: 4737, _file_count: 123
  },
  {
    _key: 'Pleura', _old_count: 87, _old_file_count: 87, _count: 87, _file_count: 87
  },
  {
    _key: 'Eye', abbr: 'UVM', _old_count: 0, _old_file_count: 0, _count: 1856, _file_count: 80
  },
  {
    _key: 'Lymph Nodes', abbr: 'DLBC', _old_count: 58, _old_file_count: 58, _count: 6406, _file_count: 37
  },
  {
    _key: 'Bile Duct', abbr: 'CHOL', _old_count: 51, _old_file_count: 51, _count: 5503, _file_count: 51
  },
];

data.sort((a, b) => a._key > b._key ? 1 : -1);
const aveInt = arr => Number.parseInt( arr.reduce( ( p, c ) => p + c.total, 0 ) / arr.length, 10 );
const sumInt = arr => Number.parseInt( arr.reduce( ( p, c ) => p + c.total, 0 ), 10 );

const {fetchTotalsIfNeeded} = totalsAction;

const Root = (props) => {
  const totals = useSelector(state => state.totals);
  const dispatch = useDispatch();

  if (!totals.readyStatus.find(e => /^obsTOTALS_[RSNF]/.test(e)))
    dispatch( fetchTotalsIfNeeded());

  console.log('totals:', totals);
  // update the data counts
  if (totals.totalobs && totals.totalobs.length)
    /*data = data
      .map( d => ({
        ...d,
	_count:sumInt(
	  totals.totalobs
	  .filter( t => d && d.abbr && d.abbr.match(t.disease) )
	) || 0
      }) ); */
  {}

  return (
    <div>
      <Sapien
        data={data}
        selector="#human-body-root"
        root={props.root}
        hoverHandler={d => console.dir(d)}
        clickHandler={d => {
          console.dir(d);
          if (props.onClick) {
	  let e = d;
	  if (!('_count' in e)) [ e ] = data.filter(o => o._key === d._key);
	  props.onClick(e);
          }
        }}
        height={props.height || 400}
        width={props.width || 400}
        labelSize="10px"
        tickInterval={2e5}
        fileCountKey="_file_count"
      />
    </div>
  );
};

export default Root;
