/*
 * MODIFIED
 *    $Date: Thu Jul 15 18:15:53 IDT 2021$
 */

import  React, {
  useState,
  useRef,
  /* Component,
  Fragment, */
  useEffect
} from 'react';

import html2canvas from 'html2canvas';
//import canvg from 'canvg';
//import jsPDF from 'jspdf';
//import Tooltip from "react-png-tooltip";

import {
  useDispatch, useSelector
} from 'react-redux';
import {useParams, useLocation} from "react-router";
import _ from 'lodash';
import {dataRecordsAction} from '../../actions';
import {totalsAction} from '../../actions';

import { useMediaQuery, useURIquery } from '../../hooks';
import {columns, TCGADiseases, alpha } from '../../config/settings';

import {
  Container, Row, Col, ButtonGroup, DropdownButton, Dropdown, Spinner
} from 'react-bootstrap';
import Sidebar from "react-sidebar";
import ReactTooltip from "react-tooltip";
import {useHistory} from 'react-router-dom';
import ThumbnailSvg from './ThumbnailSvg';
import {
  BluColorPalette, RedColorPalette, MonoColorPalette
} from '../../utils/colorPalettes';
import convertToCSV from '../../utils/csv';
import { CSVLink } from "react-csv";
import './DiseaseHeatmapContent.css';

import externalLinkIcon from './external-link-ltr-icon.svg';
import pngLogo from './png-svgrepo-com.svg'; // www.svgrepo.com/vectors/png-svg-vector/
import csvLogo from './csv-svgrepo-com.svg';// https://www.svgrepo.com/vectors/csv-svg-vector/

import Helmet from 'react-helmet';
import {
  Table,
  ColorGrid,
  /* Clustering, */
  HumanBody,
  QuestionMark,
  Loading,
  Failed
} from '../../components';

const styles = {
  container: ({
    is7px, is4px
  }) => ({
    height: `${ is7px ? '445px' : is4px ? '320px' : '296px' }`
  })
};

const sidebarStyles = (props) => ({
  root: {
    position: "relative",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "hidden",
    height: props.height ? parseInt(props.height) + 50 : 500
  },
  sidebar: {
    zIndex: 3,
    position: "fixed",
    top: () => props.myref.current.clientTop,
    bottom: 0,
    transition: "transform .3s ease-out",
    WebkitTransition: "-webkit-transform .3s ease-out",
    willChange: "transform",
    overflowY: "auto",
    width: 700
  },
  content: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: "auto",
    WebkitOverflowScrolling: "touch",
    transition: "left .3s ease-out, right .3s ease-out"
  },
  overlay: {
    zIndex: 1,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    visibility: "hidden",
    transition: "opacity .3s ease-out, visibility .3s ease-out",
    backgroundColor: "rgba(0,0,0,.3)"
  },
  dragHandle: {
    zIndex: 9999,
    position: "relative",
    top: 0,
    bottom: 0,
    background: 'red',
    color: 'aquamarine'
  }
});



const TCGAhref = d => {
  if (/PanC/.test(d)) {
  return "https://portal.gdc.cancer.gov/projects/?filters=%7B%22op%22%3A%22and%22%2C%22content%22%3A%5B%7B%22op%22%3A%22in%22%2C%22content%22%3A%7B%22field%22%3A%22projects.program.name%22%2C%22value%22%3A%5B%22TCGA%22%5D%7D%7D%5D%7D"
  }
  return `https://portal.gdc.cancer.gov/projects/TCGA-${d}`
}

/*
function triggerDownload (imgURI) {
  var evt = new MouseEvent('click', {
    view: window,
    bubbles: false,
    cancelable: true
  });

  var a = document.createElement('a');
  a.setAttribute('download', 'MY_COOL_IMAGE.png');
  a.setAttribute('href', imgURI);
  a.setAttribute('target', '_blank');

  a.dispatchEvent(evt);
}
*/

/*
const canvas2dataUrl = (canvas, svg) => {
  let ctx = canvas.getContext('2d');
  let data = (new XMLSerializer()).serializeToString(svg);
  let DOMURL = window.URL || window.webkitURL || window;
  let img = new Image();
  let svgBlob = new Blob([data], {type: 'image/svg+xml;charset=utf-8'});
  let url = DOMURL.createObjectURL(svgBlob);
  img.onload = function () {
    ctx.drawImage(img, 0, 0);
    DOMURL.revokeObjectURL(url);

    var imgURI = canvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');

    triggerDownload(imgURI);
  };

  img.src = url;
}
*/

const CondSpinner = ({cond , title}) =>
  cond ? <span>
      <Spinner
	as="span"
	animation="border"
	size="sm"
	role="status"
	aria-hidden="true"
      />{title}</span> :
      <span>{title}
      </span> ;

//
// style of the '+' and '-' legend
//
const pmStyle_ = {
  cursor: 'default',
  float: 'left',
  fontSize: 'smaller',
  width: 25,
  margin: '1px 10px 3px 0px',
  border: '1px dashed grey',
  borderRadius: 2,
  fontWeight: 'bold',
  lineHeight: '1.3em'
}
const pmStyle = {
  orange: _.assign({}, pmStyle_, { color: 'rgb(253, 187, 132)' }),
  blu: _.assign({}, pmStyle_, { color: 'rgb(0, 98, 204)' })
}

//
// display of a single disease with its 15 marker genes and respective
// heat map
//
const DiseaseHeatmapContent = (props) => {
  let {disease: D} = useParams();
  let location = useLocation();
  let query = useURIquery();
  let history = useHistory();
  const { session } = props;
  const {
    api = {
      apiHost: 'localhost:8000',
      apiUrl: 'http://localhost:8000/api'
    }
  } = props;

  let opts = {
    prefix: 'genes',
    totalkeys: 1,
    filter: null,
    URL: `${api.apiHost}${api.apiUrl}/geneIDs/`
  };

  const totals = useSelector(state => state.totals);

  const rowFn = (row) => {
    const [ rowGene ] = row.cells
        .filter( c => c.column.Header === 'Symbol' )
        .map( c => c.value )
	history.push('/gene/' + rowGene)
    //style={{ cursor: 'pointer' }}
    //id={`${row.id + column.id}`}
    }

  //
  // create clickable columns in the Top15 genes list
  //
  let columns_diseases = columns.diseases
  const ind = columns_diseases
    .map( (c,i) => /(Gene ID|Symbol|Uniprot)/.test(c.Header) ? i : undefined )
    .filter( o => o !== undefined )
  ind.forEach( i =>
    columns_diseases[i]
	.Cell = ({cell: { row, column, value } }) => {
	  return (<span
	    style={{ cursor: 'pointer' }}
	    id={`${row.id + column.id + 'link'}`}
	  >{value}</span>
	  );
	}
  )

  // const [diseases, setDiseases] = useState({})
  const [genesState, setGenesState] = useState({
    data: [], listgenes: 1
  });
  const [diseasesState, setDiseasesState] = useState({
    BRCA: {
    },
    ACC: {
    },
    listBRCA: 1,
    listACC: 1
  });
  const [heatmapState, setHeatmapState] = useState({
    A: null
  });
  const [thisDisease, setThisDisease] = useState(D);
  const [numRows, setNumRows] = useState({
    requesting: true,
    N: {
      request: 30, disease: 0, heatmap: 0
    }
  });
  const [sidebarState, setSidebarState] = useState({
    sidebarOpen: false, sidebarDocked: false
  });
  const [htmlPreCanvas, setHtmlPreCanvas] = useState(null); // holder of the <canvas> DOM elem that wraps the svg image of the ColorGrid
  const [waitForDownload, setDownloadActive] = useState([false,'Export']);
  const [waitForRelisting, setRelisting] = useState([false,`Showing ${numRows.N.request} genes`]);

  const dispatch = useDispatch();
  const dataRecords = useSelector(state => state.dataRecords);

  useEffect( () => {
    if ( thisDisease !== D ) {
      console.log('D HAS CHANGED', D);
      setThisDisease(D);
      const {N: oldN} = numRows;
      setNumRows( _.assign( {
      },
	  numRows, {
        requesting: true
      },
	  {
        N : _.assign({
        }, oldN, {
          heatmap : 0, disease: 0
        })
      }
      ));
    }
  if (session.readyStatus.find(e => /SUCCESS/.test(e)) !== undefined &&
    !session.registered) {
    const [ b ] = document.getElementsByClassName('human-button')
    b.click(); setTimeout( () => b.click(), 500);
    }
  }, [thisDisease, D, numRows] );

  useEffect( () => {
    const keywordsEl = document.querySelector('meta[name="keywords"]');
      if (keywordsEl) {
	const keywords = keywordsEl.getAttribute('content') + ',' + TCGADiseases[thisDisease];
	keywordsEl.setAttribute('content', keywords);
	console.log(keywords, typeof keywords)
	    }
  }, [] );

  const anchor = thisDisease;
  const { request: req } = numRows.N

  if (!totals.readyStatus.find(e => /genesTOTALS_[RSNF]/.test(e)))
    dispatch( fetchTotalGenesIfNeeded({ prefix: 'genes', disease: thisDisease } ));

  // dispatch database calls
  // (A) genes
  if (!dataRecords.readyStatus.find(e => /genes/.test(e)))
    dispatch( fetchRecordsIfNeeded(opts));

  const opts_ = {
    totalkeys: 1,
    filter: null,
  };

  //
  // load disease-specific data
  // (B) the top-15 genes data
  const optsDisease = _.assign({
  }, opts_, {
    prefix: anchor, 
    URL: `${api.apiHost}${api.apiUrl}/diseases/${anchor}/`
  });

  if (!dataRecords.readyStatus.find(e => e.match('^' + anchor + 'RECORDS')))
    dispatch( fetchRecordsIfNeeded(optsDisease) );

  // (C) the heatmap data
  //
  const focus = query.get("focus")
  const units = query.get("units")
  const params = [ `N=${req}`, `focus=${focus}`, `units=${units}` ]
    .filter( p => !/=null/.test(p) )
    .join('&')
  const optsHeat = _.assign({
  }, opts_, {
    prefix: anchor + 'heat', 
    URL: `${api.apiHost}${api.apiUrl}/heat/${anchor}/?${params}`
  });

  // (C) logic for loading heatmap data
  // in the event that the number of requested table rows has
  // changed, force (override) fetch..IfNeeded() to produce a new
  // dataset
  // variables:
  //    requesting  - set when the user changes the selection dropdown
  //    request     -  the result of the selection change (integer)
  //    heatmap     - current number of rows in the heat map
  //    n <- results.length - number of rows of the param in redux store
  //
  // cases:
  //    no dataRecords slot?
  //         ==> dispatch fetch optsHeat
  //    no results?
  //      ==> elegantly skip
  //    requesting == T?    # an active request was given by the user
  //      request > n?      # if the size requested is not the size existing
  //         ==> dispatch fetch optsHeat w/override
  //      ==> set requesting to F     # deactivate the request (after dispatch)

  if (!dataRecords.readyStatus.find(e => e.match(anchor + 'heat'))) {
    dispatch( fetchRecordsIfNeeded(optsHeat) );
    setNumRows( _.assign({
    }, numRows, {
      requesting : false
    }) );
  }

  const {
    N : {
      request, heatmap
    }, requesting
  } = numRows;

  let listing = 'list' + anchor + 'heat';
  if (requesting === true) {
    if  (dataRecords[listing]) {
      let {results} = dataRecords[listing];
      if (results === undefined ) // no pagination
        results = dataRecords[listing];
      const n = results.filter( r => r.disease === anchor ).length;
      if (n > 0) {
        if (request > n) 
          dispatch(
	  fetchRecordsIfNeeded(
	    _.assign({
              },
	      optsHeat,
	      {
                override : true
              }
	  )) );
        setNumRows( _.assign({
        }, numRows, {
          requesting : false
        }) );
      }
    }
  }


  console.log( 'dataRecords', dataRecords );
  if (dataRecords.readyStatus.find(e => /genesRECORDS_SUCCESS/.test(e)) !== undefined &&
    'listgenes' in dataRecords.timing &&
    genesState.listgenes < dataRecords.timing.listgenes ) {
    const {listgenes : {results : data_}} = dataRecords;
    const data = data_.map(o =>
      _.assign({
      }, o, {
        coord: `chr${o.chr}:${o.fro}-${o.to}`
      } )
    );
    setGenesState( _.assign({
    }, genesState, {
      data, listgenes : dataRecords.timing.listgenes
    }) );
  }

  // let count = dataRecords.readyStatus.filter(e => /RECORDS_SUCCESS/.test(e)).length;

  listing = 'list' + anchor;
  if (dataRecords.readyStatus.find(e => e.match(anchor + 'RECORDS_SUCCESS')) !== undefined &&
    listing in dataRecords.timing &&
    diseasesState[listing] < dataRecords.timing[listing] ) {
    console.log("now you're talking ", anchor);
    const {results} = dataRecords[listing];


    setDiseasesState(_.assign({
    }, diseasesState, {
      [listing] : dataRecords.timing[listing],
      data: results.map(r=> ({
        uniprot_id:r.uniprot_id, gene_symbol:r.gene_symbol, [anchor]: r.overall_pval
      }) )
    } ));
  }

  //const cap = (num,mx) => ( num === 0 ? mx : /*-Math.log10*/(num).toFixed(1) )
  const cap = (num,mx) => ( num === 0 ? mx : num.toPrecision(3) )

  // (B) logic for the heatmap itself
  // cases:
  //    requesting == T?
  //      ==> do nothing
  //    requesting == F
  //      dataRecords slot has data?
  //        request == heatmap?
  //          ==> skip elegantly
  //        request != heatmap?
  //          ==> assign the heatmap from the redux store, up to 'request' rows
  //              update the state's N.heatmap
  listing = 'list' + anchor + 'heat';
  if (requesting === false &&
  (dataRecords.readyStatus.find(e => e.match(anchor + 'heatRECORDS_SUCCESS')) !== undefined)
  ) {
    if (dataRecords[listing] && dataRecords[listing].length > 1) {
      //const { N : { request, heatmap }, requesting } = numRows
      if (request !== heatmap) {
	const mx = /*Math.log10*/(Number.MIN_VALUE).toFixed(1);
        const a = dataRecords[listing]
	  .map( o => ({
	    uniprot_id: o.uniprot_id,
	    gene_symbol: o.gene_symbol,
	    rank: o.rank,
	    [o.disease]: {
	      pval: cap(o.overall_pval, mx) ,
	      zscore: o.overall_z_value.toFixed(2),
	      qval: cap(o.overall_fdr_qval,mx),
	      nobs: o.n_obs
	    }
	  } ))
	  .reduce(
	    (r, v, _, __, k = v['gene_symbol']) =>
		     ((r[k] || (r[k] = [])).push(v), r),
	    []
          );
        const order = dataRecords[listing]
	  .filter(o => o.disease === anchor)
	  .map(o => o.gene_symbol);
        //order.map( o => b[o] )
        const heatmap_ = order
	  .map( k => _.assign({
          }, ...a[k]) )
	  .slice(0, request);

        const diseases =
	 _.keys( _.assign({}, ..._.values(heatmap_) ) )
	  .filter(k => !/uniprot_id|gene_symbol/.test(k))
	  .sort( (a, b) => {
	    if (/PanC/.test(a)) return -1;
	    if (/PanC/.test(b)) return 1;
	    return (a < b) ? -1 : (a > b) ? 1 : 0;
	  } )
	  .map( k => ({
	    Header: k,
	    accessor: k,
	    disableSortBy: true,
	    Cell: ({
	       cell: c, ...rest
	     }) => {
	      const cv = c.value;
	      let val = typeof cv === 'object' ?
		 _.keys(cv)
		 .map( k => k + ':' + cv[k] )
		 .join('<br/>') :
		 cv;
	      return <span data-tip={k === 'rank' ? undefined : val}>{val}</span>;
	    }
	  }));

        columns.heat = diseases;

	/*
         * update the state. If the request is larger than the
         * available records, adjust the request
	 */
        const {N: oldN} = numRows;
        setNumRows( _.assign( {},
	  numRows,
	  { N : _.assign({}, oldN, {
	    request : heatmap_.length < request ? heatmap_.length : request,
	    heatmap : heatmap_.length
	  }) }
	));
	setHeatmapState( _.assign( {},
	  heatmapState,
	  { [anchor]: heatmap_ }
	));
    setRelisting([false, `Showing ${heatmap_.length} genes`])
      }
    }
  }

  console.log('data_', genesState);

  //const geneStateFailed = dataRecords.readyStatus.find(e => /genesRECORDS_FAILURE/.test(e));
  const thisDiseaseFailed = dataRecords.readyStatus.find(e => e.match(anchor + 'RECORDS_FAILURE'));
  const heatmapFailed = dataRecords.readyStatus.find(e => e.match(anchor + 'heatRECORDS_FAIL'));

  const is7px = useMediaQuery('(min-width: 992px)');
  const is4px = useMediaQuery('(min-width: 768px)');
  const myref = useRef(null);
  const buttonref = useRef(null);

  const onSetSidebarOpen = (open) => {
    setSidebarState(_.assign({
    }, sidebarState, {
      sidebarOpen: open
    }));
  };
  const onSidebarClick = (e) => {
    console.log('event from sidebar', e);
    if ('abbr' in e) {
      const [, oneDisease ] = e.abbr.match(/^([A-Z]+)(,|$)/);
      if (oneDisease) history.push('/disease/' + oneDisease + '/');
    }
  };

  const rowOnLoad = (props, inst) => {
    //console.log(inst.row.values);
    const {
      values: {
	disease, overall_z_value: zscore, overall_fdr_qval //, n_obs
      }
    } = inst.row;
    const mx = Math.log10(Number.MAX_VALUE).toFixed(2);
    const qval = +overall_fdr_qval === 0.0 ?
      mx :
      -Math.log10(overall_fdr_qval).toFixed(2);
    let props_ = props;
    _.assign( props_, { onClick: () => rowFn(inst.row) } )
    if (disease && disease === 'PanC') {
      _.assign(props_, {
        className: 'dashed-border'
      } );
    }
    const scale = { neg : 3, pos : 15 };
    const minVal = -Math.log10(alpha)
    if (qval && zscore) {
      if (zscore > 0)
        _.assign(props_, {
          style: {
	    background: qval <= minVal ? 'inherit' : BluColorPalette(qval, scale.neg),
	    color: MonoColorPalette(qval, scale.neg)
          }
        } );
      else
        _.assign(props_, {
          style: {
	    background: qval <= minVal ? 'inherit' : RedColorPalette(qval, scale.pos),
	    color: MonoColorPalette(qval, scale.pos)
          }
        } );
    }
    return (
      props_
    );
  };

  const toCSV = () => {
    return convertToCSV ( heatmapState[anchor] );
  }

  const download = () => {
    // fake server request, getting the file url as response
    setTimeout(() => {
      const response = {
	file: `${api.apiHost}/static/media/fabric_heatmap_template.xlsx`
      };
      // server sent the url to the file!
      // now, let's download:
      window.open(response.file);
      // you could also do:
      // window.location.href = response.file;
    }, 100);
  }

  /*
  const makePdf = (svgid) => {
    const pdf = new jsPDF('p', 'pt', 'c1');
    const c = pdf.canvas;
    c.width = 1000;
    c.height = 500;

    var ctx = c.getContext('2d');
    ctx.ignoreClearRect = true;
    ctx.fillStyle = '#ffffff';
    ctx.fillRect(0, 0, 1000, 700);

    //load a svg snippet in the canvas with id = 'drawingArea'
    console.log('SVGELEM', document.getElementById(svgid).innerHTML)
    new canvg.fromString(c, document.getElementById(svgid).innerHTML, {
	  ignoreMouse: true,
	  ignoreAnimation: true,
	  ignoreDimensions: true
    });

    return pdf;
  };
  */

  const getColorGridDOMelem = (elem) => {
    console.log('ELEM', elem)
    setHtmlPreCanvas (elem);
    return elem
  }

  let totalgenes = 0;
  if ('totalgenes' in totals) {
    ([ totalgenes ] = totals.totalgenes
      .filter( o => thisDisease === o.disease )
      .map( o => o.total ))
  }

  /*
   * plot tick marks alongside the gradient color scale. This allows
   * us to measure the rgb color combinations in fixed intervals
   * along the gradient. Once we can measure the colors, we register
   * the colors in sequence under RedColorPalette() in colorPalettes.js
   */
  const debugColorScale = false;

  return (
    <>
    <ReactTooltip html={true}/>
    <Row><Col></Col><Col><h1  style={{ fontSize: 'inherit', fontFamilty: 'inherit', fontWeight: 'inherit'  }} >Cancer type: {TCGADiseases[thisDisease]} {focus ? <div id="fade-it" style={{ paddingTop: 2}}>(gene: <a data-tip={`Click to dismiss the focus on this gene<br/>and return to top-${numRows.N.heatmap} ranks`} href={location.pathname}>{focus}</a>)</div> : null}</h1></Col><Col></Col></Row>
      <header className="App-header">
        <Container style={{
          paddingLeft: 0, paddingRight: 0
        }}>
          <Row className="justify-content-md-left">
            <Col sm="auto">
	      <span className="left" style={{ fontSize: 12 }}>Full name:&nbsp;
		<a
		  href={TCGAhref(thisDisease)}
		  target="_blank" rel="noopener noreferrer"><img src={externalLinkIcon} alt='external link' />
	      {TCGADiseases[thisDisease]}
		</a>&nbsp;
	      <span data-class="DarkTooltip narrow"
		data-tip={`Open ${thisDisease} on Genomic Data Commons in a new tab`}
		><QuestionMark top={0} right={0} />
	      </span>&nbsp;
	      </span>
            </Col>
            <Col className="text-right">
	      <span  style={{ fontSize: 12, color: '#777' }}>
	      Press a cancer type to sort the genes by it (observing the top alteration promoting genes in that cancer type)
	      </span>
            </Col>
          </Row>
          <Row style={{
            paddingLeft: 0, paddingRight: 0
          }}>
            <Col>
              <button
                ref={buttonref}
                className="human-button"
                onClick={() => onSetSidebarOpen(!sidebarState.sidebarOpen)}>
                <ThumbnailSvg /> { /*&equiv; */ }
              </button>
              <Sidebar
                sidebar={ <div ref={myref} id="human-body-root"></div> }
                open={sidebarState.sidebarOpen}
                docked={sidebarState.sidebarDocked}
                onSetOpen={onSetSidebarOpen}
		styles={sidebarStyles( _.assign( {},
		    { myref: buttonref },
		    styles.container({ is7px, is4px })
		  )
                )}
                pullRight={true}
                touchHandleWidth={40}
              >
                { heatmapState[anchor] ? 
	    <>
		<div
		  id="scrollerColorGrid"
		  style={ _.assign({
		  }, styles.container({
		    is7px, is4px
		  }), {
		    overflowY: 'scroll',
		    overflowX: 'hidden',
		    maxWidth: '100%'
		  }) }
	      >
	      { /* <SingleSelect title='Disease' selected={thisDisease} getSelected={diseaseSelector} /> */ }
	      <ColorGrid 
		This={thisDisease}
		columns={columns.heat}
		data={heatmapState[anchor]}
		focus={focus}
	        units={units}
		getDOMelem={getColorGridDOMelem}
		extra={ { ngenes: totalgenes } }
	    />
	  </div>
                    <Row style={{
                      margin: 0
                    }}>
                      <Col>
                      </Col>
		      <Col
                        className="gradContainer"
                        xs={4} md={4} lg={3} xl={3}
                        style={{ margin: 0 }}>
		  { /* </Tooltip> for pm + - */ }
	    <div style={pmStyle.blu} data-class="DarkTooltip" data-tip="
		  <font style='text-size: small!important; text-align:left!important;'>
		    Plus (<font style='color:lightblue; font-weight:bold;'>+</font>) sign marks the lack of power to determine the rejection of damage caused by the gene's alteration
		  </font>"
		>+
		</div>
	    <div style={pmStyle.orange} data-class="DarkTooltip" data-tip="
		  <font style='text-size: small!important; text-align:left!important;'>
		    Minus (<font style='color:orange; font-weight:bold;'>-</font>) sign marks the lack of power to determine the promotion of damage caused by the gene's alteration
		  </font>"
		>-
		</div>
	  <div className={`gradItem ${ debugColorScale ? 'tickPlot' : '' }`}>
	    { debugColorScale && <>
	      <div class="tick"><span>0</span></div>
	    <div class="tick"></div>
	    <div class="tick"></div>
	    <div class="tick"></div>
	    <div class="tick"></div>
	    <div class="tick"><span>5</span></div>
	    <div class="tick"></div>
	    <div class="tick"></div>
	    <div class="tick"></div>
	    <div class="tick"></div>
	    <div class="tick"><span>10</span></div>
	    <div class="tick"></div>
	    <div class="tick"></div>
	    <div class="tick"></div>
	    <div class="tick"></div>
	    <div class="tick"><span>15</span></div>
	      </>
	    }
	    <div
	      className="gradColorBar"
	      style={{ color: 'white', fontSize: 'small' }}>
	      <span style={{ textAlign:"left" }}>-15</span>
	      <span style={{
		textAlign:"center",
		margin: '0px 14px 0px 68px',
		color: '#000'
	      }}>0</span>
	    <span style={{ textAlign: "right" }}><span style={{ position: 'relative' }}>3
		  { /* </Tooltip> for gradient color scale */ }
	    <span data-class="DarkTooltip" data-tip="
		  <font style='text-size: small!important; text-align:left!important;'>
		    Color scale by <i>sgn(z) &middot; (-log10(q))</i>  <br/>
		    where z is the <i>z-value</i> and<br/>
		    qval is the FDR <i>q-value</i> calculated for the gene<br/>
		    <font style='color:red; font-weight:bold;'>Red</font> colors (negative z-value) indicate alteration-promoting genes (with mutations more damaging than expected at random)<br/>
		    <font style='color:lightblue; font-weight:bold;'>Blue</font> colors (positive z-value) indicate alteration-rejecting genes (with mutations less damaging than expected at random)
		  </font>"
		><QuestionMark top={-12}right={-10}/>
	      </span>
	      </span>
	    </span>
	    </div>
    <ReactTooltip html={true}/>
	  </div>

	  <div>
        </div>
                      </Col>
                      <Col xs={5} md={4} lg={3} xl={3} style={{
                        margin: 0
                      }}>
	  <ButtonGroup size="sm">
	    <DropdownButton
	      drop="up"
	      size="sm"
	      as={ButtonGroup}
	      variant="secondary"
	      title={<CondSpinner title={waitForRelisting[1]} cond={waitForRelisting[0]} />}
	      id="bg-vertical-dropdown-2"
	      onSelect={ (eventKey, event) => {
			    const {N: oldN} = numRows;
			    if (+eventKey !== oldN.request)
			      setRelisting([true, 'Working']);
			    const {target: {classList: classes}} = event;
			    const isActive = [...Array(classes.length).keys()]
			      .find( i => /active/.test(classes[i]) );
			    if (!_.isNil(isActive)) return;
			    setNumRows( _.assign(
			      {},
			      numRows,
			      { requesting: true },
			      { N : _.assign({}, oldN, { request: +eventKey }) }
			    ));
			    console.log('eventKey', eventKey);
			}
	      }
	    >
	      <Dropdown.Item eventKey="15" active={req === 15}>15</Dropdown.Item>
	      <Dropdown.Item eventKey="30" active={req === 30}>30</Dropdown.Item>
	      <Dropdown.Item eventKey="100" active={req === 100}>100</Dropdown.Item>
	      { /* <Dropdown.Item eventKey="1000" active={req === 1000}>All</Dropdown.Item> */ }
	    </DropdownButton>
	    <DropdownButton
	      drop="up"
	      size="sm"
	      as={ButtonGroup}
	      variant="secondary"
	      title={<CondSpinner title={waitForDownload[1]} cond={waitForDownload[0]}/>}
	      id="bg-vertical-dropdown-3"
	      onSelect={ (eventKey, event) => {
		if (eventKey === "1") {}
		if (eventKey === "2") {
		  // 1. svg to canvas
		  setDownloadActive([true, 'Working']);
		  html2canvas(htmlPreCanvas, {logging : false } )
		    .then(canvas => {
		      console.log('CANVAS0', canvas)
		      canvas.id = 'svgAsCanvas';
		      canvas.style.display = 'none';
		      //var ctx = canvas.getContext('2d');
		      // 5. draw on screen
		      //document.body.appendChild(canvas)
		      // 2. canvas to dataUrl
		      console.log('CANVAS', canvas)
		      var imgURI = canvas
			  .toDataURL('image/png')
			  .replace('image/png', 'image/octet-stream');
		      setTimeout(() => {
			let a = document.createElement('a');
			a.setAttribute('download', `${thisDisease}-${focus ? focus + '-' : ''}file.png` );
			a.setAttribute('href', imgURI);
			a.setAttribute('target', '_blank');
			// 3. trigger download from dataUrl
			a.click();
			setDownloadActive([false, 'Export']);
			//document.body.removeChild(canvas);
		      }, 100);

		      // 4. make a pdf
		      //makePdf(canvas.id).save();
		    });
		}
		if (eventKey === "3") download()
	      } }
	    >
	      <Dropdown.Item as={'span'} eventKey="1" style={{ paddingLeft: 0 }}><CSVLink data={toCSV()} className="dropdown-item" filename={`${thisDisease}-${focus ? focus + '-' : ''}file.csv`} target="_blank"><img style={{ width: 20 }} src={csvLogo} alt='as table' />download as table</CSVLink></Dropdown.Item>
	      <Dropdown.Item  eventKey="2"><img style={{ width: 20 }} src={pngLogo} alt='as graphic' />download as heat map</Dropdown.Item>
	      <Dropdown.Item eventKey="3">get xlsx template</Dropdown.Item>
	    </DropdownButton>
	  </ButtonGroup>
                      </Col>
                    </Row>
	    </> :
	    heatmapFailed ?
	      <Failed
                      style={ styles.container({ is7px, is4px }) }
                      title="No heatmap data"
	      /> :
	      <Loading
                      style={ styles.container({ is7px, is4px }) }
                      subject="heat map"
	      />
                }
              </Sidebar>
            </Col>
          </Row>

          <Row>
            <Col>

                  {dataRecords && dataRecords['list' + anchor] ?
	    <div>
	      <hr />
	      <h3 className="mt-2">Top 15 {anchor} genes</h3>
	      <Table
		  columns={columns_diseases}
		  data={dataRecords['list' + anchor]['results']}
		  rowOnLoad={rowOnLoad}
	      /> 
	    </div>
	    : thisDiseaseFailed  ? <Failed title="No marker genes data" /> :
	    <Loading subject="top-15" /> }
	    <HumanBody
                    onClick={onSidebarClick}
                    root={() => myref.current}
	    />
            </Col>
          </Row>
	  <br/>
        </Container>

        {/*<img src={logo} className="App-logo" alt="logo" />
	<p>
          Edit <code>src/App.js</code> and save to reload.
	</p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      </header>
    </>
  );

};

const {fetchRecordsIfNeeded} = dataRecordsAction;
const {fetchTotalGenesIfNeeded} = totalsAction;

export default DiseaseHeatmapContent;
