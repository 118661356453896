/*
 * MODIFIED
 *    $Date: Thu Jun 11 12:52:27 IDT 2020$
 */

import React from 'react';
import PropTypes from 'prop-types';
import { ContextMenu, MenuItem, connectMenu } from "react-contextmenu";

const MENU_TYPE = 'DYNAMIC';

const DynamicMenu = props => {
    const { id, trigger } = props;
    const handleItemClick = trigger ? trigger.onItemClick : null;

    return (
        <ContextMenu id={id}>
	  {trigger && 
	    <MenuItem
	      onClick={handleItemClick}
	      data={{ action: 'Copy' }}
	    >{`copy ${trigger.wrapper ? trigger.wrapper(trigger.name) : trigger.name }`}
	    </MenuItem>
	    }
        </ContextMenu>
    );
};

DynamicMenu.propTypes = {
    id: PropTypes.string.isRequired,
    trigger: PropTypes.shape({
        name: PropTypes.string.isRequired,
        onItemClick: PropTypes.func.isRequired,
        allowRemoval: PropTypes.bool
    }).isRequired
};


const DynamicContextMenu = connectMenu(MENU_TYPE)(DynamicMenu);
export default DynamicContextMenu;
