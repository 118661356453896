
/*
 * MODIFIED
 *    $Date: Thu Jul 15 18:36:33 IDT 2021$
 */

import React, { useEffect, useState, createRef }  from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import './Home.css';

import construction from './crane-construction-svgrepo-com.svg'
import email from './email-svgrepo-com.svg'
import database from './database.svg'
import news from './newspaper-news-svgrepo-com.svg'
import MAGELEMBLlogo from './MAGEL2-EMBLlogo.png'
import externalLinkIcon from '../Disease/external-link-ltr-icon.svg';
//import { fundamentals } from '../../config/settings';
import backToTop from './up-bow-svgrepo-com.svg';
import Genejs_Card from './ScreenShot_Genejs_2020-06-27.png';
import DiseaseHeatmapContentjs from './ScreenShot_Disease_2020-06-27.png';
//import HumanBodyButton from './ScreenShot_DiseaseHumanBody_2020-06-24.png';
import HumanBodySidebar from './ScreenShot_DiseaseHumanBodySidebar_2020-06-24.png';
import DownloadDropdown from './ScreenShot_DownloadDropdn_2020-06-24.png';
import ShowingDropdown from './ScreenShot_ShowingDropdn_2020-06-24.png';
import Genejs_UCSC from './ScreenShot_GenejsUCSC_2020-06-24.png';
import Genejs_Table from './ScreenShot_Genejs_Table_2020-06-25.png';
import FABRIC_Footer from './ScreenShot_Footer_2020-06-27.png';
import FABRIC_Header from './ScreenShot_Header_2020-06-27.png';
import { SvgRightArrow, SvgUpArrow, SvgDownArrow, SvgArrow } from './right-arrow-svgrepo-com';

// create a list of links to anchors down the 'instructions' text
const SPAN = () => {
  const onLoad = () => instructions.map(({title, short},i) => <span key={title} className="comma"><a style={{ paddingRight: 1}} href={`#${short}`} key={`chapterlink${i}`}>{title}</a></span>)
  const [containerEl] = useState(onLoad());
  return <>{containerEl}</>;
}

const inViewport = bounding => {
  if (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
  ) {
    console.log('In the viewport!'); return true;
  } else {
      console.log('Not in the viewport... whomp whomp'); return false;
  }
}

const fadeInOut = ( ref, e, dir = 'down' ) => {
  const a = ref.current.getBoundingClientRect();
  let y = a.top;
  y = y + window.pageYOffset - 0.1*(y + window.pageYOffset );
  if (!inViewport(a)) window.scrollTo(0, y);
  ref.current.classList.add("fade-it-" + dir);
  setTimeout(() => ref.current.classList.remove("fade-it-" + dir), 2500) 
}


const instructions = [
  {
    img: null,
    title: 'Introduction',
    short: 'tutorial-intro',
    example: null,
    date: new Date('2020-06-27'),
    content: () => <Container className="pb-4">
	In the following tutorial we walk through the main functions of the FABRIC cancer portal. There are some conventions that we use throughout the text: 
	<Row className="indented"><Col>
	    <ul><li>
		The tutorial contains the following chapters: <SPAN /></li>
	      <li>Screen-shots from the website are embedded in the paragraphs as figures. Sections of interest on the image are marked by colored arrows (<SvgDownArrow width={12} height={12} style={{ fill: 'red', cursor: 'pointer' }}/>, <SvgRightArrow width={12} height={12} style={{ fill: 'blue', cursor: 'pointer' }}/>, <SvgUpArrow width={12} height={12} style={{ cursor: 'pointer' }}/>, ...). The arrows provide anchors referencing the image from the textual description. A click on the small arrow briefly animates the referenced image.</li>
	      <li>Links to external websites will always open in a new tab. This includes any links that are marked with the symbol: <img src={externalLinkIcon} alt='external link' />
	      </li>
	    </ul>
	</Col></Row>
    </Container>
  },
  {
    img: null,
    title: 'The Cancer types screen',
    short: 'tutorial-disease',
    example: <a href="/disease/UCEC">UCEC</a>,
    date: new Date('2020-06-25'),
    content: (myrefs) => <Container className="pb-4">
      <Row className="pb-2"><Col>(<SvgDownArrow onClick={(e) => fadeInOut(myrefs[0], e) } width={12} height={12} style={{ fill: 'red', cursor: 'pointer' }}/>) The Cancer types description screen is the main display in this portal, and focuses on the cancer type. On the top half of the page we give a heat map of cancer types and the top cancer driver genes listed by the cancer type of interest. The column and row headers are clickable so that you can move the focus to a different cancer type, or concentrate on a <a href="#tutorial-gene">gene of interest</a> on a separate page.
	</Col></Row>
	<Row><Col xs={2} sm={2} md={2} lg={2}></Col><Col style= {{ textAlign: 'center' }}>
	    <SvgDownArrow id="cancerTypes-mainTable" thisref={myrefs[0]} width={30} height={30} style={{ fill: 'red' }}/> 
       </Col>
       <Col xs={2} sm={2} md={2} style={{ zIndex: 9999 }}>
	   <SvgArrow thisref={myrefs[1]} width={30} height={30} transform='rotate(90deg) translate(200%,0)' /> 
	</Col></Row>
	<Row><Col>
	<img style={{ width: '90%' }} src={DiseaseHeatmapContentjs} alt='cancer types screen' />
	</Col></Row>
	<Row className="pb-2">
	  <Col sm={6} md={6} lg={6}></Col><Col sm={2} md={2} lg={2}><SvgUpArrow thisref={myrefs[5]} width={30} height={30} style={{ fill: 'blue' }} /> </Col>
	  <Col> <SvgUpArrow thisref={myrefs[3]} width={30} height={30} style={{ fill: "green" }} /></Col>
	</Row>
	<Row className="align-vert pb-2"><Col>(<SvgUpArrow onClick={(e) => fadeInOut(myrefs[3], e, 'up') } width={12} height={12} style={{ fill: 'green', cursor: 'pointer' }}/>, <SvgRightArrow onClick={(e) => fadeInOut(myrefs[4], e, 'right') } width={14} height={14} style={{ fill: 'green', cursor: 'pointer' }}/>) Immediately below the table there are several control buttons that are worth considering. One is the button that controls the number of visualized rows, the other is a download feature. You can download a csv file or a heatmap image. The csv file contains four columns per cancer type that denote the different statistics at each association of gene to cancer type. The parameters are described <a href="/FAQ#whatAreTheMetrics">in the FAQ section</a>.
	  </Col>
	  <Col sm={1} md={1} lg={1}> <SvgRightArrow thisref={myrefs[4]} width={30} height={30} style={{ fill: 'green' }}/> </Col>
	  <Col sm={4} md={4}>
	    <img style={{ width: '48%' }} className="px-2" src={ShowingDropdown} alt='dropdown open' />
	    <img style={{ width: '48%' }} className="px-2" src={DownloadDropdown} alt='download dropdown' />
	  </Col>
	</Row>
	<Row ><Col>(<SvgUpArrow onClick={(e) => fadeInOut(myrefs[5], e, 'up') } width={12} height={12} style={{ fill: 'blue', cursor: 'pointer' }}/>) Also immediately under the heat map sits the color scale. This is a -log(q-value) wmultiplied by the sign of the z-score. In this fashion, negative values mark a cancer-neutralizing gene and positive values designate a cancer-promoting gene. The base 10 logarithm of q is saturated at 15 to designate q &lt; 1e-15.
	</Col></Row>
	<Row className="align-vert pb-2">
	  <Col>(<SvgDownArrow onClick={(e) => fadeInOut(myrefs[1], e) } width={12} height={12} style={{ cursor: 'pointer' }} />, <SvgRightArrow onClick={(e) => fadeInOut(myrefs[2], e, 'right') } width={14} height={14} style={{ cursor: 'pointer' }}/>) On the right hand side of the viewport you can find a button with an icon of the human body. Pushing this button opens up a map of the body with the cases listed per cancer type. Creation of this map was inspired by <a href="https://portal.gdc.cancer.gov">Genomic Data Commons<img src={externalLinkIcon} alt='external link'  /></a> and is fully click-responsive.</Col>
	  <Col sm={1} md={1} lg={1}> <SvgRightArrow thisref={myrefs[2]} width={30} height={30}/> </Col>
	  <Col sm={4} md={4} lg={4}>
	    <img style={{ width: '90%', float: 'right' }} src={HumanBodySidebar} alt='sidebar' />
	  </Col>
	</Row>
	<Row>
	  <Col>
	    The bottom half of the screen hosts a <i>Top-15 table</i>. The table lists the top (most significant) genes for the currently selected cancer type. The rows are, again, clickable and will direct you to the <a href="#tutorial-gene">respective gene's screen</a>
	  </Col>
	</Row>
    </Container>
  },
  {
    img: null,
    title: 'The Gene screen',
    short: 'tutorial-gene',
    example: <a href="/gene/CTCF">CTCF</a>,
    date: new Date('2020-06-24'),
    content: (myrefs) => <Container className="pb-4">
      <Row className="pb-2"><Col>The Gene screen isn't accessible from the menu, you can reach it by searching for a gene in the <a href="/search">search page</a> or clicking on a gene's name listed in the Cancer types screen. The top of the Gene description screen carries an <i>introductory card</i> (<SvgUpArrow onClick={(e) => fadeInOut(myrefs[6], e, 'up') } width={12} height={12} style={{ fill: 'blue', cursor: 'pointer'  }}/>) and a <i>protein families</i> subdivision track (<SvgUpArrow onClick={(e) => fadeInOut(myrefs[7], e, 'up') } width={12} height={12} style={{ fill: 'green', cursor: 'pointer'  }}/>). Let us walk through the panels starting with the left one:
	</Col></Row>
	<img style={{ width: '90%' }} src={Genejs_Card} alt='card section of the gene screen' />
	<Row>
	  <Col  sm={3} md={3} lg={3}></Col><Col> <SvgUpArrow thisref={myrefs[6]} width={30} height={30} style={{ fill: 'blue' }} /></Col>
	  <Col> <SvgArrow thisref={myrefs[7]} width={30} height={30} style={{ fill: "green" }} transform='translate(0,-300%) rotate(-90deg)' /></Col>
	</Row>
	<Row ><Col>(<SvgUpArrow onClick={(e) => fadeInOut(myrefs[6], e, 'up') } width={12} height={12} style={{ fill: 'blue', cursor: 'pointer' }}/>) The card on the left panel contains the name of the gene, its common symbol, and several of its properties such as its length. It also lists external websites that may hold further information on structural and cancer-related properties of the gene.
	</Col></Row>
	<Row className="align-vert pb-2">
	  <Col sm={7} md={7} lg={7}>(<SvgRightArrow onClick={(e) => fadeInOut(myrefs[8], e, 'right') } width={12} height={12} style={{ cursor: 'pointer' }} />) These websites are marked by clickable logos. Right-clicking the external website logo will pop a menu offering to copy the gene's id. This pop up is context-sensitive, thus right-click UCSC will show the chromosomal coordinates. The popup in action is illustrated here on the right</Col>
	  <Col sm={1} md={1} lg={1}> <SvgRightArrow thisref={myrefs[8]} width={30} height={30}/> </Col>
	  <Col>
	    <img style={{ width: '100%', float: 'right' }} src={Genejs_UCSC} alt='UCSC connection' />
	  </Col>
	</Row>
	<Row className="pb-2"><Col>(<SvgUpArrow onClick={(e) => fadeInOut(myrefs[7], e, 'up') } width={12} height={12} style={{ fill: 'green', cursor: 'pointer'  }}/>) The tick marks on the protein families track (right panel, alternating black and white dashes) count amino acid positions, not nucleotides. This can be easily inferred from comparing to the 'Coding Sequence length' stated on the card.
	</Col></Row>
	<Row>
	  <Col>
	    (<SvgDownArrow onClick={(e) => fadeInOut(myrefs[9], e, 'down') } width={12} height={12} style={{ fill: 'red', cursor: 'pointer'  }}/>) On the bottom half of the screen you will find a table listing all the cancer types with observed mutations in that gene. For each cancer type (comprising a row in that table), we give the relevant summary statistics of FABRIC in the context of the gene. The color scale runs from red to white to blue <div
              className="gradColorBar"
	      style={{ display: 'inline-block', lineHeight: '15px', width: 80, color: 'white', fontSize: 'small' }}>&nbsp;</div> and follows the same color scheme of the <a href="#tutorial-disease">heat map</a>. The table is pre-sorted by the q-value. You can sort it differently by clicking on the column header of the desired field. finally, pressing on a row will lead to a cancer-type heatmap oriented around the current gene, irrespective of <a href="/FAQ#noGene">its rank</a>.
	  </Col>
	</Row>
	<Row><Col></Col><Col style= {{ textAlign: 'center' }}>
	   <SvgDownArrow thisref={myrefs[9]} width={30} height={30} style={{ fill: 'red' }}/> 
	</Col><Col></Col></Row>
	<Row>
	  <Col>
	    <img style={{ width: '100%' }} src={Genejs_Table} alt='gene screen table' />
	  </Col>
	</Row>
    </Container>
  },
  {
    img: null,
    title: 'The Header',
    short: 'tutorial-header',
    date: new Date('2020-06-26'),
    content: (myrefs) => <Container>
	<Row className="py-2"><Col>
	    The site's header lists the functions that the portal may carry. It links to the welcome page (<SvgDownArrow onClick={(e) => fadeInOut(myrefs[10], e, 'down') } width={12} height={12} style={{ fill: 'green', cursor: 'pointer'  }}/>), to the <a href="/disease/PanC">pan-cancer</a> cancer types visualization (<SvgDownArrow onClick={(e) => fadeInOut(myrefs[13], e, 'down') } width={12} height={12} style={{ fill: 'blue', cursor: 'pointer'  }}/>), the <a href="/search">search page</a> (<SvgDownArrow onClick={(e) => fadeInOut(myrefs[14], e, 'down') } width={12} height={12} style={{ fill: 'red', cursor: 'pointer'  }}/>), and several other helpful resources (<SvgDownArrow onClick={(e) => fadeInOut(myrefs[15], e, 'down') } width={12} height={12} style={{ fill: 'black', cursor: 'pointer'  }}/>) such as <a href="/Cite">how to cite us</a> or <a href="/Contact">whom to contact</a> in case of an urgent query.
	</Col></Row>
	<Row>
	  <Col xs={4} sm={4} md={4} lg={4}>
	    <Row>
	      <Col>
		<SvgDownArrow thisref={myrefs[10]} width={30} height={30} style={{ fill: 'green' }}/> 
	      </Col>
	      <Col>
		<SvgDownArrow thisref={myrefs[13]} width={30} height={30} style={{ fill: 'blue' }}/> 
	      </Col>
	      <Col>
		<SvgDownArrow thisref={myrefs[14]} width={30} height={30} style={{ fill: 'red' }}/> 
	      </Col>
	      <Col>
		<SvgDownArrow thisref={myrefs[15]} width={30} height={30} style={{ fill: 'black' }}/> 
	      </Col>
	    </Row>
	  </Col>
	  <Col>
	  </Col>
	  <Col style= {{ textAlign: 'center' }}>
	  </Col>
	  <Col>
	  </Col>
      </Row>
      <Row className="pb-2">
	<Col>
	  <img style={{ width: '90%' }} src={FABRIC_Header} alt='header' />
	</Col>
      </Row>
    </Container>
  },
  {
    img: null,
    title: 'The Footer',
    short: 'tutorial-footer',
    date: new Date('2020-06-23'),
    content: (myrefs) => <Container>
	<Row className="py-2"><Col>
	    (<SvgDownArrow onClick={(e) => fadeInOut(myrefs[11], e, 'down') } width={12} height={12} style={{ fill: 'blue', cursor: 'pointer'  }}/>) The footer contains the site map where all the header menu items are listed.</Col></Row>
	<Row><Col>(<SvgUpArrow onClick={(e) => fadeInOut(myrefs[12], e, 'up') } width={12} height={12} style={{ fill: 'green', cursor: 'pointer' }}/>) The latest database version will be marked on the bottom right corner.
	</Col></Row>
	<Row><Col></Col><Col style={{ textAlign: 'center' }}>
	   <SvgDownArrow thisref={myrefs[11]} width={30} height={30} style={{ fill: 'blue' }}/> 
	</Col><Col></Col></Row>
	<Row className="pb-2"><Col style={{ textAlign: 'center' }}>
	<img style={{ width: '90%' }} src={FABRIC_Footer} alt='footer' />
	</Col></Row>
	<Row>
	  <Col  sm={7} md={7} lg={7}></Col><Col> </Col>
	  <Col> <SvgUpArrow thisref={myrefs[12]} width={30} height={30} style={{ fill: "green" }} /></Col>
	</Row>
    </Container>
  }
];

const announcements = [{
  img:<img style={{ width: 50 }} src={construction} alt='under construction' />,
  title: 'This site is under construction',
  date: new Date('2020-06-08'),
  content: 'Expected date for alpha release: Jul 1, 2020. Please refer to this page for further announcements.'
},
  {
    img: <img style={{ width: 45 }} src={email} alt='email' />,
    title: 'We have a new email address',
    date: new Date('2020-06-09'),
    content: <>Please find it <a href="/Contact">here</a></>
  },
  {
    img: <img style={{ width: 50 }} src={database} alt='database' /> ,
    title: 'Database version 3.3 introduced',
    date: new Date('2020-06-18'),
    content: <div style={{ minHeight: 0 }}><div style={{ float: 'left', width: '70%' }}>Whenever protein families data are not available from Cosmic (e.g. <a href="/gene/MAGEL2">MAGEL2</a>), we load pfam information from <a target="_blank" href="http://pfam.xfam.org" rel="noopener noreferrer">EMBL-EBI <img src={externalLinkIcon} alt='external link'  /></a>. Watch out for the resource logo on the pfam track:</div><div style={{ float: 'left', width: '30%' }}><img src={MAGELEMBLlogo} style={{ width:250, float: 'right', marginTop: 0 }} alt='EMBL' /></div><br/><br/></div>
  },
  {
    img: <img style={{ width: 50 }} src={database} alt='database' /> ,
    title: 'Database version 3.4 introduced',
    date: new Date('2020-06-21'),
    content: <>EMBL pfam titles are cached. If a gene does not have a pfam description associataed, it is downloaded as per v3.3 and then saved in the database.</>
  },
  {
    img: <img style={{ width: 50 }} src={database} alt='database' /> ,
    title: 'Database version 3.6 introduced',
    date: new Date('2020-08-03'),
    content: <>Several corrections, such as splitting three uniprot ids to GNAS, GNAS1 and GNAS2.</>
  },
  {
    img: <img style={{ width: 50 }} src={news} alt='news' /> ,
    title: 'Updated the tutorial',
    date: new Date('2020-06-27'),
    content: <>The latest <a href="#tutorial-head">tutorial</a> is colorful, it links to the corners of the website, and contains animated reference arrows.</>
  },
  {
    img: <img style={{ width: 50 }} src={database} alt='database' /> ,
    title: 'Database version 3.5 introduced',
    date: new Date('2020-06-30'),
    content: <>Added 224 genes with zero observations from the FABRIC analysis. (e.g. <a href="/gene/GAGE12B">GAGE12B</a>)</>
  }
]


const [ textUpdated, ...rest ] = announcements.sort( (a,b) => b.date - a.date).map( ({date}) => date );
const [ instructionsUpdated, ...rest2 ] = instructions.sort( (a,b) => b.date - a.date).map( ({date}) => date );


const Home = () => {

  let myrefs = [];
  const numArrows = 20;
  myrefs = [ ...Array( numArrows ).keys() ]
	   .map(() => createRef())

  return (
  <><div className="fabricWatermark">
  <h1 className="h4" style={{ paddingTop: 20 }}>The FABRIC Cancer Portal</h1>
  <div className="fabricPageContainer">
    <h4>&nbsp;</h4>
    <div style={{ lineHeight: '1.6em' }} >
      Welcome to the FABRIC Cancer Portal, a comprehensive catalogue of human coding genes in cancer based on the FABRIC framework. FABRIC quantifies the selection of genes in tumor and weighs their evidence for being cancer drivers.
      </div>
      <div>
	For more details about FABRIC and the portal, please refer to the website's <a href="/FAQ">FAQ</a> section or the <a href="#tutorial-head">tutorial</a>.
    </div>
  </div>

  <div id="highlights" className="fabricPageContainer">
    <h4>Highlights <font style={{ fontSize: 9 }}>updated Sun Aug 9 2020</font>
      </h4>
    <ul style={{ listStyleType: 'disc' }}>
      <li style={{ padding: '5px 0px' }}>A comprehensive catalog covering the entire human coding genome (~18,000 protein-coding genes) across all 33 TCGA cancer types (with the additional pan-cancer view).</li>

      <li style={{ padding: '5px 0px' }}>Simple visual interface allowing a seamless switch between gene-centric and cancer-type views.</li>

      <li style={{ padding: '5px 0px' }}>Clear ranking of genes by evidence for their selection in cancer, based on rigorous and robust statistics.</li>

      <li style={{ padding: '5px 0px' }}>Gene selection is quantified by combining genomic and proteomic data, while taking into account the rich and diverse proteomic annotations. This includes phosphorylation and other post-translational modifications, protein domains and secondary structure annotations.</li>

      <li style={{ padding: '5px 0px' }}>Cross-references to the most relevant resources providing genomic, proteomic and cancer-related information. </li>

    </ul>

  </div>
  <br/>

  <div id="tutorial-head" className="fabricPageContainer">
    <h4>Instructions <font style={{ fontSize: 9 }}>updated {instructionsUpdated.toDateString()}</font>
      </h4>
    <ul style={{ listStyleType: 'none' }}>
      { instructions
	.sort( (a,b) => b.date - a.date)
	.map( ({img, title, example, short, date, content}, i) =>
	<li key={i} id={short}>{img} <strong>{title}</strong>
	  { example && <Container><Row><Col><font style={{ fontWeight: 'bold', fontSize: 'smaller'}}>Example: {example}</font></Col></Row></Container> }
	<div style={{ textAlign: 'justify', width: '95%' }}>{content(myrefs)}</div>
	<a className="backToTop" href="#tutorial-head"><span  style={{ backgroundImage: `url(${backToTop})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>&nbsp;&nbsp;&nbsp;</span> Back to the top of the instructions section</a>
	<hr/>
      </li>
      )}
  </ul>

  </div>
  <br/>
  <div id="announcements" className="fabricPageContainer">
    <h4>Announcements <font style={{ fontSize: 9 }}>updated {textUpdated.toDateString()}</font>
      </h4>
    <ul style={{ listStyleType: 'none' }}>
      { announcements.sort( (a,b) => b.date - a.date).map( ({img, title, date, content}) =>
	<li key={title} style={{ marginBottom: '1em' }}>{img} <strong>{title}</strong>&nbsp;
	  <font style={{ fontSize: 9 }}>{date.toDateString()}</font>
	<div>{content}</div>
      </li>
      )}
  </ul>
  </div>
  <br/>
  </div>
    </>);
}

export default Home;
