/*
 * MODIFIED
 *    $Date: Sun Jul 26 23:23:01 IDT 2020$
 */

import React from 'react';
import {
  Container, Row, Col
} from 'react-bootstrap';
import './Footer.css';
import newLogo from './new-svgrepo-com.svg';

import { fundamentals } from '../../config/settings';

const isDev = process.env.NODE_ENV === 'development';
const revision = process.env.REACT_APP_GIT_SHA;
const data_tag = process.env.REACT_APP_GIT_TAG || fundamentals['database-ver'];
const data_revision = data_tag.replace(/data_/, '');

const Footer = () => 
  <div className="main-footer">
    <Container>
      <Row style={{textAlign: 'left' }}>
	<Col md={4} sm={6}>
	  <h4>Main actions</h4>
	  <ul className="list-unstyled">
	    <li><a href="/g/disease/PanC">Cancer types</a></li>
	    <li><a href="/g/search">Search</a></li>
	  </ul>
	</Col>
	<Col md={4} sm={6}>
	  <h4>Help</h4>
	  <ul className="list-unstyled">
	    <li><a href="/#tutorial-head">Tutorial</a></li>
	    <li><a href="/g/Contact">Contact us</a></li>
	    <li><a href="/g/Cite">Cite us</a></li>
	    <li><a href="/g/Crossref">Cross references</a></li>
	  </ul>
	</Col>
	<Col md={4} sm={6}>
	  <h4>More</h4>
	  <ul className="list-unstyled">
	    <li><a href="/#announcements">Announcements<img src={newLogo} style={{ width:10}} alt='announements' /></a></li>
	    <li><a href="/g/FAQ">FAQ</a></li>
	    <li><a href={(isDev ? '' : '/static') + '/sitemap.xml?v=' + revision}>Sitemap</a></li>
	    <li>FABRIC database version {data_revision}</li>
	  </ul>
	</Col>
      </Row>
    </Container>
  </div>;

export default Footer;
