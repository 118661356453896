/*
 * MODIFIED
 *    $Date: $
 */

import _ from 'lodash';

const magnify = (imgID, zoom, op = 'On') => {
  let img, glass, w, h, bw;
  img = document.getElementById(imgID);
  const glassID = `magnifier-glass_${imgID}`

  const getCursorPos = (e) => {
    var a, x = 0, y = 0;
    e = e || window.event;
    /* Get the x and y positions of the image: */
    a = img.getBoundingClientRect();
    /* Calculate the cursor's x and y coordinates, relative to the image: */
    x = e.pageX - a.left;
    y = e.pageY - a.top;
    /* Consider any page scrolling: */
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    return {x : x, y : y};
  }

  const showMagnifier = (e) => {
    glass = document.getElementById(glassID);
    if (!_.isNil(glass)) {
      glass.style.display = 'block';
    }
  }

  const hideMagnifier = (e) => {
    glass = document.getElementById(glassID);
    if (!_.isNil(glass)) {
      glass.style.display = 'none';
    }
  }

  const moveMagnifier = (e) => {
    var pos, x, y;
    /* Prevent any other actions that may occur when moving over the image */
    e.preventDefault();
    /* Get the cursor's x and y positions: */
    pos = getCursorPos(e);
    x = pos.x;
    y = pos.y;
    /* Prevent the magnifier glass from being positioned outside the image: */
    if (x > img.width - (w / zoom)) {x = img.width - (w / zoom);}
    if (x < w / zoom) {x = w / zoom;}
    if (y > img.height - (h / zoom)) {y = img.height - (h / zoom);}
    if (y < h / zoom) {y = h / zoom;}
    /* Set the position of the magnifier glass: */
    glass.style.left = (x - w) + "px";
    glass.style.top = (y - h) + "px";
    /* Display what the magnifier glass "sees": */
    glass.style.backgroundPosition = "-" + ((x * zoom) - w + bw) + "px -" + ((y * zoom) - h + bw) + "px";
  }

  const magnifyOn = () => {
    /* Create magnifier glass: */
    console.log('On')
    glass = document.getElementById(glassID);
    if (!_.isNil(glass)) {
      glass.style.display = 'block';
    } else {
      glass = document.createElement("DIV");
      glass.id = glassID
      glass.setAttribute("class", "img-magnifier-glass");

      /* Insert magnifier glass: */
      img.parentElement.insertBefore(glass, img);
      /* Set background properties for the magnifier glass: */
      glass.style.backgroundImage = "url('" + img.src + "')";
      glass.style.backgroundRepeat = "no-repeat";
      glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";

      bw = 3;
      w = glass.offsetWidth / 2;
      h = glass.offsetHeight / 2;

      /* Execute a function when someone moves the magnifier glass over the image: */
      glass.addEventListener("mousemove", moveMagnifier);
      img.addEventListener("mousemove", moveMagnifier);

      /*and also for touch screens:*/
      glass.addEventListener("touchmove", moveMagnifier);
      img.addEventListener("touchmove", moveMagnifier);

      glass.addEventListener("mouseleave", hideMagnifier);
      img.addEventListener("mouseleave", hideMagnifier);

      glass.addEventListener("mouseenter", showMagnifier);
      img.addEventListener("mouseenter", showMagnifier);

      glass.style.display = 'none';
    }
  }

  const magnifyOff = () => {
    console.log('Off')
    glass = document.getElementById(glassID);
    if (!_.isNil(glass)) {
      glass.removeEventListener("mousemove", moveMagnifier);
      img.removeEventListener("mousemove", moveMagnifier);

      /*and also for touch screens:*/
      glass.removeEventListener("touchmove", moveMagnifier);
      img.removeEventListener("touchmove", moveMagnifier);

      glass.removeEventListener("mouseleave", hideMagnifier);
      img.removeEventListener("mouseleave", hideMagnifier);

      glass.removeEventListener("mouseenter", showMagnifier);
      img.removeEventListener("mouseenter", showMagnifier);

      img.parentElement.removeChild(glass);
    }
  }

  if (op === 'On')
    magnifyOn()
  else if (op === 'Off')
    magnifyOff()
}

export default magnify;
