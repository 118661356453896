/* eslint no-param-reassign: warn */
/* eslint no-nested-ternary: warn */
/* eslint react/no-did-update-set-state: warn */

/*
 * MODIFIED
 *    $Date: Thu Aug  6 23:10:37 IDT 2020$
 */

import React, { Component, useState } from 'react';

import PropTypes from 'prop-types';
import axios from 'axios';
import ReactTooltip from "react-tooltip";
import _ from 'lodash';
import GridDiv from './GridDiv';
import './Search.css';
import {
  Alert,
  Button,
  Container,
  Row, Col
} from "react-bootstrap";
import mapOrder from '../../utils/mapOrder';

const unique = arr =>
  _.values(
    arr.reduce((a, c) => {
      a[c.hierarchy] = c;
      return a;
    }, {})
  );

const strcmp = (a, b) =>
  b.hierarchy > a.hierarchy ? -1 : b.hierarchy < a.hierarchy ? 1 : 0;

const kvcmp = (a, b) => (b.val < a.val ? -1 : b.val > a.val ? 1 : 0);

// experiment-level search query
const where = ({ query, limit = 15, noWhere = false }) => {
  let qObj = {
    where: {
      and: [
        { type: 'EXPERIMENT' },
        {
          or: [
            { elem_name: { regexp: query } },
            { stage: { regexp: query } },
            { project: { regexp: query } },
            { note: { regexp: query } },
            { external_note: { regexp: query } },
            { internal_note: { regexp: query } },
            { owner: { regexp: query } }
          ]
        }
      ]
    },
    limit,
    order: [`created DESC`, `coll_date DESC`]
  };
  if (noWhere) ({ where: qObj } = qObj); // strip the 'where'
  return JSON.stringify(qObj);
};

// hierarchy-based query. Generally used for injection-level
// retrievals. h is set to a or'ed regular expression string. e.g.
//
// '021O00010001....|021N00010001....|021M00010001....|021L00010001....|01T400010001....|02Z800010001....|01DL00010001....|01D200010001....|01CZ00010001....|01CX00010001....|01CV00010001....|01CQ00010001....|01CP00010001....|01CO00010001....|01CN00010001....'
//
const whereId = ({ query: h }) =>
  JSON.stringify({
    where: { hierarchy: { regexp: `^(${h})$` } },
    order: [`created DESC`, `coll_date DESC`]
  });

const NotFound = ({ query }) => {
  const [show, setShow] = useState(true);

  return (
    <Container>
      <Row>
	<Col>{ show ?
	      <Alert
		variant='danger'
		onClose={() => setShow(false)} dismissible
	      >
		<Alert.Heading>No Results</Alert.Heading>
		<p>The search string &quot;<b>{query}</b>&quot; did not match any gene.</p>
		<p>Try another search string. Refer to the examples below</p>
	      </Alert> :
	      <Button
		size="sm"
		variant="outline-danger"
		onClick={() => setShow(true)}
	      >Show Alert
	      </Button>
	      }
	  </Col>
      </Row>
    </Container>
  );
}

  const keys = [
    /* 'gene_index', */
    'uniprot_id',
    'gene_symbol',
    'gene_name',
    'refseq_id',
    'chr',
    /*
    'fro',
    'to', */
    'cds_len',
  ];


const Suggestions = props => {
  const { results, query, api } = props;
  const Query = new RegExp( `(${query})`, 'ig' );
  const QueryBeg = new RegExp( `(^${query}|;${query})`, 'ig' );
  const QueryBegWord = new RegExp( `(^${query};?|;${query};?|;${query}$)`, 'ig' );
  // highlight of the query
  const Replacement = '<font style="background: yellow">$1</font>';
  console.log('RESULTS', props);
  if (_.isNil(results) || /^$/.test(query)) return null;
  if (results.length === 0 ) return <NotFound query={query}/>;

  const grid = 'cancer types'
  // const hdrKeys = [...new Set(results.slice(0, 5).map(r => _.keys(r)))];
  const headers = (
    <li className={'search-header'}>
      {[...keys, grid]
        // .filter(k => results[0][k])
        .map(k => (
          <span key={`head_${k}_${results[0].gene_index}`}>
            <b>{k}</b>
          </span>
        ))}
    </li>
  );

  ReactTooltip.rebuild() // https://github.com/wwayne/react-tooltip/issues/134

  const classes = 'search-row';
  const giveDate = val =>
    val === '0000-01-01T00:00:00.000Z' ? (
      <b style={{ color: '#ee1111' }}>no date</b>
    ) : (
      new Date(val).toLocaleDateString()
    );

  // number the lines, make sure gene_symbol appears first so that
  // any line that does not pass the special sort will be ordered by
  // gene symbol
  const lines = results.map((r,i) => 
    ({ num: i, line: ['gene_symbol', ...keys].map( k => r[k] ).join(';') })
  );
  // the special ordering - first by query in the beginning of the
  // field, then lexicographically.
  const order = lines.sort((a,b) => {
    if (a.line.match(QueryBegWord) && b.line.match(QueryBegWord))
      return (a.line < b.line) ? -1 : (a.line > b.line) ? 1 : 0;
    if (a.line.match(QueryBegWord)) return -1;
    if (b.line.match(QueryBegWord)) return 1;
    return (a.line < b.line) ? -1 : (a.line > b.line) ? 1 : 0;
  }).map( o => o.num )

  // https://stackoverflow.com/a/21945724
  // extend an array w/a custom function
  const arrayExt = (array = []) => {
    array.order = (a,b) => mapOrder(array,a,b);
    return array;
  }
  // add the 'num' serial numbering of the rows
  const resultsOrder = arrayExt(results.map( (r,i) => _.assign({}, r, { num: i })) )
  const options = resultsOrder
    .order(order, 'num') // apply the special ordering fn
    .map((r,i) => {
      //const clsextra = r.type === 'EXPERIMENT' ? 'nth' : '';
      const clsextra = 'nth';
      return (
	<li className={[classes, clsextra].join(' ')} key={r.gene_index}>
	  {[...keys, grid]
	    // .filter(k => r[k])
	    .map(k => (
	      <span
		key={`${k}_${r.uniprot_id}`}
		className={
		  k === 'note' || k === grid
		    ? 'search-collection wide'
		    : ''
		}
	      >
		{r[k] ? (
		  /created/.test(k) ? (
		    giveDate(r[k])
		  ) : r[k] === -9999 ? (
		    <b style={{ color: '#ee1111' }}>no {k}</b>
		  ) : (
		    <a href={`/g/gene/${r.gene_symbol}`}>{
		      <div
			dangerouslySetInnerHTML={{
			  __html: String(r[k]).replace(Query, Replacement)
			}} />
		    }</a>
		  )
		) : (
		  k === grid ? 
		    <a href={`/g/gene/${r.gene_symbol}`}>
		  <GridDiv key={`grid_${r.gene_symbol}`} gene_symbol={r.gene_symbol} api={api} /></a> :
		  '.'
		)}
	      </span>
	    ))}
	  <div key={`dates_${r.gene_symbol}`}>
	    {Object.keys(r)
	      .filter(k => /_date/.test(k) && r[k])
	      .map(k => ({ key: k, val: r[k] }))
	      .sort(kvcmp)
	      .reverse()
	      .map(o => (
		<span
		  className={'search-collection'}
		  key={`${o.key}_${r.hierarchy}`}
		>
		  {o.key}: {giveDate(o.val)}
		</span>
	      ))}
	  </div>
	</li>
      );
  });

  return (
    <div className={'tableContainer'}>
      <ReactTooltip id="dynamic"/>
      <ul className={'search-table'}>
        <div className={'thead'}>{headers}</div>
        <div className="">{options}</div>
      </ul>
    </div>
  );
};

Suggestions.propTypes = {
  results: PropTypes.instanceOf(Object),
  api: PropTypes.instanceOf(Object)
};

Suggestions.defaultProps = {
  results: null,
  api: {
    apiHost: 'http://localhost:8000',
    apiUrl: '/api'
  },
};

/*
 * In this component we use setTimeout to start a timer as soon as
 * the user enters text. If the user enters another character, the
 * timer restarts. If the user does not type again before the timer
 * completes, it will dispatch a query to the database
 */
class Search extends Component {
  state = {
    query: '',
    query_params: null,
    results: null,
    adjusting: false,
    counts: 0
  };

  static propTypes = {
    api: PropTypes.instanceOf(Object),
    limit: PropTypes.number,
    selectAll: PropTypes.bool
  };

  static defaultProps = {
    api: {
      apiHost: 'http://localhost:8000',
      apiUrl: '/api'
    },
    limit: 15,
    selectAll: true
  };

  constructor(props) {
    super(props);
    this.checkboxes = [];
  }

  componentDidMount() {
    this.search.focus()
    this.checkboxes.forEach( ch => ch.checked = true )
    this.setState({ query_params: _.assign( {}, ...this.checkboxes.map( ch => ch ? ({[ch.getAttribute('name')]: ch.checked}) : {} ) )
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { query: thisQ, query_params: thisP, adjusting } = this.state;
    const { query: prevQ, query_params: prevP } = prevState;
    const { selectAll: thisAll } = this.props;
    const { selectAll: prevAll } = prevProps;
    if (!_.isNil(prevAll) && !_.isNil(thisAll) && ( prevAll !== thisAll ) ) {
      console.log('toggle', thisAll)
      this.checkboxes.forEach( ch => ch.checked = thisAll )
      this.setState({ query_params: _.assign( {}, ...this.checkboxes.map( ch => ch ? ({[ch.getAttribute('name')]: ch.checked}) : {} ) )
      })
    }
    // handle typing timer
    if (prevQ !== thisQ || !_.isEqual(prevP, thisP)) {
      this.handleTyping();
    }

    // handle adjusting the sizes when the component stops loading data
    if (adjusting) return;
    const { results: thisResults, counts: thisCounts } = this.state;
    const { results: prevResults } = prevState;
    console.log('prevState', prevResults);
    console.log('thisState', thisResults);
    if (_.isNil(thisResults)) return;
    if (/*prevResults.length === 0 && */ thisResults.length === 0) return;
    if (prevState.counts === thisCounts) return;
    //if (prevResults.length === thisResults.length) return;
    if (!adjusting)
      this.setState({ adjusting: true }, () =>
        setTimeout(this.adjustHeader('nth', 'search-header'), 1000)
      );
  }

  // query the database
  getInfo = ({
    what = k => where(k),
    query: query_,
    query_params = null,
    kind = 'free',
    limit = 15
  }) => {
    let query = query_;
    if (query_ === undefined) ({ query } = this.state);
    if (what === null) return () => true;

    let q_param = '';
    if (query_params) {
      q_param = '?' + _.keys(query_params).map( k => `${k}=${query_params[k]}` ).join('&')
    }
    const {
      api
    } = this.props;

    //let filter = what({ query, limit });
    axios
      .get(
	`${api.apiHost}${api.apiUrl}/search/${query}/${q_param}`
      )
      .then(({ data }) => {
        const { results: thisResults } = this.state;
        switch (kind) {
          case 'free':
            this.setState(
              { results: data.results, counts: data.count }
            );
            break;
          case 'hier':
            this.setState(
              {
                results: unique([...thisResults, ...data]).sort(strcmp)
              },
              this.getInfo({ kind: 'stop', what: null })
            );
            break;
          default:
            this.setState(
              { results: data.results, counts: data.count }
            );
            break;
        }
        return data;
      });

    return () => true;
  };

  // on change of input, make sure space and newlines are not in the query
  handleInputChange = () => {
    const { value } = this.search;
    const v = value.replace( /[\n\r&;')( ^\\]/g, '');
    this.setState({ query: v, counts: 0 } /* , this.fireQueryEvent() */);
  };

  // on change of checkbox state
  handleCheckboxToggle = (i) => {
    this.setState({ query_params: _.assign( {}, ...this.checkboxes.map( ch => ch ? ({[ch.getAttribute('name')]: ch.checked}) : {} ) )
    })
  };

  // disable the [CR] keypress
  handleKey = e => {
    //console.log('key', e.keyCode)
    if (e.keyIdentifier==='U+000A' || e.keyIdentifier==='Enter' || e.keyCode===13){
      e.preventDefault();
      return false;
    }
  }

  // when should we trigger getInfo()? Depending on query size
  fireQueryEvent = () => {
    const { query, query_params } = this.state;
    const { limit } = this.props;
    if (query && query.length > 1) {
      if (query.length <= 25 || query.length % 2 === 0) {
        this.getInfo({ what: k => where(k), limit, query_params });
      }
    } else if (!query || query.length <= 1) {
      this.setState({ results: [] });
      // do nothing
    }
  };

  // Clear the running timer and start a new one each time the user
  // types anything (continues typing within the timeout interval)
  handleTyping = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.fireQueryEvent();
    }, 1000);
  };

  // Adjust the width of the table's header and body columns to the
  // size that is column-wise maximal
  adjustHeader = (classNTH, classHeader) => {
    const byClass = cls => document.getElementsByClassName(cls);

    // column sizes of the tbody
    const [v] = byClass(classNTH); // 'Search__nth--1XcCZ')
    if (!_.isNil(v)) {
      const vc = v.children;
      const ind = [...Array(vc.length).keys()];
      const widthsBody = ind.map(i => vc[i].clientWidth);

      // column sizes of the thead (must be same num of columns)
      const [h] = byClass(classHeader); // ('Search__search-header--3GMDf')
      const hc = h.children;
      const widthsHeader = ind.map(i => hc[i] && hc[i].clientWidth);

      // maximal width of each column (either the head's or the body's
      // client measurement)
      const maxWidths = ind.map(i => Math.max(widthsHeader[i], widthsBody[i]));

      // adjust
      ind.forEach(i => {
	if (hc[i] && vc[i]) {
	  hc[i].style['min-width'] = `${maxWidths[i]}px`;
	  vc[i].style['min-width'] = `${maxWidths[i]}px`;
	}
      });
    }

    // done
    this.setState({ adjusting: false });
  };


  render() {
    const { results, counts, query } = this.state;
    const { limit, api } = this.props;
    return (
      <>
      <form style={{ textAlign: 'center' }}>
        <div className={'center-wrapper'}>
          <input
            placeholder="Search for... (e.g. 'LC5')"
            ref={input => {
              this.search = input;
            }}
            onChange={this.handleInputChange}
	    onKeyDown={this.handleKey}
          />
          {counts > 0 && (
            <>
              <div>&nbsp;{counts} Results</div>
              <div>&nbsp;(limit {limit})</div>
            </>
          )}
        </div>
	  <div>{
	    keys.map( (k,i) => <div
	      key={`${k}_${i}`}
	      style={{ display: 'inline-grid' }}
	      >
	      <label style={{ /* float: 'left',
	      display: 'block' */
	      }}><input
		style={{
		  width: 'auto',
		  padding: 0,
		  margin: '0 2px 0 10px',
		  position: 'relative',
		  top: 2,
		  '*overflow': 'hidden',
		}}
		type="checkbox"
		name={k}
		disabled={ /uniprot/.test(k) }
		ref={input => 
		  i > this.checkboxes.length ?
		    this.checkboxes.push(input) :
		    this.checkboxes[i] = input
		}
		onChange={ () => this.handleCheckboxToggle(i) }
		onKeyDown={this.handleCheckboxKey}
	      />
	      {k}</label>
	    </div>)
	  }</div>
      </form>
        <Suggestions api={api} query={query} results={results} />
      </>
    );
  }
}

export default Search;
