/*
 * MODIFIED
 *    $Date: Tue Jul 21 21:32:39 IDT 2020$
 */

import _ from 'lodash'

const calcColor = (val, scale, colors, rev) => {
  const colorsH = _.assign( {}, colors );
  const binSz = scale / (colors.length-1);
  const I = _.assign( {}, ...[ ...new Array(colors.length).keys()].map( k =>  ({ [k * binSz] : k }) ) )
  const i = parseInt( ( val / Math.max(scale, val) * scale ) / binSz ) * binSz;
  //console.log( 'IIII', i, I[i] )
  return( colorsH[ I[i] ] )
}

// [b] red-to-white
export const RedColorPalette = (val, scale, rev = false) => {
  //                  15           14         13        12          11
  const colors = [ '#fee8ce', '#f9c18d', '#f2a069', '#ee834c', '#f07142',
  //                  10           9         8          7          6
		    '#f15f39', '#f34c2f', '#e74d32', '#db4f37', '#cf513b',
  //                  5           4         3          2          1
                    '#c35340', '#b24148','#a5324f','#972356','#89145d', ];

  //const colors = [ "#ffffff", "#fff7ec", "#fee8c8", "#fdd49e", "#fdbb84", "#fc8d59", "#ef6548", "#d7301f", "#b30000", "#7f0000" ];
  return calcColor(val, scale, rev ? colors.reverse() : colors, rev);
}

// [b] red-to-white
export const GrnColorPalette = (val, scale, rev = false) => {
  const colors = [ "#ffffff", "#f7fcf5", "#e5f5e0", "#c7e9c0", "#a1d99b", "#74c476", "#41ab5d", "#238b45", "#006d2c", "#00441b" ];
  return calcColor(val, scale, rev ? colors.reverse() : colors, rev);
}


// [c] blue-to-white
export const BluColorPalette = (val, scale, rev = false) => {
  const colors = [ '#ffffff', '#F4F8FC', '#deebf7', '#6baed6', '#2171b5', '#08519c', '#08306b' ];
  // const colors = [ "#ffffff", "#f7fbff", "#deebf7", "#c6dbef", "#9ecae1", "#6baed6", "#4292c6", "#2171b5", "#08519c", "#08306b" ];
  return calcColor(val, scale, rev ? colors.reverse() : colors, rev);
}


// [d] gray/black/white
export const MonoColorPalette = (val, scale, rev = false) => {
  const colors = [ "#111", "#111", "#111", "#111", "#111", "#111", "#ddd", "#ddd", "#ddd", "#ddd" ];
  return calcColor(val, scale, rev ? colors.reverse() : colors, rev);
}
