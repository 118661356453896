/*
 * MODIFIED
 *    $Date: Wed Jan 27 09:11:16 IST 2021$
 */

import React from 'react';
import './Crossref.css';
import externalLinkIcon from '../Disease/external-link-ltr-icon.svg';

const Crossref = () =>
  <><div className="fabricWatermark">
  <div>&nbsp;</div>
  <h1 className="h4">Cross references</h1>
  <div className="fabricPageContainer apa-ref">
    <p><strong>FABRIC references the following sources:</strong></p>
  <ul className="indentedList">
    <li>The <b>C</b>atalogue <b>O</b>f <b>S</b>omatic <b>M</b>utations <b>I</b>n <b>C</b>ancer <a href="http://cancer.sanger.ac.uk" target="_blank" rel="noopener noreferrer">COSMIC<img src={externalLinkIcon} alt='external link'/></a></li>
    <li><b>P</b>rotein <b>fam</b>ilies database from EMBL-EBI <a href="http://pfam.xfam.org" target="_blank" rel="noopener noreferrer">Pfam<img src={externalLinkIcon} alt='external link'/></a></li>
    <li><b>T</b>he <b>C</b>ancer <b>G</b>enome <b>A</b>tlas <b>P</b>rogram <a href="https://www.cancer.gov/about-nci/organization/ccg/research/structural-genomics/tcga" target="_blank" rel="noopener noreferrer">TCGA<img src={externalLinkIcon} alt='external link'/></a></li>

    <li>NCBI <b>R</b>eference <b>S</b>equence Database <a href="https://www.ncbi.nlm.nih.gov/refseq" target="_blank" rel="noopener noreferrer">RefSeq<img src={externalLinkIcon} alt='external link'/></a></li>
    <li>The <b>U</b>niversal <b>P</b>rotein <b>R</b>esource <a href="https://www.uniprot.org" target="_blank" rel="noopener noreferrer">Uniprot<img src={externalLinkIcon} alt='external link'/></a></li>
    <li>The Human Gene Database <a href="https://www.genecards.org" target="_blank" rel="noopener noreferrer">GeneCards<img src={externalLinkIcon} alt='external link'/></a></li>
  </ul>

  </div>
  <br/>
  </div>
  </>

export default Crossref;
