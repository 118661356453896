/*
 * MODIFIED
 *    $Date: $
 */

import React, {useState, useEffect, useRef, createRef } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { Raphael,Paper,Set,Circle,Ellipse,Image,Rect,Text,Path,Line } from 'react-raphael';
import ReactTooltip  from 'react-tooltip';
import './PfamMap.css';

import s_ from './histo';

const r = Raphael;

const settings = {
      res_type     : 'CDS', // type can be amino acid(AA) or CDS.
      width        : 750,
      height       : 120,
      im_width     : 550,
      im_height    : 85,
      subStart     : 25,
      subEnd       : 268,
      featureStart : 35,
      featureEnd   : 45,
      comStart     : 345,
      comEnd       : 410,
      insStart     : 420,
      insEnd       : 460,
      delStart     : 460,
      delEnd       : 500,
      gainStart    : 505,
      gainEnd      : 540,
      lossStart    : 540,
      lossEnd      : 570,
      upStart      : 580,
      upEnd        : 615,
      downStart    : 615,
      downEnd      : 645,
      hyperStart   : 655,
      hyperEnd     : 690,
      hypoStart    : 690,
      hypoEnd      : 725,
      scale        : 1,      // which gets overridden by the changesize method
      transX       : 100,
      transY       : 6,
      font10       : {
        height     : 15,
        width      : 8
      },
      dom          : {
        barHt      : 1,
        markupHt   : 20,
        markupRad  : 4,
        featureHt  : 12
      },
      color     : {
        "*"     : "#c1cdcd",
        "_"     : "#c1cdcd",
        "a"     : "#90ee90",
        "A"     : "#c1ffc1",
        "C"     : "#f0e68c",
        "c"     : "#b0c4de",
        "D"     : "#ffd700",
        "E"     : "#eea2ad",
        "F"     : "#ffb90f",
        "g"     : "#ffec8b",
        "G"     : "#3cb371",
        "H"     : "#00ced1",
        "I"     : "#adff2f",
        "K"     : "#f08080",
        "L"     : "#c0ff3e",
        "M"     : "#00ff00",
        "N"     : "#eeaeee",
        "P"     : "#00ff7f",
        "Q"     : "#ffe1ff",
        "R"     : "#bc8f8f",
        "S"     : "#ab82ff",
        "T"     : "#e066ff",
        "t"     : "#eea2ad",
        "V"     : "#9bcd9b",
        "W"     : "#87ceeb",
        "Y"     : "#87cefa",
        'grey'  : "#d3d3d3",
        'red'   : '#FF0000',
        'blue'  : '#0000FF',
        'gain'  : '#fab3a0',
        'loss'  : "#BEE3F8",
        'over'  : "#d66262",
        'under' : "#86BF99",
        'hyper' : "#FF6666",
        'hypo'  : "#0000FF",
      },
      texts:[]
    };

  var options = {};

const DrawObject = () => {

  const myref = useRef(null);
  let myrefs = [];
  const [ boxes, setBoxes ] = useState();
  const [ pfam, setPfam ] = useState();
  const [ texts, setTexts ] = useState();
  const [ bubble, setBubble ] = useState ({x:0,y:0,text:''});

  const myrectref = createRef()
  const  s = _.assign( {}, s_, settings, options );
  let ox, bstart, bstop;

  useEffect( () => {
    const [ pfam ] = s.pfam
    if (!pfam) return;
    const { motifs, regions, markups } = pfam
    const isMarkups = markups && markups.length
    const numMarkups = isMarkups ? markups.length : 0
    const isMotifs = motifs && motifs.length
    const numMotifs = isMotifs ? motifs.length : 0
    const isRegions = regions && regions.length
    const numRegions = isRegions ? regions.length : 0
    console.log('numRegions', numRegions)
    console.log('numMotifs', numMotifs)
    console.log('numMarkups', numMarkups)
    myrefs = [ ...Array( numMarkups + numMotifs + numRegions ).keys() ]
	     .map(() => createRef())
  } )

  // ----------------------------------------------------------------------------
  const con = (v, title = null, key = '') => <div key={key} className="con">
    <div style={{background: '#567EAB', color:'white', fontWeight: 'bold', textAlign: 'center', fontFamily: 'Verdana,Arial,helvetica,sans-serif', fontStyle: 'normal', fontVariantCaps: 'normal', fontStretch: 'normal', borderColor: '#7898BC', ariaAtomic: 'true', fontSize: 'small' }}>{/^ *$/.test(title) ? v.metadata.description : title}</div>
    <div className="ui-tooltip-content">
    {v.metadata.description &&
	!/^ *$/.test(title) && <div ><strong>Description:</strong> {v.metadata.description}</div> }
	{ v.end ? <div ><strong>Coordinates:</strong> {v.start} - {v.end} {
	  v.metadata.aliStart && `(alignment region ${v.metadata.aliStart} - ${v.metadata.aliEnd})` } </div> :
	  <div ><strong>Position:</strong> {v.start}</div>
	}
    <div ><strong>Source:</strong> {v.metadata.database}</div>
    </div>
    </div>;

  // ----------------------------------------------------------------------------
  // extract attributes from a DOM element, place them in an
  // object of their own
  //
  const getAttributes = (el, arr, numeric = true) => {
    const getter = arr.map( e => ({ [e]: numeric ? +el.getAttribute(e) : el.getAttribute(e)}) )
    return _.assign({}, ...getter)
  }

  // ----------------------------------------------------------------------------
  // subset of keys from an object
  // from https://stackoverflow.com/a/56592365/2821963
  let subset = (obj, keys) =>
    Object.keys(obj)
      .filter(key => keys.indexOf(key) >= 0)
      .reduce((obj2, key) => Object.assign(obj2, { [key]: obj[key] }), {});

  // ----------------------------------------------------------------------------
  const _shape = ( data ) => {
    var points = [];
    for (var i = 0; i < data.face; i++) {
      var point = {};
      var angle = data.angle + (i * (1 / data.face) * 2 * Math.PI);
      point.x = Math.round(data.xpos + (data.radius * Math.cos(angle)));
      point.y = Math.round(data.ypos + (data.radius * Math.sin(angle)));
      points.push(point);
    }
    var strp = "M" + points[0].x + " " + points[0].y;
    for (var i = 1; i < data.face; i++) {
      strp += "L" + points[i].x + " " + points[i].y;
    }
    strp += "L" + points[0].x + " " + points[0].y;

    return strp;
  };

  // ----------------------------------------------------------------------------
  /*
   * Function to draw the legend;
   */ 
  const drawLegend = function () {

    //r.legends = r.set();

    var u_param = unparam( s.title );      
    var p_str = get_param_str( u_param ); 
    var zout_url = s.url + p_str;

    // var zoom_out = r.path("M22.646,19.307c0.96-1.583,1.523-3.435,1.524-5.421C24.169,8.093,19.478,3.401,13.688,3.399C7.897,3.401,3.204,8.093,3.204,13.885c0,5.789,4.693,10.481,10.484,10.481c1.987,0,3.839-0.563,5.422-1.523l7.128,7.127l3.535-3.537L22.646,19.307zM13.688,20.369c-3.582-0.008-6.478-2.904-6.484-6.484c0.006-3.582,2.903-6.478,6.484-6.486c3.579,0.008,6.478,2.904,6.484,6.486C20.165,17.465,17.267,20.361,13.688,20.369zM8.854,11.884v4.001l9.665-0.001v-3.999L8.854,11.884z").attr({ 'fill': '#608CBE', 'stroke': '#FFF', 'href': zout_url }).translate( 50, 4 );
    // var zoom_in = r.path( 'M22.646,19.307c0.96-1.583,1.523-3.435,1.524-5.421C24.169,8.093,19.478,3.401,13.688,3.399C7.897,3.401,3.204,8.093,3.204,13.885c0,5.789,4.693,10.481,10.484,10.481c1.987,0,3.839-0.563,5.422-1.523l7.128,7.127l3.535-3.537L22.646,19.307zM13.688,20.369c-3.582-0.008-6.478-2.904-6.484-6.484c0.006-3.582,2.903-6.478,6.484-6.486c3.579,0.008,6.478,2.904,6.484,6.486C20.165,17.465,17.267,20.361,13.688,20.369zM15.687,9.051h-4v2.833H8.854v4.001h2.833v2.833h4v-2.834h2.832v-3.999h-2.833V9.051z' ).attr({ 'fill': '#608CBE', 'stroke': '#FFF' }).translate( s.im_width + s.transX+ 25, 4  );

    // var zin_tip = '<div class="content">There are 3 ways to zoom in on a region of interest<ol><li>Click and drag a curtain across a region on the histogram</li><li>Click and drag the blue slider bars to define a region</li><li>Type in the start and end positions in the boxes on the Filters panel on the right hand side of the page</li></ol></div>';
    // n._buildTip( zoom_in, { 'c': zin_tip, 'title': 'Zoom In'});

    // var zout_tip = '<div class="content">Click to zoom out and view the full length of the gene</div>';
    // n._buildTip( zoom_out,  { 'c': zout_tip, 'title': 'Zoom out'  });

    // path for the graph in complex and subs;
    var sub_len = 10+(s.subEnd - s.subStart ),
      com_len = s.comEnd - s.comStart,
      com = s.comStart + s.transY,
      sub = s.transY + 10,
      fam = s.featureStart, // + s.transY,
      fam_len = s.featureEnd - s.featureStart
	;

    // liners for the graph;
    var p = 'M'.concat( 90, ',', sub , ',h5,v', sub_len , ',h-5,M90,', com, ',h5,v', com_len, ',h-5' );

    const liner = <Path d={[ p ]}  />;

    // text for the graph;
    // var sub1 = r.text( 70, s.subEnd + s.transY - 6, '0');
    // var com1 = r.text( 70, com + 3, '0');
    /*
    s.texts.push( [ s.ts - 14, s.subEnd + s.transY - 6, '0', {}, 0 ] );
    s.texts.push( [s.ts - 14, com + 3, '0', {}, 0 ]);
    s.texts.push( [s.ts - 14, s.upEnd + s.transY + 3, '0', {}, 0 ]);
    s.texts.push( [s.ts - 14, s.gainEnd + s.transY + 3, '0', {}, 0 ]);
    */

    // draw the texts, subs, complex, indels.
     s.texts.push( [ 0, fam  + fam_len / 2 , 'Pfam .',{ 'text-anchor': 'end', 'font-size': '11px' }, 0] );

    // check the restype
    if( s.res_type === 'AA' ){
      var y = 288 + s.transY;
       s.texts.push( [ s.ts, y, 'Amino acid' ,{ 'text-anchor': 'end', 'font-size': '11px' }, 0] );
    }else{
      var y1 = 275 + s.transY , y2 = 288 + s.transY;
       s.texts.push( [ s.ts, y1, 'bp.' ,{ 'text-anchor': 'end', 'font-size': '11px' }, 0] );
       s.texts.push( [ s.ts, y2, 'Amino acid' ,{ 'text-anchor': 'end', 'font-size': '11px' }, 0] );
    }

  };

  // ----------------------------------------------------------------------------
  const renderText = () => {
    //r.texts = r.set();
    let texts = <Set>
      {
	s.texts.map( s_text => {
	  const [ x,y,text, attr, key, tip ] = s_text;
	  var txt = <Text key={key} x={x} y={y} text={`${text}`} attr={ attr } />;
	  if( attr ){
	    //r.texts.push( txt );
	  }

	  // see if this text label has a tip associated with it (jt6)
	  if ( tip ) {
	    _buildTip( txt, tip );
	  }
	  return txt
	})
      }</Set>
    texts = _.assign({}, texts, { translate: { x: s.transX, y: s.transY } })
    return texts;
  };

  // ----------------------------------------------------------------------------
  const _buildTip = ( el, d ) => {
      // create the text for the tootlip;
    return {}
   /*
     $( el.node ).qtip({
       content:{
         text: d
       },
       style:{
         classes: 'ui-tooltip-light ui-tooltip-shadow'
       },
       hide:{
        event: 'unfocus mouseleave'
       }
     });
     */
  };

  // ----------------------------------------------------------------------------
  const drawRegions = () => {

    if ( s.pfam === undefined) return null
    if ( s.pfam.regions.length === 0 ){
      // console.log( "drawRegions: regions are null so skipping" );
    } else {

      // now loop through the data and draw them
      const b_set = s.pfam.regions.map( ( v, i ) => {

	// some Pfam domains are flagged not to be displayed. Check that
	// flag before doing anything else and skip this domain if it's
	// false
	if ( v.display !== null && v.display === false ) {
	  return null;
	}

	  var mstart  = parseInt( v.start, 10 );
	  var mend    = parseInt( v.end, 10 );
	  
	  // if the resttype is cds then multiply by 3;
	  if( 0 && s.res_type==='CDS' ){
	    mstart = ( mstart === 1 ) ? 0 : ( mstart - 1 ) * 3;
	    mend   = mend * 3;
	  } // end of restype===cds 
	  else if ( s.res_type==='AA' ){
	    mstart = mstart - 1;
	  }
	  
	  // draw the graph only if it comes inside the drawn region
	  if (
	    ( mstart < s.start && mend < s.start ) ||
	    ( mstart >= s.end && mend > s.end )
	  ){
	  } else { 
	    // console.log( " Dont draw the region as its not in our histogram" );
	  
	  mstart    = ( mstart > s.start  ) ? mstart : s.start;
	  mend      = ( mend <  s.end ) ? mend : s.end;
	  var w     = ( mend - mstart )* s.px_per_bp;
	  var x1    = ( ( mstart - s.start  ) * s.px_per_bp );

	  // since we need y1 to start just above the bar so reduce the bar ht from total motif height and half it so we get the exact point at which to start;
	  var y1    = s.dom.bar - ( s.dom.featureHt - s.dom.barHt ) / 2 ;
	    // var r1    = r.rect( x1, y1, w, s.dom.featureHt, 4 ).attr({ 'fill': v.colour, 'stroke': v.colour, 'fill-opacity': 1});

	  let txt = null;
	  let dataTip = null

	  if ( v.text !== undefined ) {

	    // var test the size of the font, by default it is 10 px; 
	    var w1 = s.font10.width * v.text.length + 4,
		h1 = s.font10.height;
	    // the width calculation needs to take into account the length of the
	    // string to be rendered, which it didn't... (jt6)

	    var tipContent = {
	      c: con(v),
	      title: `${v.text} (${v.metadata.accession})`
	    };

	    dataTip = con(v, `${v.text} (${v.metadata.accession})`)

	    if ( w > w1 ) {
	      /*
	      s.texts.push( [
		x1 + w/2,                                    // 0: X-coord
		y1 + s.dom.featureHt/2,                      // 1: Y-coord
		v.text,                                      // 2: text
		{'font-size': '10 px', 'fill': '#000'},      // 3: CSS attributes for the label
		1,                                           // 4: translate label after adding it ? 
		null,                                        // 5: who the hell knows what this does...
		tipContent                                   // 6: contents of a tip that should be added to
	      ] );                                           //    the label (jt6)
	      */
	      txt = <Text key={`${mstart}text`} x={x1 + w/2} y={y1 + s.dom.featureHt/2} text={`${v.text}`} attr={{ "font-size": 10, "fill": '#000' }} />;
	    }

	    //_buildTip( r1, tipContent );
	  }
	    const I = i + s.pfam.markups.length + s.pfam.motifs.length
	    const r1 =
	      <Rect ref={myrefs[I]}
		id={`regions_${I}`} key={`${mstart}region`} x={x1} y={y1} r={4}
		width={w} height={s.dom.featureHt}
		attr={{ "fill": v.colour, "stroke": v.colour, "fill-opacity": 1}}
		hover={{in: t_in, out: t_out}} ms={I}
	      />;

	return <Set key={`${mstart}regions-set`}>{r1}{txt}</Set>;

       } // end of mstart < s.start and mend < s.start;

      }).filter( o => o !== null ); // end of map pfam.regions
      return <Set>{b_set}</Set>
    }

  };  // end of drawRegions


  // ----------------------------------------------------------------------------
  const t_in = ( e, x, y ) => {
    const keys = ['x', 'y', 'width', 'height', 'cx', 'cy' /*, 'r' */ ]
    let tgt = getAttributes(e.target, keys, false)
    tgt = _.assign(
      {},
      ..._.entries(tgt)
	    .map(([k,v]) => v !== null && {[k]: +v})
    );
    const rename = (({cx: x, cy: y, ...rest}) => ({x, y, ...rest}))
    tgt = rename(tgt);

    console.log('in', e.target, x,y, tgt) // the DOM target
    console.log('ref', myref) // the tooltip
    const [ who ] = myrefs
      .filter(r => r.current)
      .filter( r => _.isEqual(subset(r.current.props, keys), tgt));
    console.log('ref', who) // the jsx target
    const [ , cls, i ] = who ? who.current.props.id.match(/(regions|motifs|markups)_([0-9]+)/) : [ 'unknown', -1 ];
    if (i>0 || /^[0-9]+$/.test(i)) {
      let add;
      switch(cls) {
	case 'regions':
	  add = s.pfam.markups.length + s.pfam.motifs.length
	  break;
	case 'motifs':
	  add = s.pfam.markups.length
	  break;
	default:
	case 'markups':
	  add = 0
	  break;
      }
      const I = i - add
      const v = s.pfam[cls][I];
      console.log(v)
      let title = []
      if (v.metadata) {
	title = [...title, v.metadata.type ? v.metadata.type : []]
	title = [...title, v.metadata.identifier ? v.metadata.identifier : []]
	title = [...title, v.metadata.accession ? `(${v.metadata.accession})` : []]
      }
      setBubble ( {x, y, text: con( v, title.join(' '), `${cls}_I` )} )
	/* const thisNode = ReactDOM.findDOMNode(bubble)
      myref.current.appendChild( thisNode ); */
    }

    // myref.current.innerHTML = who ? who.current.props.id : 'not found';
  }

  // ----------------------------------------------------------------------------
  const t_out = ( e, x, y ) => {
    setBubble ( {x:0, y:0, text: ''} )
    console.log('out', e, x,y)
  }


  // ----------------------------------------------------------------------------
  const drawMotifs  = () => {
    if (s.pfam === undefined) return null
    if( s.pfam.motifs.length === 0 ){
      console.log( "drawMotifs: motifs are null so skipping" );
      return null
    } else {

      // now loop through the data and draw it;
      const m_set = s.pfam.motifs.map( ( v, i ) => {
	var mstart  = parseInt( v.start, 10 );
	var mend    = parseInt( v.end, 10 );

	console.log( "before *3: the mstart, mend,start, end  are ", mstart +'|'+mend +'|'+ s.start +'|'+ s.end +'|'+ v.start + '|' + v.end+ '|'+ v.metadata.type  ); 
	// if the resttype is cds then multiply by 3;
	if( 0 && s.res_type === 'CDS' ){
	  mstart = ( mstart===1 ) ? 0 : ( mstart - 1 ) * 3;
	  mend   = mend * 3;
	} // end of restype===cds 
	else if ( s.res_type==='AA' ){
	  mstart = mstart - 1;
	}

	 // dont draw the motifs which are not in range;
	 if ( ( mstart < s.start && mend < s.start ) || ( mstart >= s.end && mend > s.end ) ){
	 }else{ 
	 mstart    = ( mstart > s.start  ) ? mstart : s.start;
	 mend      = ( mend <  s.end ) ? mend : s.end;

	// console.log( "iAfter *3: the mstart, mend,start, end  are ", mstart +'|'+mend +'|'+ s.start +'|'+ s.end +'|'+ v.start + '|' + v.end+ '|'+ v.metadata.identifier  ); 
	var x1    = ( ( mstart - s.start  ) * s.px_per_bp );

        // since we need y1 to start just above the bar so reduce
        // the bar ht from total motif height and half it so we get
        // the exact point at which to start;
	var y1    = s.dom.bar - ( s.dom.featureHt - s.dom.barHt ) / 2 ;
	// draw only if display is ne false;
	if( v.display===null ){
	  var w   = ( mend - mstart )* s.px_per_bp;
	  const I = i + s.pfam.markups.length

	  if( v.type==='pfamb'){
	    
	    // get the new y2 value as we need to increment by the size of the featureHt;
	    var y2 = y1;
	    let b_set;

	    // loop thru the colors and draw the pfam-b's
	    b_set = v.colour.map( ( col, j ) => {
	      let r1 = <Rect x={x1} y={y2} r={4} width={w} height={s.dom.featureHt / 3} attr={{ "fill": col, "stroke":col }} />;
	      y2 += s.dom.featureHt/3;
	      return r1;
	    } );  // end of each color;
	    
	    // create the content;
	    //var con = <><div>Coordinates: {v.start} - {v.end}</div><div>Source: {v.metadata.database}</div></>;

	    //_buildTip( b_set[1], { 'c': con, 'title': v.metadata.identifier } );
	    
	    // push to the set;
	    return <Set ref={myrefs[I]}>{b_set}</Set>

	  } else {
	    let r1 =
	      <Rect ref={myrefs[I]}
		id={`motifs_${I}`} key={`${mstart}motif`}
		x={x1} y={y1} width={w} height={s.dom.featureHt}
		attr={{ "fill": v.colour, "stroke":v.colour }}
		hover={{in: t_in, out: t_out}} ms={I}
	      />;
	    let txt = <Text key={`${mstart}text`} x={x1 + w/2} y={y1 + s.dom.featureHt/2} text={`${v.type}`} attr={{ "font-size": 10, "fill": '#000' }} />;

	    // create the content;
	    // var con = <><div>Coordinates: {v.start} - {v.end}</div><div>Source: {v.metadata.database}</div></>;

	    //  _buildTip( r1, { 'c': con, 'title': v.metadata.type } );
	    //return <Set key={`${mstart}set`}>{r1}</Set>;
	    return r1;
	  } // else of pfamb

	  return null;
	} // end of v.display

	} // end of else m.start < s.start and m.end < s.start;
	return null;
      }); // end of each pfam.markups

      return m_set
    }

  };  // end of drawMotifs



  // ----------------------------------------------------------------------------
  const drawMarkups = () => {

    if (s.pfam === undefined) return null
    if( s.pfam.markups.length === 0 ){
      // console.log( "Markups are null so skipping" );
    } else {

      // now loop through the data and draw it;
      const b_set = s.pfam.markups.map( ( v,i ) => {

	var mstart = parseInt( v.start, 10 );
	var mend = parseInt( v.start, 10 );

	// if the resttype is cds then multiply by 3;
	if( 0 && s.res_type === 'CDS' ){
	  mstart = ( mstart === 1 ) ? 0 : ( mstart - 1 ) * 3;
	  mend   = mend * 3;
	} // end of restype===cds 
	else if ( s.res_type==='AA' ) {
	  mstart = mstart - 1;
	}

	if( mstart > s.start && mstart < s.end ) {
	  var x1   = ( ( mstart - s.start - 1 ) * s.px_per_bp ) + s.px_per_bp / 2;
	  var y1   = s.dom.bar;
	  var y2   = ( v.v_align==='top' ) ? ( y1 - s.dom.markupHt ) : ( y1 + s.dom.markupHt );
	  var str  = 'M'.concat( x1, ',', y1, ' ', 'L', x1, ',',  y2 );
	  //var l    = r.path( str).attr({ 'stroke': v.lineColour } ); 
	  var l    = <Path d={[ str ]} attr={{ 'stroke': v.lineColour }} />; 

	  // now push it to the set;
	  // r.is.push( l );

	  //console.log( "drawMarkup the object is ", v );

	  // need to workput the arrow, pointer, line
	  let ang    = ( v.headStyle==='square' ) ? 95 : 0;
	  let  c;
	  if( v.headStyle === 'circle' ) {
	    //c = r.circle( x1, y2, s.dom.markupRad ).attr({ 'fill': v.colour, 'stroke': v.colour }) ;
	    c = <Circle
	      id={`markups_${i}`}
	      x={x1} y={y2} r={s.dom.markupRad}
	      attr={{ 'fill': v.colour, 'stroke': v.colour }}
	      ref={myrefs[i]}
	      hover={{in: t_in, out: t_out}}
	      />;
	    // r.is.push( c );
	  } else {
	    var shp = _shape( {
	      xpos: x1,
	      ypos: y2,
	      face: 4,
	      angle: ang,
	      radius: s.dom.markupRad
	    }); 

	    c = <Path d={[ shp ]}  attr={{ 'fill': v.colour, 'stroke': v.colour}}
		id={`markups_${i}`}
		ref={myrefs[i]}
		hover={{in: t_in, out: t_out}}
	      />
	      // r.path( shp ).attr({ 'fill': v.colour, 'stroke': v.colour});
	      // r.is.push( c );
	  }  // end of shape===circle

	  // generate the content and color so it can use the datastructure to create Tip
	  var content = '<div class="content">position: '+  v.start +'</div><div class="content">database: '+ v.metadata.database + '</div>';
	  _buildTip( c,  { 'c': content, 'title': v.metadata.description  });

	  return (<Set
	    key={`${mstart}markupset`}
	  >
	    {l}
	    {c}
	  </Set>);
	} // end of mstart;

      }); // end of each pfam.markups
      return b_set;

    } // end of markups length 

  };

  // ----------------------------------------------------------------------------
  const drawPfam = () => {

    if( s.pfam !== undefined ){
    //if( ( s.pfam.markups.length > 0 ) || ( s.pfam.regions.length > 0 ) || ( s.pfam.motifs.length > 0 ) )
      // if we choose the midpoint and not half it by its bar size, then we have rect drawing down from the midpoint
      // hence halving the size of the bar;      
      var z = ( s.featureStart + s.featureEnd ) / 2; 
      s.dom.bar = ( z - s.dom.barHt /2 );

      var d = <Rect x={0} y={s.dom.bar} width={s.im_width}  height={s.dom.barHt} attr={{ "fill":s.color.grey, "stroke": s.color.grey}} />;

      const dMa = drawMarkups()
      const dMo = drawMotifs()
      const dR = drawRegions()
      return (
	  <Set>
	    {d}
	    { /* now draw the bar for the */ } { dMa }
	    { /* now draw the */} { dMo }
	    { /* now draw the */ } { dR }
	  </Set>
      );

    } else {
	  // r.is.push( r.text(250, 355, 'No Pfam annotations found').attr({ 'font-size': '14px', 'text-anchor': 'middle' }) );  
	  s.texts.push( [250, 32, 'No Pfam annotations found', { 'font-size': '14px', 'text-anchor': 'middle' }, 1] );  
    }

  };

  // ----------------------------------------------------------------------------
  const getUnits = () => {

    if (s.end === 0 )
      s.end = s.pfam[0].length
    // check whether the start is 1 or not;
    // var mystart = ( s.start <= 1 ) ? 1 : s.start -1 ;
    var mystart = s.start - 1;
    s.start     = mystart;
    s.length    = s.end - s.start + 1;

    // we need to have a length of minimum 20 residues;
    if (s.length <= 3) {
      s.end        = s.start + 2;
      s.length     = 3;
      s.minor_unit = 1;
      s.major_unit = 5;
    }
    else {

      // now calculate the mantiss and log factors to split the tracks;
      var exponent = Math.pow(10, parseInt(Math.log(s.length) / Math.log(10)));
      // // console.log( 'GETUNITS: the exponent value is ', exponent );
      var mantissa = s.length / exponent;

      if (mantissa < 1.2) {
	s.major_unit = exponent / 10;
	s.minor_unit = s.major_unit / 5;
      }
      else 
	if (mantissa < 2.5) {
	  s.major_unit = exponent / 5;
	  s.minor_unit = s.major_unit / 4;
	}
	else 
	  if (mantissa < 5) {
	    s.major_unit = exponent / 2;
	    s.minor_unit = s.major_unit / 5;
	  }
	  else {
	    s.major_unit = exponent;
	    s.minor_unit = s.major_unit / 5;
	  }
    // // console.log( "GETUNITS:major and minor units are ", s.major_unit, s.minor_unit );
    }

    s.minor_unit = ( s.minor_unit < 1 ) ? 1 : s.minor_unit;

    // set the text start( ts ) to reduce 10 px from transx
    s.ts = s.transX - 10;
    //console.log( 'GETUNITS: major unit, minorunit, s.length, px_per_bp is ', s.major_unit, s.minor_unit, s.length, s.px_per_bp , s.start, s.end);
  };

  // ----------------------------------------------------------------------------
  const unparam = (p) => {
    var ret = {},
    seg = p.replace(/^.*\?/,'').split('&'),
    len = seg.length, i = 0, s;
    for (;i<len;i++) {
	if (!seg[i]) { continue; }
	s = seg[i].split('=');
	var a = [] ;
	if( ret[s[0]] ) {
	    a = ret[s[0]] ;
	}
	a.push(s[1]); 
	ret[s[0]] = a ; 
    }
    return ret;
  };

  // ----------------------------------------------------------------------------
  const get_param_str = (p) => {
    var p_str ='?';
    var sep = '' ;
    for(var index in p) {
	if(index  === 'start' || index  === 'end' || index === 'export') {
	    continue ;
	}
	for (var i = 0; i < p[index].length; i++) {
	     p_str += sep + index + '=' + p[index][i] ;
	     sep = '&' ;
	}
    }
    return p_str;
  };

  // ----------------------------------------------------------------------------
  const drawBoxes = () => {
    // create an image_set ( is );
    //r.is = r.set();

    // variable which are local to this function
    // b1 is one part color in bar, b2 is the other color part, c is counter, 
    // t is the sum of the blen ( pix taken to draw the minor unit)

    // var diff = s.start % s.minor_unit, start = ( s.start -diff <= 0 || s.start == 1 ) ? 0 : s.start - diff, end = s.end;
    let start = s.start , end = s.end;
    // now calculate length and px_per_bp;
    s.length = end - start;
    s.px_per_bp = s.im_width / s.length;

    /*
    var minor_space = s.minor_unit * s.px_per_bp,
	b1 = "M0,0", b2 = "M"+ minor_space + ',0', c = 0, tick = '', t = 0,
	line = "M0,0v"+s.im_height;*/
    const diff_to_start = s.minor_unit - ( start % s.minor_unit );
    let diff_space = diff_to_start * s.px_per_bp,
        c = 1,
        line = "M0,0v" + s.im_height + 'M' + diff_space + ',0v' + s.im_height,
        tick = '',
	minor_space = s.minor_unit * s.px_per_bp,
        b1 = "M0,0h" + diff_space + "m" + minor_space + ",0",
	b2 = "M" + diff_space + ',0',
        t = diff_space;
    // now overwrite the start as we use the new start for the image;
    s.start = start;

    // increment teh start with the diff_to_start value;
    start = start + diff_to_start;
    // console.log( "the starts and ends are ", s.start, end, s.px_per_bp  ) ;
    while( start <= end ){
      // console.log( 'the starts and ends are', start, end, s.major_unit, s.minor_unit );
      let len;
      if( start + s.minor_unit > end ){
	len = ( end - start ) * s.px_per_bp;
      }else{
	len = minor_space;  
      }

      // we don't want to draw a horizontal line once we reached the end
      if( !( start === end ) ){
	if( c % 2 === 0 ) {
	  b1 += 'h' + len + 'm'+ len + ',0';
	} else {
	  b2 += 'h' + len + 'm'+ len + ',0';
	}  
      }

      // now if the unit is major unit mark it;
      if (!(start % s.major_unit)) {
	 tick += "M" + t + ',0v5';
	 
	 // create the text and push it to the image_set;
	 // r.is.push( r.text( t, 10, start ) );
	 // r.is.push( r.text( t, s.im_height + 10, start ) );
	 
	 // takes args( x,y,text,attr,force to translate);
	 s.texts.push([t,10,start,{},1]);
	 s.texts.push([t,s.im_height+10, start, {}, 1]);
       }  

      // vertical line for the bar;
      line += 'M' + t +',0v'+ s.im_height;
      t += len;
      c++;
      start += s.minor_unit;    

    }  // end of while s.start <= s.end

    //console.log( 'the ticks is ', tick );
    line += 'M' + t +',0v'+ s.im_height;

    // console.log( 'teh tick is ', b1, b2 );
    //<Path d={["M150 287L150 287"]} animate={Raphael.animation({"path": ["M80 287L220 287"]},500,"<>")} attr={{"stroke":"#fff"}}/>

    // top scale
    const p1 = <Path d={[b1]} attr={{ "stroke": s.color.grey, "stroke-width": 4 }}/>;
    const p2 = <Path d={[ b2 ]} attr={{ "stroke": "#000", "stroke-width": 4 }}/>;
    // top ticks
    const t1 = <Path d={[ tick ]} attr={{ "stroke": '#d00', "stroke-width": 2.0 }} />;
    // vertical lines
    const liner = <Path d={[ line ]} attr={{ stroke: '#ddd', strokeWidth: 0.2}} />;

    const Xlation = { x: 0, y: s.im_height };
    // bottom scale
    const p3 = <Path {...p1.props}  translate={Xlation}>{p1.children}</Path>;
    const p4 = <Path {...p2.props}  translate={Xlation}>{p2.children}</Path>;
    // bottom ticks
    const t2 = <Path {...t1.props}  translate={Xlation}>{t1.children}</Path>;
    console.log('TTTTTTTTTTTTTTT', t2)
    // set for diff color bar;
    var s1 = <Set attr={{ strokeWidth: 4, stroke: s.color.grey }}>{p1}{p3}</Set>;
    var s2 = <Set attr={{ strokeWidth: 4, stroke: '#000' }}>{p2}{p4}</Set>;

    var path_holder, holder, x = 0, n = this;

    var st = ( x, y ) => {

      const pos = { left:20 }; // $( '#'+s.selector ).offset();
      ox = ( x - pos.left ) / s.scale;
      bstart = Math.round( s.start + ( ( ox - s.transX ) / s.px_per_bp ) );
      return <Rect
	       ref={myrectref} x={ox} y={s.transY} width={1}
	       height={s.im_height}
	       attr={{ strokeWidth:0, fill: '#FF0', opacity: 0.5 }}
	     />;
    };

    const move = ( dx, dy ) => {
      const size = ox + dx;

      //set the dx and dy, so we can use it later;
      //this.dx = dx, this.dy = dy;

      if( size < s.width ){
	bstop = Math.round( bstart + ( dx / s.px_per_bp ) );
	myrectref.current.attr({ width: dx });  
      }

    }

    const up = ( a, b ) => {

      const size = bstop - bstart + 1;
      // dont zoom if the selection is less than 5 residues,
      if( size < 3 ){
	myrectref.current.remove();
	alert( 'Histogram: Please choose more than 3 residues' );
	return;
      }

      /* 
	NOTE: bug in unparam(), now use unparam() and get_param_str()

      var url_param = n.unparam( s.title );

      // convert the object into queryparam to fire a call;
      var str = $.param( $.extend( true, url_param, { 'start': this.bstart, 'end': this.bstop, 'export': 'html' } ) );

      // remove the rectangle
      this.rect.remove();

      // set the location to the new zoom position;
      window.location = s.url +'?' + str;
      */

      // call the unparam method to generate the object
      const u_param = unparam( s.title );
      let str     = get_param_str(u_param) ; 
      str += '&export=html&start=' + bstart + '&end=' + bstop;

      // remove the rectangle
      //myrectref.current.rect.remove();

      // navigate to the new zoom position;
      //window.location = s.url + str;

    };

    // ------------------------------------------------------------ 
    // draw a box and provide the mouse move events to get it;
    // first way of zooming.
    const box = <Rect
      x={0} y={s.subStart} width={s.im_width} height={ s.im_height }
      attr={{ "fill": '#FFF', "opacity": 0, "cursor": 'crosshair' }}
      drag={{ move, start: st, end: up }}
    />

    // ------------------------------------------------------------ 
    // second way of zooming.
    // this.horiZoom();
    // ------------------------------------------------------------ 

    const [ pfam ] = s.pfam
    const { metadata : { identifier } } = pfam
    const x1 = s.start  * s.px_per_bp;
    const y1 = s.dom.featureHt - s.dom.barHt

    // push them into the global image_set;
    return (
      <Set>
	{ identifier && <Text x={x1} y={y1} text={identifier} attr={{ "font-size": 10, "fill": '#000', 'text-anchor':'start' }} /> }
        {s1}
        {s2}
        {t1}
        {t2}
        {liner} 
        {box}
      </Set>
    );
    //r.is.push(  s1, s2, t1, t2, liner , box );

  };


  // ----------------------------------------------------------------------------
  const init = () => {

    // get the units;
    getUnits();

    // now draw the boxes;
    setBoxes (drawBoxes());

    /*
    // draw the bases;
    drawBases();

    var url = $( '#'+ s.selector ).attr( 'title' );
    $('#'+s.selector).removeAttr( 'title' );

    */
    // set it in the settings, so we can use it later;
    s.title = 'hello'; // url;
    /*
    drawSubs( s.sub, true );
    drawComplex( s.complex );

    drawIns( s.ins );
    drawDel( s.del );

    drawCNV();

    // draw the expression data 
    drawExpression();

    // draw Methylation
    drawMethylation();
    */

    if ( s.pfam !== undefined ) {
      // dig pfam from its JSON array
      let [ data ] = s.pfam;
      s.pfam = data;
      // now see whether the pfam exists;
      setPfam( drawPfam() );

    }

    /*
    // translate the image to the x and y position and then draw the legend;
    r.is.translate( s.transX, s.transY );

    // now draw the legends;
    */
    drawLegend();

    setTexts( renderText() );


  }; // end  of init;

  useEffect( () => {
    init();
    console.log('REF', myrefs)
  }, [ s.major_unit ] )

  return(
    <>
    <div id="ttip" ref={myref} style={{ position: 'absolute', top: bubble.y - 80, left: bubble.x, width: 250, background: 'white', zIndex: 100 }}>{bubble.text}</div>
    <Paper width={s.im_width + 25} height={s.im_height + 15} viewbox="-50 0 650 110">
      <Set>{boxes}</Set>
      <Set>{pfam}</Set>
      <Set>{texts}</Set>
    </Paper>
    <ReactTooltip id='svgTooltip2'/>
    </>);

}


const PfamMap = () => <div>pfam: <DrawObject /></div>


export default PfamMap;

