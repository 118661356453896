/*
 * MODIFIED
 *    $Date: Fri Jun 26 00:50:06 IDT 2020$
 */

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export const SvgArrow = ({ width, height, transform, style, ...rest }) => {
  const origStyles = {
    enableBackground: "new 0 0 579.083 579.083",
    width,
    height,
    transform
  }
  const { onClick, thisref } = rest;
  const newStyles = _.assign( {}, style, origStyles );

  return (
    <svg onClick={onClick} ref={thisref} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	  width="579.083px" height="579.083px" viewBox="0 0 579.083 579.083" style={newStyles}
	     >
    <g>
	    <path id="svgArrow" d="M492.551,389.644c31.823-21.42,100.979-64.872,83.844-111.997c-19.584-52.632-89.964-94.248-134.028-124.236
		    c-25.092-17.136-110.771-78.336-140.76-41.004c-1.836,1.836-2.448,4.896-1.836,7.344c-12.24,25.092-4.896,64.26-4.896,91.188
		    c0,4.896,0,9.792,0,14.688c-89.963-0.612-187.883-29.988-275.399-4.284c-5.508,1.836-7.956,5.508-8.568,9.18
		    c-0.612,0-1.224,0.612-1.836,0.612c-13.464,7.956-7.956,38.556-7.956,52.02c0,23.257-0.612,47.737,3.672,70.38
		    c1.224,5.509,4.896,8.568,9.18,9.181c2.448,3.06,4.896,4.284,8.568,4.284c92.412-1.225,182.988-12.24,275.399-6.732
		    c0,29.376-1.224,58.752-5.508,88.128c-0.612,3.672,0.611,6.732,1.836,9.18c-6.12,7.345,0.611,22.645,13.464,20.809
		    C372.599,469.203,439.307,425.14,492.551,389.644z"/>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
  </svg>);
}

SvgArrow.defaultProps = {
  width: 579,
  height: 579,
  transform: ''
}

SvgArrow.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  transform: PropTypes.string
}

export const SvgRightArrow = SvgArrow;
export const SvgUpArrow = props => <SvgArrow transform='rotate(-90deg)' {...props} />;
export const SvgDownArrow = props => <SvgArrow transform='rotate(90deg)' {...props} />;

