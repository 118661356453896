import React from "react";
import Sidebar from "react-sidebar";
import PfamMap from './PfamMap';
import {Search} from '../../components';

import {
  HumanBody
} from '../../components'

const mql = window.matchMedia(`(min-width: 1200px)`);

const styles = {
  root: {
    position: "absolute",
    top: 50,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "hidden"
  },
  sidebar: {
    zIndex: 2,
    position: "absolute",
    top: 50,
    bottom: 0,
    transition: "transform .3s ease-out",
    WebkitTransition: "-webkit-transform .3s ease-out",
    willChange: "transform",
    overflowY: "auto",
    width: 700
  },
  content: {
    position: "absolute",
    top: 50,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: "auto",
    WebkitOverflowScrolling: "touch",
    transition: "left .3s ease-out, right .3s ease-out"
  },
  overlay: {
    zIndex: 1,
    position: "fixed",
    top: 50,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    visibility: "hidden",
    transition: "opacity .3s ease-out, visibility .3s ease-out",
    backgroundColor: "rgba(0,0,0,.3)"
  },
  dragHandle: {
    zIndex: 9999,
    position: "relative",
    top: 50,
    bottom: 0,
    background: 'red',
    color: 'aquamarine'
  }
};

class Sidebar_ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarDocked: mql.matches,
      sidebarOpen: false
    };

    this.myref = React.createRef()

  }

  componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  }

  mediaQueryChanged = () => {
    this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });
  }

  render() {
    const { api } = this.props;
    return (
      <>
      <Sidebar
	sidebar={
	  <>
	    <div ref={this.myref} id="human-body-root"></div>
	    <b>Sidebar content</b>
	  </>
	}
        open={this.state.sidebarOpen}
        docked={this.state.sidebarDocked}
        onSetOpen={this.onSetSidebarOpen}
	styles={styles}
	pullRight={true}
	touchHandleWidth={40}
      >
        <b>Main content</b>
	<div style={{ height: 50 }}>&nbsp;</div>
	<Search api={api}/>
	<br />
	<PfamMap />
      </Sidebar>
      <button
	style={{ zIndex: 4, position: 'fixed', right: 0 }}
	onClick={() => this.onSetSidebarOpen(!this.state.sidebarOpen)}>
          &equiv;
        </button>
      <HumanBody onClick={d => console.log('Clicked',d)} root={() => this.myref.current} />
      </>
    );
  }
}

export default Sidebar_;
