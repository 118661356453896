/*
 * MODIFIED
 *    $Date: $
 */

import Cite from './Cite';

export default Cite;


