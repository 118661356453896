/*
 * MODIFIED
 *    $Date: $
 */

import axios from 'axios';

import type { Dispatch, GetState, ThunkAction, ReduxState } from '../types';

export const fetchServerSession = (opts): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  const { api } = getState();
  const API_URL = `${api.apiHost}${api.apiUrl}/session/`;
  dispatch({ type: `SESSION_REQUESTING` });
  try {
    const { data } = await axios.get(API_URL);
    let data_ = data;
    if (data_) {
      dispatch({ type: `SESSION_SUCCESS`, data: data_ });
      return;
    }
  } catch (err) {
    /* istanbul ignore next */
    dispatch({ type: `SESSION_FAILURE`, err: err.message });
  }
}

const shouldFetchServerSession = (state: ReduxState, prefix = ''): boolean => {
  const pattern = new RegExp(`SESSION_(REQUESTING|SUCCESS)`);
  const ind = state.session.readyStatus.findIndex(p => pattern.test(p));
  if (ind >= 0) return false;

  return true;
}

export const fetchSessionIfNeeded = (opts): ThunkAction => (
  dispatch: Dispatch,
  getState: GetState
) => {
  const { api } = getState();
  const API_URL = `${api.apiHost}${api.apiUrl}/session/`;
  let override = false; // true - always get a fresh set of images
  let URI;
  URI = `${opts && opts.URL ? opts.URL : API_URL}`;

  if (override || shouldFetchServerSession(getState()))
    return dispatch(fetchServerSession({ URI, ...opts }));

  return new Promise(res => res(new Error('no session data at this time')));
}
