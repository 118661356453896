/*
 * MODIFIED
 *    $Date: $
 */

import Contact from './Contact';

export default Contact;


