/*
 * MODIFIED
 *    $Date: Thu Jul 15 18:13:01 IDT 2021$
 */

import React, { useEffect, useRef } from 'react';

import _ from 'lodash';
import styled from 'styled-components';
import {
  useTable, useSortBy
} from 'react-table';
import ReactTooltip from "react-tooltip";
import Figue from './figue';
import {useHistory} from 'react-router-dom';
import {useMediaQuery} from '../../hooks';
import './ColorGrid.css';
import {
  BluColorPalette, RedColorPalette
} from '../../utils/colorPalettes';

import {TCGADiseases, alpha } from '../../config/settings';

const minLog = -Math.log10(Number.MIN_VALUE)
const Styles = styled.div`
  padding: 1rem 1rem 0 1rem;
`;

// Create a default prop getter
const defaultPropGetter = () => ({
});

// the following color palettes do not offer protection for
// less-than-minimal value
// [a] original (rainbow)
/*
const ColorPalette = (val, scale) => (
  `hsl(${scale * (( Math.min(scale, val)) / scale) * -1 + scale}, 100%, 67%)`
);
*/


// get the data, estimate the distance between rows, return the rank order from farthest to closest
const ranking = (data) => {

  var labels = [];
  var vectors = [];
  const key = 'qval'; // the key that is used for distance estimates
  const allKeys = _.keys(...data).filter(k=>!/uniprot_id|gene_symbol|order|rank/.test(k));
  data.forEach( d => {
    labels.push( d['gene_symbol'] );
    vectors.push( allKeys.map( k => k in d && key in d[k] ? +d[k][key] : 1) );
  });
  const root = Figue.agglomerate(labels, vectors, Figue.EUCLIDIAN_DISTANCE, Figue.SINGLE_LINKAGE) ;
  const lines = Figue.generateDendogram(root, 2, false, true, false, false) ;
  const order = lines.map( l => l.match(/([A-Z0-9]+)/g)).filter( l => l ).flat();
  console.log(order);
  const reorder = data.map( (d, i) => order.indexOf(d.gene_symbol)); // index of new order
  return reorder;
};


const styles = {
  container: ({
    is2px, is3px, is4px, is7px
  }) => ({
    fontFamily: "Verdana",
    transform: `rotate(90deg) translate(10px, -${
      is7px ? 9 : is4px ? 4 : is3px ? 3 : 2 }px)`
  })
};

// Expose some prop getters for headers, rows and cells, or more if you want!
function Table({
  columns,
  data,
  This,
  extra = { ngenes: 18000 },
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  },
  useSortBy,
  );

  const nrows = rows.length;
  const colorCellsBy = 'qval';
  const is2px = useMediaQuery('(max-width: 575px)');
  const is3px = useMediaQuery('(min-width: 576px)');
  const is4px = useMediaQuery('(min-width: 768px)');
  const is7px = useMediaQuery('(min-width: 992px)');
  //console.log('IS7PX:', is7px);

  // ordering of the fields in the tooltips
  const official_order = [ 'qval', 'zscore', 'pval', 'nobs' ];
  const one = _.assign( {}, ...official_order.map( (k,i) => ({[k]:i})) )
  const official_sort = (a,b) => one[a] < one[b] ? -1 : one[a] > one[b] ? 1 : 0;

  const official = {
    qval: 'FDR q-value',
    zscore: 'z-value',
    pval: 'p-value',
    nobs: '# obs. mutations',
  }


  return (
    <>
      <table className="color-grid bwhatch" align="center" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
		      className: column.className === 'user' ?
		      'user-th' :
		      column.className,
		    style: column.style,
                    },
                    getColumnProps(column),
                    getHeaderProps(column),
		  column.index === 0 && column.Header === 'Gene ID' ? column.getSortByToggleProps() : {
                    }
                  ])}
                  data-for="getContent"
                  data-tip={
		  (column.index === 0 && column.Header === 'Gene ID') ||
		    column.parent === undefined ?
		    null :
		    ( _.isNil(TCGADiseases[column.id]) ?
		      '<span>no<br /> disease<br /> information</span>' :
		      '<span>' + TCGADiseases[column.id].replace(/__NGENE__/, extra.ngenes) + '</span>'
		    ) }
                >
                  <div className={"th-cell" + (/Scores|Name/.test(column.Header) ? '1' : '')}>
		  {column.parent !== undefined && /Scores/.test(column.parent.Header) ?
                      <svg id={"svg" + column.id} height="50px" width="100%">
                        <foreignObject
			  id={"centreMe" + column.id}
			  className={ "G" + (This === column.id ?
			    'selected' :
			    '')
			  }
			  width="100%"
			  height="100%">
                          <svg style={{
                            width: '100%', height: '50px'
                          }}>
                            <rect x="0" y="0" width="100%" height="100%" fill="#deebf7"></rect>
                            <text className="svgtext" fill="black" style={styles.container({
                              is2px, is3px, is4px, is7px
                            } ) /* { transform: 'rotate(90deg) translate(10px, -4px)',  fontFamily: "Verdana" } */ }>{column.render('Header')}</text>
                          </svg>
                        </foreignObject>
                      </svg> : column.render('Header') }
                  </div>
                  {/* Add a sort direction indicator */}
                  {column.isSorted ?
                    <span>
		    {column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼' }
                    </span> : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
	  //console.log('ROW', row.getRowProps(getRowProps(row)))
            return (
            // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map(cell => {
                  /*console.log('CELL', _.assign({}, {...cell.getCellProps([
                      {
                        className: cell.column.className,
                        style: cell.column.style,
                      },
                      getColumnProps(cell.column),
                      getCellProps(cell),
		    ])})) */
                  const cv = cell.value;
                  const val = typeof cv === 'object' && colorCellsBy in cv ? cv[colorCellsBy] : cv;
		  let val2 = typeof cv === 'object' ?
		    _.keys(cv)
		   // .sort( (a,b) => official[a] < official[b] ? -1 : official[a] > official[b] ? 1 : 0 )
		    .sort( official_sort )
		    .map( k => official[k] + ': ' + cv[k] )
		    .join('<br />') :
		    cv;
                  if (/Gene ID|rank/.test(cell.column.Header)) val2 = undefined;
                  return (
                    <td className="content"
                    // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        getCellProps(cell),
		      {
                          style: {
                            width: 18, height: 18
                          }
                        }
                      ])}
		      data-for="getContent"
		      data-tip={
                        _.isNil(val) ?
			  '<span>zero observed<br />mutations<br/>&nbsp;</span>' :
			  val2
		      }>
		      {/#ffffff|white/.test(getCellProps(cell).style.backgroundColor) ?
			  <StyledPlus pm={cv.zscore > 0 ?
			      '+' :
			      '-'}/> :
			  cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <ReactTooltip key={nrows} id="getContent" html={true} multiline={true}
        className="text-wrapper" effect="float" offset={{
          top: 20
        }}
        getContent={(c) =>  {
          let val;
	  //let sgn = -1;
	  //const scale = 100;
          if (typeof c === 'string' && !/zero/.test(c)) {
	  const matches = c.match(colorCellsBy + ' *: *([0-9.-]+)');
	  if (matches && matches.length > 1) val = Math.abs(+matches[1]);
          } else {
	  val = Math.abs(+c);
          }
          if (isNaN(val)) return c;
	  //const minVal = 0.0; // -log(0.1) == 1, -log(0.05) == 1.3
          return (
	  "<span style='color: white" +
	  /* (val < minVal ? 'white' :
		sgn > 0 ? BluColorPalette(val, scale) : RedColorPalette(val, scale)
	    ) + */
	  ";'>" + c + "</span>"
          );
        }}
      />

    </>
  );
}

// draw a '+' or '-' as a background of a div
const StyledPlus = props => 
  <div id="cont" className="tablecell-pmcontainer">
    <div className="tablecell-background" style={props.pm === '-' ? {
      color: '#fdbb84'
    } : {
      color: '#528bbe'
    }}>{props.pm}</div>
  </div>;

function ColorGrid(props) {
  const {
    columns: columns_, data: data_, This, focus, getDOMelem = () => null, extra, units: units_ = 'uniprot_id'
  } = props;
  let history = useHistory();
  const myref = useRef(null);
  const htmlTable = useRef(null);
  const dataLen = data_ ? data_.length : 0;
  console.log('dataLen', dataLen)
  let units = units_
  if (_.isNil(units)) units = 'uniprot_id'

  // componentDidMount
  useEffect(() => { 
    if (!_.isNil(myref.current))
      setTimeout(() => {
	let topPos = 0;
	/*
	   Here we need to ask again if the reference exists. This
           is in the event that a browser's back button was pressed.
           It turns out that sometimes the components are not mounted
           when using this method to navigate back
	*/
	if (!_.isNil(myref.current))
	  topPos = myref.current.offsetTop - 70; // - (d.clientHeight + 70);
	if (topPos < 0) topPos = 0;
	const d = document.getElementById('scrollerColorGrid')
	if (d)
	  d.scrollTo( {top: topPos, behavior: 'smooth'} );
	/*
	myref.current.scrollIntoView({ top: 0,
	  left: 0,
	  behavior:'smooth'}); */
      } , 1000);
  }, []);

  useEffect(() => { 
    if (!_.isNil(htmlTable.current))
      setTimeout(() => {
	getDOMelem(htmlTable.current)
      } , 100);
  }, [htmlTable, dataLen]);

  if (! ( columns_ && data_ )) return null;
  const reorder = ranking(data_);
  const data__ = data_.map( (d, i) => _.assign({
  }, d, {
    order: reorder[i]
  }) );
  console.log('reorder', data__);
  console.log('reorder2', htmlTable);

  // decide if we have a 'rank' column at the end of the data. If
  // so, display it under a separate parent. Otherwise, elegantly make
  // an empty column.
  let lastCol = columns_.slice(-1)
  let allCols = columns_.slice(0,-1);
  if (lastCol[0].Header === 'rank') {
    lastCol[0].className = 'user minimal';
  } else{
    allCols = columns_
    lastCol = null;
  }

  let columns = [
    {
      Header: 'Name',
      columns: [
        {
          Header: 'Gene ID',
          accessor: 'gene_symbol',
          className: 'user',
          style: {
            fontWeight: 'bolder',
          },
	    sortType: (a, b) => {
	      const {original: A} = a;
	      const {original: B} = b;
	      return A.order - B.order;
	    }
        }
      ],
    },
    {
      Header: 'Scores',
      columns: [
	  ...allCols
      ]
    },
    {
     Header: 'R',
      columns : lastCol 
    }
  ];

  return (
    <Styles ref={htmlTable}>
	  { /* <ReactTooltip id='test' place="left" type="success" data-offset="{'top':30}" effect="float" /> */ }

      <Table
        columns={columns}
        data={data__}
        This={This}
	extra={extra}
        getHeaderProps={column => {
	  const onClick =
	    column.parent === undefined ||
	    !/Scores/.test(column.parent.Header) ||
	    column.Header === 'rank' ?
	      () => null :
	      () => history.push('/disease/' + column.id + '/');
	  if (typeof onClick === undefined) alert('undef', column.id);
	  return ({
	    onClick
	  });
        } }
        getColumnProps_={col => {
	  const onClick = () => {
	    const cell = col.render('Cell');
	    const rowid = cell.props.getRowId();
	    console.log('Column!', col, cell, rowid);
	  };
	  return ({
	    onClick
	  });}
        }
        getRowProps={row => ({
          style: {
	    background: 'transparent',
	    color: focus && row.original[units] === focus ? 'red' : 'inherit',
	    /*background: row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
	    color: row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white', */
	  },
	  className: focus && row.original[units] === focus ? 'dashedStyle' : 'normal',
	  id: focus && row.original[units] === focus  ? "inFocus" : null,
	  ref: focus && row.original[units] === focus  ? myref : null
        })}
        getCellProps={cellInfo => {
	  //console.log('CELLINFO', cellInfo)
	  let val = 0;
	  let sgn = 1;
	  const {value} = cellInfo;
	  const isObj = typeof value === 'object';
	  val = Math.abs(isObj && 'qval' in value ? value.qval : value);
	  sgn = parseInt( isObj && 'zscore' in value ? value.zscore / Math.abs(value.zscore) : 1 );
	  //const magnitude = typeof cell;
	  let col;
	  const scale = { neg : 3, pos : 15 };
	  let style = {
          };
	  if (!/gene_symbol|rank/.test(cellInfo.column.id) ) {
	    if (isNaN(val)) col = 'transparent';
	    else {
	      const minVal = alpha; // -log(0.1) == 1, -log(0.05) == 1.3
	      col = Math.abs(val) > minVal ?
                'white' :
		sgn > 0 ?
		  BluColorPalette(val>0?-Math.log10(val).toFixed(0):minLog, scale.neg) :
		  RedColorPalette(val>0?-Math.log10(val).toFixed(0):minLog, scale.pos);
	    }
	    style = {
              backgroundColor: col,
              color: col,
	    };
	  }
	  return ({
	    onMouseEnter: () => {
	      console.log('Cell!', cellInfo);
	      //return <span data-for='test' data-tip={val}>{val}</span>;
	    },
	    onClick: () => {
	      const {column: col} = cellInfo;
	      if (col.Header === 'Gene ID')
                history.push('/gene/' + cellInfo.value);
	      else {
                console.log('Column!', col);
                return null;
	      }
	    },
	    style,
	    id: `${cellInfo.row.id + cellInfo.column.id}`
	  });}
        }
      />
    </Styles>
  );
}

export default ColorGrid;

