//import React from 'react';

  const columns = {
    genes: [
      {
	    Header: 'gene ID',
	    accessor: 'uniprot_id',
	    format: 'String',
	    width: '15%'
      },
      {
	  Header: 'CDS length',
	  accessor: 'cds_len',
	  format: 'Integer',
	  width: '15%'
      },
      {
	  Header: 'name',
	  accessor: 'gene_name',
	  format: 'String',
	  width: '15%'
      },
      {
	  Header: 'Symbol',
	  accessor: 'gene_symbol',
	  format: 'String',
	  width: '15%'
      },
      {
	  Header: 'coordinate',
	  accessor: 'coord',
	  format: 'String',
	  width: '15%'
      },
    ],
    diseases: [
      {
	    Header: 'Symbol',
	    accessor: 'gene_symbol',
	    format: 'String',
	    width: '5%',

      },
      {
	    Header: 'Uniprot',
	    accessor: 'uniprot_id',
	    format: 'String',
	    width: '5%'
      },
      {
	    Header: 'Name',
	    accessor: 'gene_name',
	    format: 'String',
	    width: '15%'
      },
      {
	    Header: 'q-value',
	    accessor: 'overall_fdr_qval',
	    format: 'Number',
	    width: '10%'
      },
      {
	    Header: 'p-value',
	    accessor: 'overall_pval',
	    format: 'Number',
	    width: '10%'
      },
      {
	    Header: 'z-value',
	    accessor: 'overall_z_value',
	    format: 'Number',
	    width: '10%'
      },
      {
	    Header: '#obs',
	    accessor: 'n_obs',
	    format: 'Number',
	    width: '10%'
      },
      /*      {
	    Header: <div>&lang;deviation&rang;</div>, // '<deviation>',
	    accessor: 'overall_mean_deviation',
	    format: 'Number',
	    width: '15%'
      }, */
      {
	  Header: 'mutation density',
	  accessor: 'mutations_per_nt',
	  format: 'Number',
	  width: '15%'
      },
    ],
    diseases2: [
      {
	    Header: 'Gene ID',
	    accessor: 'uniprot_id',
	    format: 'String',
	    width: '5%'
      },
      {
	    Header: 'q-value',
	    accessor: 'overall_fdr_qval',
	    format: 'Number',
	    width: '10%'
      },
      {
	    Header: 'p-value',
	    accessor: 'overall_pval',
	    format: 'Number',
	    width: '10%'
      },
      {
	    Header: 'z-value',
	    accessor: 'overall_z_value',
	    format: 'Number',
	    width: '10%'
      },
      {
	    Header: 'observations',
	    accessor: 'n_obs',
	    format: 'Number',
	    width: '10%'
      },
      /*      {
	    Header: <div>&lang;deviation&rang;</div>, // '<deviation>',
	    accessor: 'overall_mean_deviation',
	    format: 'Number',
	    width: '15%'
      }, */
      {
	  Header: 'mutation freq',
	  accessor: 'mutations_per_nt',
	  format: 'Number',
	  width: '15%'
      },
    ],
    heat: [ ]

  };

export const alpha = 0.05; // FDR passing value

const api = {
    apiHost: 'localhost:8000',
    apiUrl: 'http://localhost:8000/api'
}

//
// Table 2 from the paper
//
const TCGADiseases = {
  BRCA: "Breast Invasive Carcinoma",
  LUAD: "Lung Adenocarcinoma",
  UCEC: "Uterine Corpus Endometrial Carcinoma",
  HNSC: "Head and Neck Squamous Cell Carcinoma",
  LGG: "Brain Lower Grade Glioma",
  PRAD: "Prostate Adenocarcinoma",
  LUSC: "Lung Squamous Cell Carcinoma",
  THCA: "Thyroid Carcinoma",
  SKCM: "Skin Cutaneous Melanoma",
  STAD: "Stomach Adenocarcinoma",
  OV: "Ovarian Serous Cystadenocarcinoma",
  BLCA: "Bladder Urothelial Carcinoma",
  COAD: "Colon Adenocarcinoma",
  GBM: "Glioblastoma Multiforme",
  LIHC: "Liver Hepatocellular Carcinoma",
  KIRC: "Kidney Renal Clear Cell Carcinoma",
  CESC: "Cervical Squamous Cell Carcinoma and Endocervical Adenocarcinoma",
  KIRP: "Kidney Renal Papillary Cell Carcinoma",
  SARC: "Sarcoma",
  ESCA: "Esophageal Carcinoma",
  PCPG: "Pheochromocytoma and Paraganglioma",
  PAAD: "Pancreatic Adenocarcinoma",
  TGCT: "Testicular Germ Cell Tumors",
  LAML: "Acute Myeloid Leukemia",
  READ: "Rectum Adenocarcinoma",
  THYM: "Thymoma",
  ACC: "Adrenocortical Carcinoma",
  MESO: "Mesothelioma",
  UVM: "Uveal Melanoma",
  KICH: "Kidney Chromophobe",
  UCS: "Uterine Carcinosarcoma",
  CHOL: "Cholangiocarcinoma",
  DLBC: "Lymphoid Neoplasm Diffuse Large B-cell Lymphoma",
  PanC: "Pan-cancer",
  rank: "gene ranking {1 ... __NGENE__}"
}

export const fundamentals = {
  'database-ver': 'v3.5',
  'text-updated': 'Jun 29, 2020'
}

export { columns, api, TCGADiseases  };
