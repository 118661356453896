/*
 * MODIFIED
 *    $Date: Thu Aug  6 23:10:37 IDT 2020$
 */

/*
 * from https://gridbyexample.com/examples/example23
 */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import axios from 'axios';
import { alpha, TCGADiseases } from '../../config/settings';
import {
  BluColorPalette, RedColorPalette, MonoColorPalette
} from '../../utils/colorPalettes';

  const keys = [
    /* 'gene_index',
    'uniprot_id', */
    'gene_symbol',
    'gene_name',
    'refseq_id',
    'chr',
    /*
    'fro',
    'to', */
    'cds_len',
  ];

const GridDiv = ({ gene_symbol, api }) => {
  const [colorArr, setColorArr] = useState(
    _.assign({}, ..._.keys(TCGADiseases)
      .filter( k => !/rank/.test(k))
      .sort( (a, b) => {
	    if (/PanC/.test(a)) return -1;
	    if (/PanC/.test(b)) return 1;
	    return (a < b) ? -1 : (a > b) ? 1 : 0;
	  } )
      .map( o => ({ [o]: '#eee'}) )
    )
  );
  const [fetchColors, gotColors] = useState(false);

  const scale = { neg : 3, pos : 15 };
  const minVal = -Math.log10(alpha)
  const mx = Math.log10(Number.MAX_VALUE).toFixed(2);
  let color = null;

  /*
   * following the error about subscriptions:
   *    index.js:1 Warning: Can't perform a React state update on
   *    an unmounted component. This is a no-op, but it indicates
   *    a memory leak in your application. To fix, cancel all
   *    subscriptions and asynchronous tasks in a useEffect cleanup
   *    function.
   *
   *  we moved the fetch command into useEffect(). This way we can
   *  unsubscribe from the async tasks automatically when GridDiv
   *  unmounts. This is documented here:
   *
   *    https://medium.com/@selvaganesh93/how-to-clean-up-subscriptions-in-react-components-using-abortcontroller-72335f19b6f7
   */

  useEffect( () => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    if (!fetchColors) {
      color = axios
	.get(
	  `${api.apiHost}${api.apiUrl}/gene-detail/${gene_symbol}/`,
	  { cancelToken: source.token }
	)
	.then(res => {
	  const { data } = res;
	  if (!_.isArray(data)) return ({ error: 1 })
	  console.log('RES', data)
	  const M = data.map( ({disease, overall_fdr_qval, overall_z_value }) => ({
	    disease, 
	    qval: +overall_fdr_qval === 0.0 ? mx : -Math.log10(overall_fdr_qval).toFixed(2),
	    zscore: overall_z_value
	  })
	  )
	  const S = M.map( ({disease, qval, zscore}) => ({
	    [disease]: zscore > 0 ? 
	      ( qval <= minVal ? '#eee' : BluColorPalette(qval, scale.neg) ) :
	      ( qval <= minVal ? '#eee' : RedColorPalette(qval, scale.pos) )
	  }))
	  return _.assign({}, ...S)
	})

      color.then( res => {
	const newRes = _.assign({},
	  ..._.keys(colorArr)
	  .map( (k,i) => _.assign({}, { [k] : k in res ? res[k] : colorArr[k]}) )
	)
	gotColors(true); // terminate the fetch request
	setColorArr(newRes)
	return(newRes);
      })
    }
    return () => { source.cancel(); };
  }, [])

  return(
      <div className="disease-wrapper grid-body">
	{_.keys(colorArr)
	    .map ( (c,i) => <div
	  key={`box_${gene_symbol}_${i}`}
	  className={`box box${i+1}`}
	  style={{ backgroundColor: colorArr[c] }}
	  data-tip={c}
	  data-for="dynamic"
	>{i+1}</div>
	)}
  </div>
  );
}

export default GridDiv;
