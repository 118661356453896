// wget https://cancer.sanger.ac.uk/cosmic/gene/histo\?all_data\=\&coords\=AA%3AAA\&dr\=\&end\=603\&gd\=\&id\=285572\&ln\=A1CF\&seqlen\=603\&start\=1


/*
const a = {
  "aa_seq": "MEAVCLGTCPEPEASMSTAIPGLKKGNNALQSIILQTLLEKENGQRKYGGPPPGWDAAPPERGCEIFIGKLPRDLFEDELIPLCEKIGKIYEMRMMMDFNGNNRGYAFVTFSNKVEAKNAIKQLNNYEIRNGRLLGVCASVDNCRLFVGGIPKTKKREEILSEMKKVTEGVVDVIVYPSAADKTKNRGFAFVEYESHRAAAMARRKLLPGRIQLWGHGIAVDWAEPEVEVDEDTMSSVKILYVRNLMLSTSEEMIEKEFNNIKPGAVERVKKIRDYAFVHFSNREDAVEAMKALNGKVLDGSPIEVTLAKPVDKDSYVRYTRGTGGRGTMLQGEYTYSLGQVYDPTTTYLGAPVFYAPQTYAAIPSLHFPATKGHLSNRAIIRAPSVREIYMNVPVGAAGVRGLGGRGYLAYTGLGRGYQVKGDKREDKLYDILPGMELTPMNPVTLKPQGIKLAPQILEEICQKNNWGQPVYQLHSAIGQDQRQLFLYKITIPALASQNPAIHPFTPPKLSAFVDEAKTYAAEYTLQTLGIPTDGGDGTMATAAAAATAFPGYAVPNATAPVSAAQLKQAVTLGQDLAAYTTYEVYPTFAVTARGDGYGTF*",
  "gene": "A1CF",
  "expn": {
    "up": {
      "range": [
        [
          1,
          603,
          490,
          "https://cancer.sanger.ac.uk/cosmic/cnv/details?all_data=&chr=&coords=AA%3AAA&dr=&end=603&expn=over&gd=&id=285572&ln=A1CF&seqlen=603&start=1"
        ]
      ]
    },
    "max": 490,
    "down": {
      "range": [
        [
          1,
          603,
          9,
          "https://cancer.sanger.ac.uk/cosmic/cnv/details?all_data=&chr=&coords=AA%3AAA&dr=&end=603&expn=under&gd=&id=285572&ln=A1CF&seqlen=603&start=1"
        ]
      ]
    }
  },
  "seqlen": "603",
  "indels": {
    "total": 3
  },
  "ins": {
    "pos": {
      "274": [
        [
          "p.R274Sfs*10 (c.819dup)",
          "S",
          1,
          "99912388",
          "-"
        ]
      ]
    },
    "max": 1
  },
  "end": 603,
  "start": 1,
  "del": {
    "max": 1,
    "pos": {
      "548": [
        [
          "p.A548del (c.1642_1644del)",
          "",
          1,
          "99912029",
          "+"
        ]
      ],
      "26": [
        [
          "p.G26Efs*68 (c.75del)",
          "E",
          1,
          "99913134",
          "+"
        ]
      ]
    }
  },
  "sub": {
    "max": 13,
    "pos": {
      "509": [
        [
          "p.P509L (c.1526C>T)",
          "L",
          1,
          "99913739",
          ""
        ]
      ],
      "390": [
        [
          "p.I390V (c.1168A>G)",
          "V",
          2,
          "99909193",
          ""
        ]
      ],
      "455": [
        [
          "p.A455T (c.1363G>A)",
          "T",
          1,
          "99919823",
          ""
        ]
      ],
      "594": [
        [
          "p.A594= (c.1782C>T)",
          "A",
          1,
          "99919097",
          ""
        ]
      ],
      "136": [
        [
          "p.G136E (c.407G>A)",
          "E",
          1,
          "99918822",
          ""
        ]
      ],
      "269": [
        [
          "p.R269= (c.807G>A)",
          "R",
          1,
          "99912600",
          ""
        ]
      ],
      "14": [
        [
          "p.A14E (c.41C>A)",
          "E",
          2,
          "99908312",
          ""
        ],
        [
          "p.A14V (c.41C>T)",
          "V",
          1,
          "99911277",
          ""
        ]
      ],
      "163": [
        [
          "p.E163= (c.489G>A)",
          "E",
          1,
          "99912638",
          ""
        ]
      ],
      "268": [
        [
          "p.E268Q (c.802G>C)",
          "Q",
          1,
          "99909143",
          ""
        ]
      ],
      "449": [
        [
          "p.P449= (c.1347C>A)",
          "P",
          1,
          "99919700",
          ""
        ]
      ],
      "289": [
        [
          "p.E289V (c.866A>T)",
          "V",
          1,
          "99908573",
          ""
        ]
      ],
      "94": [
        [
          "p.R94K (c.281G>A)",
          "K",
          1,
          "99918460",
          ""
        ]
      ],
      "365": [
        [
          "p.P365L (c.1094C>T)",
          "L",
          1,
          "99915777",
          ""
        ],
        [
          "p.P365S (c.1093C>T)",
          "S",
          1,
          "99914069",
          ""
        ]
      ],
      "453": [
        [
          "p.K453= (c.1359A>G)",
          "K",
          1,
          "99918646",
          ""
        ]
      ],
      "415": [
        [
          "p.L415= (c.1245G>T)",
          "L",
          1,
          "99918323",
          ""
        ]
      ],
      "557": [
        [
          "p.P557L (c.1670C>T)",
          "L",
          1,
          "99919388",
          ""
        ]
      ],
      "189": [
        [
          "p.F189V (c.565T>G)",
          "V",
          1,
          "99917384",
          ""
        ]
      ],
      "253": [
        [
          "p.E253D (c.759G>C)",
          "D",
          1,
          "99913194",
          ""
        ]
      ],
      "511": [
        [
          "p.L511= (c.1533G>A)",
          "L",
          1,
          "99909439",
          ""
        ]
      ],
      "120": [
        [
          "p.A120G (c.359C>G)",
          "G",
          1,
          "99911063",
          ""
        ]
      ],
      "583": [
        [
          "p.T583= (c.1749C>A)",
          "T",
          1,
          "99910995",
          ""
        ],
        [
          "p.T583N (c.1748C>A)",
          "N",
          1,
          "99919804",
          ""
        ]
      ],
      "427": [
        [
          "p.E427Q (c.1279G>C)",
          "Q",
          1,
          "99914469",
          ""
        ]
      ],
      "244": [
        [
          "p.R244I (c.731G>T)",
          "I",
          1,
          "99912078",
          ""
        ]
      ],
      "4": [
        [
          "p.V4L (c.10G>C)",
          "L",
          1,
          "99914447",
          ""
        ]
      ],
      "226": [
        [
          "p.P226S (c.676C>T)",
          "S",
          1,
          "99908978",
          ""
        ]
      ],
      "496": [
        [
          "p.L496V (c.1486C>G)",
          "V",
          1,
          "99917435",
          ""
        ]
      ],
      "92": [
        [
          "p.E92K (c.274G>A)",
          "K",
          1,
          "99918473",
          ""
        ]
      ],
      "354": [
        [
          "p.V354= (c.1062C>A)",
          "V",
          1,
          "99918158",
          ""
        ]
      ],
      "392": [
        [
          "p.M392I (c.1176G>A)",
          "I",
          5,
          "99910253",
          ""
        ]
      ],
      "255": [
        [
          "p.I255= (c.765T>C)",
          "I",
          1,
          "99910827",
          ""
        ],
        [
          "p.I255V (c.763A>G)",
          "V",
          1,
          "99908700",
          ""
        ]
      ],
      "497": [
        [
          "p.A497S (c.1489G>T)",
          "S",
          3,
          "99908200",
          ""
        ]
      ],
      "59": [
        [
          "p.P59T (c.175C>A)",
          "T",
          1,
          "99917709",
          ""
        ]
      ],
      "201": [
        [
          "p.A201S (c.601G>T)",
          "S",
          1,
          "99916233",
          ""
        ]
      ],
      "39": [
        [
          "p.L39= (c.115C>T)",
          "L",
          1,
          "99908014",
          ""
        ],
        [
          "p.L39Q (c.116T>A)",
          "Q",
          1,
          "99913959",
          ""
        ]
      ],
      "544": [
        [
          "p.A544T (c.1630G>A)",
          "T",
          1,
          "99911044",
          ""
        ]
      ],
      "450": [
        [
          "p.Q450= (c.1350A>G)",
          "Q",
          2,
          "99912425",
          ""
        ]
      ],
      "368": [
        [
          "p.H368Y (c.1102C>T)",
          "Y",
          1,
          "99918709",
          ""
        ]
      ],
      "540": [
        [
          "p.T540I (c.1619C>T)",
          "I",
          1,
          "99909345",
          ""
        ]
      ],
      "133": [
        [
          "p.R133P (c.398G>C)",
          "P",
          1,
          "99918717",
          ""
        ]
      ],
      "524": [
        [
          "p.E524K (c.1570G>A)",
          "K",
          3,
          "99909890",
          ""
        ]
      ],
      "258": [
        [
          "p.E258D (c.774A>T)",
          "D",
          2,
          "99908866",
          ""
        ],
        [
          "p.E258K (c.772G>A)",
          "K",
          2,
          "99911127",
          ""
        ]
      ],
      "127": [
        [
          "p.Y127N (c.379T>A)",
          "N",
          1,
          "99912441",
          ""
        ]
      ],
      "457": [
        [
          "p.Q457K (c.1369C>A)",
          "K",
          1,
          "99914561",
          ""
        ]
      ],
      "371": [
        [
          "p.A371= (c.1113C>A)",
          "A",
          1,
          "99913638",
          ""
        ],
        [
          "p.A371= (c.1113C>G)",
          "A",
          1,
          "99915449",
          ""
        ]
      ],
      "553": [
        [
          "p.G553E (c.1658G>A)",
          "E",
          1,
          "99913479",
          ""
        ]
      ],
      "211": [
        [
          "p.R211T (c.632G>C)",
          "T",
          1,
          "99917075",
          ""
        ]
      ],
      "73": [
        [
          "p.R73* (c.217C>T)",
          "*",
          2,
          "99917046",
          ""
        ],
        [
          "p.R73= (c.219A>T)",
          "R",
          1,
          "99918599",
          ""
        ],
        [
          "p.R73= (c.217C>A)",
          "R",
          1,
          "99918906",
          ""
        ]
      ],
      "98": [
        [
          "p.D98N (c.292G>A)",
          "N",
          1,
          "99916178",
          ""
        ]
      ],
      "401": [
        [
          "p.V401= (c.1203G>T)",
          "V",
          1,
          "99919369",
          ""
        ]
      ],
      "256": [
        [
          "p.E256K (c.766G>A)",
          "K",
          1,
          "99911526",
          ""
        ]
      ],
      "65": [
        [
          "p.E65K (c.193G>A)",
          "K",
          6,
          "99908791",
          ""
        ]
      ],
      "416": [
        [
          "p.G416S (c.1246G>A)",
          "S",
          1,
          "99913418",
          ""
        ]
      ],
      "149": [
        [
          "p.G149W (c.445G>T)",
          "W",
          1,
          "99916922",
          ""
        ]
      ],
      "175": [
        [
          "p.I175T (c.524T>C)",
          "T",
          1,
          "99919056",
          ""
        ],
        [
          "p.I175= (c.525C>T)",
          "I",
          2,
          "99909672",
          ""
        ]
      ],
      "375": [
        [
          "p.H375Y (c.1123C>T)",
          "Y",
          1,
          "99909744",
          ""
        ],
        [
          "p.H375R (c.1124A>G)",
          "R",
          1,
          "99914342",
          ""
        ]
      ],
      "187": [
        [
          "p.R187* (c.559C>T)",
          "*",
          1,
          "99918726",
          ""
        ]
      ],
      "451": [
        [
          "p.G451E (c.1352G>A)",
          "E",
          2,
          "99918434",
          ""
        ]
      ],
      "63": [
        [
          "p.G63= (c.189C>T)",
          "G",
          1,
          "99913703",
          ""
        ]
      ],
      "303": [
        [
          "p.P303= (c.909C>T)",
          "P",
          3,
          "99912254",
          ""
        ],
        [
          "p.P303S (c.907C>T)",
          "S",
          1,
          "99913169",
          ""
        ]
      ],
      "180": [
        [
          "p.A180V (c.539C>T)",
          "V",
          1,
          "99915387",
          ""
        ]
      ],
      "231": [
        [
          "p.D231V (c.692A>T)",
          "V",
          1,
          "99913116",
          ""
        ]
      ],
      "341": [
        [
          "p.Q341H (c.1023A>T)",
          "H",
          1,
          "99911471",
          ""
        ],
        [
          "p.Q341H (c.1023A>C)",
          "H",
          2,
          "99914598",
          ""
        ]
      ],
      "495": [
        [
          "p.A495T (c.1483G>A)",
          "T",
          1,
          "99918974",
          ""
        ]
      ],
      "374": [
        [
          "p.G374R (c.1120G>A)",
          "R",
          3,
          "99909611",
          ""
        ]
      ],
      "432": [
        [
          "p.D432G (c.1295A>G)",
          "G",
          1,
          "99909254",
          ""
        ]
      ],
      "156": [
        [
          "p.K156= (c.468G>A)",
          "K",
          1,
          "99908486",
          ""
        ]
      ],
      "454": [
        [
          "p.L454= (c.1362C>A)",
          "L",
          1,
          "99910979",
          ""
        ],
        [
          "p.L454= (c.1362C>T)",
          "L",
          4,
          "99908749",
          ""
        ]
      ],
      "204": [
        [
          "p.R204= (c.612G>A)",
          "R",
          1,
          "99909471",
          ""
        ]
      ],
      "379": [
        [
          "p.R379K (c.1136G>A)",
          "K",
          1,
          "99916461",
          ""
        ]
      ],
      "322": [
        [
          "p.R322* (c.964C>T)",
          "*",
          1,
          "99915018",
          ""
        ]
      ],
      "436": [
        [
          "p.G436R (c.1306G>A)",
          "R",
          1,
          "99910305",
          ""
        ],
        [
          "p.G436E (c.1307G>A)",
          "E",
          3,
          "99909364",
          ""
        ]
      ],
      "435": [
        [
          "p.P435L (c.1304C>T)",
          "L",
          1,
          "99918446",
          ""
        ]
      ],
      "595": [
        [
          "p.R595Q (c.1784G>A)",
          "Q",
          1,
          "99912194",
          ""
        ]
      ],
      "162": [
        [
          "p.S162* (c.485C>A)",
          "*",
          1,
          "99918516",
          ""
        ]
      ],
      "142": [
        [
          "p.D142V (c.425A>T)",
          "V",
          1,
          "99913396",
          ""
        ]
      ],
      "167": [
        [
          "p.V167I (c.499G>A)",
          "I",
          1,
          "99917816",
          ""
        ]
      ],
      "333": [
        [
          "p.G333R (c.997G>A)",
          "R",
          2,
          "99911224",
          ""
        ]
      ],
      "487": [
        [
          "p.F487L (c.1461C>A)",
          "L",
          1,
          "99909903",
          ""
        ]
      ],
      "2": [
        [
          "p.E2K (c.4G>A)",
          "K",
          1,
          "99918674",
          ""
        ]
      ],
      "538": [
        [
          "p.D538N (c.1612G>A)",
          "N",
          1,
          "99908909",
          ""
        ]
      ],
      "513": [
        [
          "p.A513S (c.1537G>T)",
          "S",
          2,
          "99914059",
          ""
        ]
      ],
      "326": [
        [
          "p.G326R (c.976G>A)",
          "R",
          2,
          "99916626",
          ""
        ]
      ],
      "41": [
        [
          "p.K41E (c.121A>G)",
          "E",
          1,
          "99908507",
          ""
        ]
      ],
      "135": [
        [
          "p.L135* (c.404T>G)",
          "*",
          1,
          "99913779",
          ""
        ]
      ],
      "300": [
        [
          "p.D300Y (c.898G>T)",
          "Y",
          2,
          "99915277",
          ""
        ]
      ],
      "141": [
        [
          "p.V141E (c.422T>A)",
          "E",
          1,
          "99918049",
          ""
        ],
        [
          "p.V141= (c.423G>A)",
          "V",
          2,
          "99915492",
          ""
        ]
      ],
      "164": [
        [
          "p.M164I (c.492G>A)",
          "I",
          1,
          "99909562",
          ""
        ]
      ],
      "480": [
        [
          "p.G480A (c.1439G>C)",
          "A",
          1,
          "99913540",
          ""
        ]
      ],
      "230": [
        [
          "p.V230L (c.688G>C)",
          "L",
          1,
          "99915174",
          ""
        ]
      ],
      "178": [
        [
          "p.P178L (c.533C>T)",
          "L",
          1,
          "99916385",
          ""
        ],
        [
          "p.P178K (c.532_533delinsAA)",
          "K",
          1,
          "99918223",
          ""
        ],
        [
          "p.P178L (c.532_533delinsTT)",
          "L",
          1,
          "99914012",
          ""
        ]
      ],
      "408": [
        [
          "p.G408= (c.1224C>G)",
          "G",
          1,
          "99909695",
          ""
        ]
      ],
      "369": [
        [
          "p.F369= (c.1107C>T)",
          "F",
          1,
          "99911031",
          ""
        ]
      ],
      "151": [
        [
          "p.I151= (c.453C>T)",
          "I",
          1,
          "99915467",
          ""
        ]
      ],
      "43": [
        [
          "p.N43K (c.129T>G)",
          "K",
          1,
          "99913278",
          ""
        ]
      ],
      "152": [
        [
          "p.P152T (c.454C>A)",
          "T",
          2,
          "99913216",
          ""
        ]
      ],
      "174": [
        [
          "p.V174= (c.522C>A)",
          "V",
          1,
          "99915862",
          ""
        ]
      ],
      "93": [
        [
          "p.M93K (c.278T>A)",
          "K",
          1,
          "99919716",
          ""
        ],
        [
          "p.M93I (c.279G>A)",
          "I",
          1,
          "99911832",
          ""
        ]
      ],
      "215": [
        [
          "p.W215* (c.645G>A)",
          "*",
          1,
          "99914514",
          ""
        ]
      ],
      "257": [
        [
          "p.K257R (c.770A>G)",
          "R",
          2,
          "99908108",
          ""
        ]
      ],
      "74": [
        [
          "p.D74= (c.222C>T)",
          "D",
          1,
          "99912699",
          ""
        ]
      ],
      "290": [
        [
          "p.A290V (c.869C>T)",
          "V",
          1,
          "99917414",
          ""
        ]
      ],
      "397": [
        [
          "p.G397E (c.1190G>A)",
          "E",
          1,
          "99918865",
          ""
        ]
      ],
      "442": [
        [
          "p.M442V (c.1324A>G)",
          "V",
          1,
          "99908636",
          ""
        ]
      ],
      "42": [
        [
          "p.E42K (c.124G>A)",
          "K",
          12,
          "99908605",
          ""
        ],
        [
          "p.E42* (c.124G>T)",
          "*",
          1,
          "99918763",
          ""
        ]
      ],
      "426": [
        [
          "p.R426T (c.1277G>C)",
          "T",
          1,
          "99910644",
          ""
        ]
      ],
      "13": [
        [
          "p.E13= (c.39A>G)",
          "E",
          1,
          "99909102",
          ""
        ]
      ],
      "522": [
        [
          "p.A522T (c.1564G>A)",
          "T",
          1,
          "99912524",
          ""
        ]
      ],
      "273": [
        [
          "p.I273= (c.819T>C)",
          "I",
          1,
          "99914835",
          ""
        ]
      ],
      "418": [
        [
          "p.G418A (c.1253G>C)",
          "A",
          1,
          "99916437",
          ""
        ],
        [
          "p.G418E (c.1253G>A)",
          "E",
          2,
          "99915546",
          ""
        ]
      ],
      "520": [
        [
          "p.T520= (c.1560G>A)",
          "T",
          2,
          "99911632",
          ""
        ]
      ],
      "145": [
        [
          "p.R145= (c.433C>A)",
          "R",
          1,
          "99919322",
          ""
        ],
        [
          "p.R145* (c.433C>T)",
          "*",
          1,
          "99910841",
          ""
        ]
      ],
      "293": [
        [
          "p.A293T (c.877G>A)",
          "T",
          1,
          "99913684",
          ""
        ]
      ],
      "216": [
        [
          "p.G216* (c.646G>T)",
          "*",
          1,
          "99912775",
          ""
        ]
      ],
      "295": [
        [
          "p.N295S (c.884A>G)",
          "S",
          1,
          "99915829",
          ""
        ]
      ],
      "21": [
        [
          "p.P21S (c.61C>T)",
          "S",
          1,
          "99912486",
          ""
        ]
      ],
      "598": [
        [
          "p.G598E (c.1793G>A)",
          "E",
          1,
          "99910905",
          ""
        ],
        [
          "p.G598R (c.1792G>A)",
          "R",
          1,
          "99909111",
          ""
        ]
      ],
      "596": [
        [
          "p.G596R (c.1786G>A)",
          "R",
          1,
          "99914128",
          ""
        ]
      ],
      "134": [
        [
          "p.L134I (c.400C>A)",
          "I",
          1,
          "99914697",
          ""
        ],
        [
          "p.L134F (c.400C>T)",
          "F",
          1,
          "99912660",
          ""
        ]
      ],
      "198": [
        [
          "p.R198* (c.592C>T)",
          "*",
          1,
          "99918035",
          ""
        ]
      ],
      "76": [
        [
          "p.F76V (c.226T>G)",
          "V",
          1,
          "99915584",
          ""
        ]
      ],
      "128": [
        [
          "p.E128K (c.382G>A)",
          "K",
          5,
          "99911399",
          ""
        ]
      ],
      "314": [
        [
          "p.K314= (c.942G>A)",
          "K",
          1,
          "99916064",
          ""
        ]
      ],
      "411": [
        [
          "p.A411T (c.1231G>A)",
          "T",
          1,
          "99908371",
          ""
        ],
        [
          "p.A411V (c.1232C>T)",
          "V",
          2,
          "99911716",
          ""
        ]
      ],
      "470": [
        [
          "p.Q470R (c.1409A>G)",
          "R",
          2,
          "99911702",
          ""
        ]
      ],
      "548": [
        [
          "p.A548= (c.1644T>A)",
          "A",
          1,
          "99909327",
          ""
        ]
      ],
      "160": [
        [
          "p.I160= (c.480C>A)",
          "I",
          2,
          "99908710",
          ""
        ],
        [
          "p.I160= (c.480C>T)",
          "I",
          1,
          "99917787",
          ""
        ]
      ],
      "440": [
        [
          "p.T440N (c.1319C>A)",
          "N",
          1,
          "99912006",
          ""
        ]
      ],
      "86": [
        [
          "p.K86N (c.258A>C)",
          "N",
          1,
          "99918490",
          ""
        ]
      ],
      "116": [
        [
          "p.E116K (c.346G>A)",
          "K",
          1,
          "99918341",
          ""
        ]
      ],
      "334": [
        [
          "p.E334K (c.1000G>A)",
          "K",
          1,
          "99916024",
          ""
        ],
        [
          "p.E334* (c.1000G>T)",
          "*",
          1,
          "99911457",
          ""
        ]
      ],
      "399": [
        [
          "p.A399= (c.1197G>C)",
          "A",
          1,
          "99908158",
          ""
        ],
        [
          "p.A399V (c.1196C>T)",
          "V",
          2,
          "99911820",
          ""
        ]
      ],
      "277": [
        [
          "p.A277V (c.830C>T)",
          "V",
          1,
          "99915844",
          ""
        ],
        [
          "p.A277S (c.829G>T)",
          "S",
          1,
          "99918622",
          ""
        ]
      ],
      "208": [
        [
          "p.L208= (c.624A>G)",
          "L",
          1,
          "99908293",
          ""
        ]
      ],
      "488": [
        [
          "p.L488= (c.1462T>C)",
          "L",
          1,
          "99911248",
          ""
        ],
        [
          "p.L488M (c.1462T>A)",
          "M",
          1,
          "99917315",
          ""
        ]
      ],
      "172": [
        [
          "p.V172= (c.516C>T)",
          "V",
          3,
          "99913946",
          ""
        ],
        [
          "p.V172I (c.514G>A)",
          "I",
          1,
          "99916605",
          ""
        ],
        [
          "p.V172A (c.515T>C)",
          "A",
          1,
          "99912147",
          ""
        ]
      ],
      "562": [
        [
          "p.P562= (c.1686C>A)",
          "P",
          1,
          "99909581",
          ""
        ],
        [
          "p.P562L (c.1685C>T)",
          "L",
          1,
          "99919455",
          ""
        ]
      ],
      "72": [
        [
          "p.P72S (c.214C>T)",
          "S",
          1,
          "99913497",
          ""
        ]
      ],
      "158": [
        [
          "p.E158D (c.474A>C)",
          "D",
          1,
          "99909505",
          ""
        ]
      ],
      "262": [
        [
          "p.I262F (c.784A>T)",
          "F",
          1,
          "99911443",
          ""
        ]
      ],
      "79": [
        [
          "p.E79K (c.235G>A)",
          "K",
          1,
          "99912679",
          ""
        ]
      ],
      "241": [
        [
          "p.L241I (c.721C>A)",
          "I",
          1,
          "99909921",
          ""
        ]
      ],
      "296": [
        [
          "p.G296A (c.887G>C)",
          "A",
          1,
          "99912823",
          ""
        ]
      ],
      "89": [
        [
          "p.K89* (c.265A>T)",
          "*",
          1,
          "99916133",
          ""
        ]
      ],
      "118": [
        [
          "p.K118N (c.354G>T)",
          "N",
          1,
          "99908726",
          ""
        ]
      ],
      "566": [
        [
          "p.A566D (c.1697C>A)",
          "D",
          1,
          "99919228",
          ""
        ]
      ],
      "309": [
        [
          "p.A309= (c.927A>T)",
          "A",
          1,
          "99915658",
          ""
        ]
      ],
      "288": [
        [
          "p.V288= (c.864T>A)",
          "V",
          1,
          "99912847",
          ""
        ]
      ],
      "117": [
        [
          "p.A117= (c.351C>T)",
          "A",
          1,
          "99911151",
          ""
        ]
      ],
      "537": [
        [
          "p.G537= (c.1611C>T)",
          "G",
          1,
          "99912765",
          ""
        ]
      ],
      "66": [
        [
          "p.I66V (c.196A>G)",
          "V",
          1,
          "99909656",
          ""
        ]
      ],
      "283": [
        [
          "p.N283= (c.849C>T)",
          "N",
          1,
          "99919653",
          ""
        ]
      ],
      "154": [
        [
          "p.T154I (c.461C>T)",
          "I",
          2,
          "99912308",
          ""
        ]
      ],
      "274": [
        [
          "p.R274* (c.820C>T)",
          "*",
          2,
          "99908464",
          ""
        ]
      ],
      "280": [
        [
          "p.H280Y (c.838C>T)",
          "Y",
          1,
          "99915034",
          ""
        ]
      ],
      "417": [
        [
          "p.R417= (c.1251A>G)",
          "R",
          1,
          "99913326",
          ""
        ],
        [
          "p.R417Q (c.1250G>A)",
          "Q",
          3,
          "99909783",
          ""
        ]
      ],
      "383": [
        [
          "p.R383Q (c.1148G>A)",
          "Q",
          1,
          "99917987",
          ""
        ]
      ],
      "330": [
        [
          "p.M330L (c.988A>T)",
          "L",
          1,
          "99908087",
          ""
        ]
      ],
      "464": [
        [
          "p.Q464L (c.1391A>T)",
          "L",
          1,
          "99918698",
          ""
        ]
      ],
      "232": [
        [
          "p.E232D (c.696A>T)",
          "D",
          1,
          "99919596",
          ""
        ]
      ],
      "395": [
        [
          "p.P395= (c.1185T>A)",
          "P",
          1,
          "99911091",
          ""
        ]
      ],
      "485": [
        [
          "p.Q485R (c.1454A>G)",
          "R",
          1,
          "99915207",
          ""
        ]
      ],
      "157": [
        [
          "p.R157= (c.471A>G)",
          "R",
          1,
          "99917867",
          ""
        ]
      ],
      "385": [
        [
          "p.P385S (c.1153C>T)",
          "S",
          1,
          "99910285",
          ""
        ]
      ],
      "238": [
        [
          "p.V238= (c.714G>A)",
          "V",
          1,
          "99912616",
          ""
        ],
        [
          "p.V238L (c.712G>T)",
          "L",
          1,
          "99914745",
          ""
        ]
      ],
      "423": [
        [
          "p.G423E (c.1268G>A)",
          "E",
          5,
          "99912493",
          ""
        ]
      ],
      "271": [
        [
          "p.K271= (c.813G>A)",
          "K",
          1,
          "99909811",
          ""
        ]
      ],
      "315": [
        [
          "p.D315G (c.944A>G)",
          "G",
          1,
          "99919172",
          ""
        ]
      ],
      "166": [
        [
          "p.K166N (c.498G>C)",
          "N",
          1,
          "99917573",
          ""
        ]
      ],
      "555": [
        [
          "p.A555V (c.1664C>T)",
          "V",
          1,
          "99915005",
          ""
        ],
        [
          "p.A555D (c.1664C>A)",
          "D",
          2,
          "99911802",
          ""
        ]
      ],
      "210": [
        [
          "p.G210R (c.628G>C)",
          "R",
          2,
          "99915507",
          ""
        ]
      ],
      "444": [
        [
          "p.P444S (c.1330C>T)",
          "S",
          2,
          "99912181",
          ""
        ]
      ],
      "203": [
        [
          "p.A203V (c.608C>T)",
          "V",
          3,
          "99909310",
          ""
        ],
        [
          "p.A203T (c.607G>A)",
          "T",
          2,
          "99908217",
          ""
        ]
      ],
      "49": [
        [
          "p.G49D (c.146G>A)",
          "D",
          1,
          "99917636",
          ""
        ]
      ],
      "26": [
        [
          "p.G26E (c.77G>A)",
          "E",
          3,
          "99908823",
          ""
        ]
      ],
      "344": [
        [
          "p.D344N (c.1030G>A)",
          "N",
          4,
          "99912571",
          ""
        ]
      ],
      "192": [
        [
          "p.V192L (c.574G>T)",
          "L",
          1,
          "99908256",
          ""
        ],
        [
          "p.V192M (c.574G>A)",
          "M",
          1,
          "99914455",
          ""
        ]
      ],
      "571": [
        [
          "p.A571= (c.1713G>T)",
          "A",
          2,
          "99910921",
          ""
        ]
      ],
      "213": [
        [
          "p.Q213* (c.637C>T)",
          "*",
          1,
          "99916988",
          ""
        ]
      ],
      "57": [
        [
          "p.A57V (c.170C>T)",
          "V",
          1,
          "99915226",
          ""
        ]
      ],
      "324": [
        [
          "p.T324I (c.971C>T)",
          "I",
          1,
          "99912804",
          ""
        ]
      ],
      "9": [
        [
          "p.C9Y (c.26G>A)",
          "Y",
          1,
          "99910315",
          ""
        ],
        [
          "p.C9* (c.27C>A)",
          "*",
          1,
          "99908521",
          ""
        ]
      ],
      "338": [
        [
          "p.S338F (c.1013C>T)",
          "F",
          1,
          "99911514",
          ""
        ]
      ],
      "542": [
        [
          "p.A542T (c.1624G>A)",
          "T",
          1,
          "99918942",
          ""
        ]
      ],
      "54": [
        [
          "p.G54S (c.160G>A)",
          "S",
          1,
          "99911692",
          ""
        ]
      ],
      "140": [
        [
          "p.S140N (c.419G>A)",
          "N",
          1,
          "99911561",
          ""
        ]
      ],
      "298": [
        [
          "p.V298= (c.894G>T)",
          "V",
          1,
          "99911540",
          ""
        ]
      ],
      "388": [
        [
          "p.R388I (c.1163G>T)",
          "I",
          1,
          "99908351",
          ""
        ]
      ],
      "573": [
        [
          "p.T573N (c.1718C>A)",
          "N",
          1,
          "99919128",
          ""
        ]
      ],
      "353": [
        [
          "p.P353S (c.1057C>T)",
          "S",
          1,
          "99908058",
          ""
        ]
      ],
      "195": [
        [
          "p.E195K (c.583G>A)",
          "K",
          1,
          "99916253",
          ""
        ]
      ],
      "357": [
        [
          "p.A357D (c.1070C>A)",
          "D",
          1,
          "99919678",
          ""
        ],
        [
          "p.A357V (c.1070C>T)",
          "V",
          1,
          "99912105",
          ""
        ]
      ],
      "441": [
        [
          "p.P441A (c.1321C>G)",
          "A",
          1,
          "99909291",
          ""
        ]
      ]
    },
    "total": 232
  },
  "mut_url": "https://cancer.sanger.ac.uk/cosmic/mutation/overview",
  "cds_seq": "ATGGAAGCAGTGTGTCTGGGCACATGCCCAGAGCCAGAAGCGAGCATGAGCACAGCAATTCCTGGCCTCAAAAAAGGAAATAATGCTTTGCAGTCCATCATTTTGCAAACTTTGCTGGAAAAGGAAAATGGACAAAGAAAATATGGTGGCCCTCCACCTGGTTGGGATGCTGCACCCCCTGAAAGGGGCTGTGAAATTTTTATTGGAAAACTTCCCCGAGACCTTTTTGAGGATGAGCTTATACCATTATGTGAAAAAATCGGTAAAATTTATGAAATGAGAATGATGATGGATTTTAATGGCAACAATAGAGGATATGCATTTGTAACATTTTCAAATAAAGTGGAAGCCAAGAATGCAATCAAGCAACTTAATAATTATGAAATTAGAAATGGGCGCCTCTTAGGGGTTTGTGCCAGTGTGGACAACTGCCGATTATTTGTTGGGGGCATCCCAAAAACCAAAAAGAGAGAAGAAATCTTATCGGAGATGAAAAAGGTTACTGAAGGTGTTGTCGATGTCATCGTCTACCCAAGCGCTGCAGATAAAACCAAAAACCGAGGCTTTGCCTTCGTGGAGTATGAGAGTCATCGAGCAGCTGCCATGGCGAGGAGGAAACTGCTACCAGGAAGAATTCAGTTATGGGGACATGGTATTGCAGTAGACTGGGCAGAGCCAGAAGTAGAAGTTGATGAAGATACAATGTCTTCAGTGAAAATCCTATATGTAAGAAATCTTATGCTGTCTACCTCTGAAGAGATGATTGAAAAGGAATTCAACAATATCAAACCAGGTGCTGTGGAGAGGGTGAAGAAAATTCGAGACTATGCTTTTGTGCACTTCAGTAACCGAGAAGATGCAGTTGAGGCTATGAAAGCTTTAAATGGCAAGGTGCTGGATGGTTCCCCCATTGAAGTCACCCTAGCAAAACCAGTGGACAAGGACAGTTATGTTAGGTATACCCGAGGCACAGGTGGAAGGGGCACCATGCTGCAAGGAGAGTATACCTACTCTTTGGGCCAAGTTTATGATCCCACCACAACCTACCTTGGAGCTCCTGTCTTCTATGCCCCCCAGACCTATGCAGCAATTCCCAGTCTTCATTTCCCAGCCACCAAAGGACATCTCAGCAACAGAGCCATTATCCGAGCCCCTTCTGTTAGAGAAATTTACATGAATGTACCTGTAGGGGCTGCGGGAGTGAGAGGACTGGGCGGCCGTGGCTATTTGGCATACACAGGCCTGGGTCGAGGATACCAGGTCAAAGGAGACAAAAGAGAAGACAAACTCTATGACATTTTACCTGGGATGGAGCTCACCCCAATGAATCCTGTCACATTAAAACCCCAAGGAATTAAACTCGCTCCCCAGATATTAGAAGAGATTTGTCAGAAAAATAACTGGGGACAGCCAGTGTACCAGCTGCACTCTGCTATTGGACAAGACCAAAGACAGCTATTCTTGTACAAAATAACTATTCCTGCTCTAGCCAGCCAGAATCCTGCAATCCACCCTTTCACACCTCCAAAGCTGAGTGCCTTTGTGGATGAAGCAAAGACGTATGCAGCCGAATACACCCTGCAGACCCTGGGCATCCCCACTGATGGAGGCGATGGCACCATGGCTACTGCTGCTGCTGCTGCTACTGCTTTCCCAGGATATGCTGTCCCTAATGCAACTGCACCCGTGTCTGCAGCCCAGCTCAAGCAAGCGGTAACCCTTGGACAAGACTTAGCAGCATATACAACCTATGAGGTCTACCCAACTTTTGCAGTGACTGCCCGAGGGGATGGATATGGCACCTTCTGA",
  "res_type": "AA",
  "url": "https://cancer.sanger.ac.uk/cosmic/gene/analysis",
  "export": "json",
  "meth": {
    "hypo": {
      "range": [
        [
          2933,
          2933,
          "3",
          "https://cancer.sanger.ac.uk/cosmic/meth/details?all_data=&chr=10&coords=AA%3AAA&dr=&end=50806560&gd=&id=&ln=A1CF&meth=L&seqlen=603&start=50806560"
        ]
      ]
    },
    "max": 3,
    "hyper": {
      "range": [
        [
          2933,
          2933,
          null,
          "https://cancer.sanger.ac.uk/cosmic/meth/details?all_data=&chr=10&coords=AA%3AAA&dr=&end=50806560&gd=&id=&ln=A1CF&meth=H&seqlen=603&start=50806560"
        ]
      ]
    }
  },
  "": {
    "pos": {
      "603": [
        [
          "p.*603= (c.1808G>A)",
          "*",
          1,
          "99912207",
          ""
        ],
        [
          "p.*603Wext*21 (c.1809A>G)",
          "W",
          1,
          "99913383",
          ""
        ]
      ]
    }
  },
  "pfam": [
    {
      "length": 594,
      "metadata": {
        "database": "uniprot",
        "organism": "Homo sapiens (Human)",
        "description": "APOBEC1 complementation factor",
        "accession": "Q9NQ94",
        "taxid": "9606",
        "identifier": "A1CF_HUMAN"
      },
      "markups": [],
      "regions": [
        {
          "display": null,
          "endStyle": "curved",
          "text": "RRM_1",
          "type": "pfama",
          "metadata": {
            "description": "RNA recognition motif. (a.k.a. RRM, RBD, or RNP domain)",
            "type": "Domain",
            "aliStart": 58,
            "accession": "PF00076",
            "identifier": "RRM_1",
            "end": 127,
            "start": 58,
            "score": "1.5e-13",
            "database": "pfam",
            "aliEnd": 124,
            "scoreName": "e-value"
          },
          "colour": "#2dcf00",
          "modelStart": 1,
          "modelLength": 70,
          "startStyle": "curved",
          "aliEnd": 124,
          "href": "/family/PF00076",
          "modelEnd": 67,
          "aliStart": 58,
          "start": 58,
          "end": 127
        },
        {
          "text": "RRM_1",
          "display": null,
          "endStyle": "jagged",
          "colour": "#2dcf00",
          "type": "pfama",
          "metadata": {
            "scoreName": "e-value",
            "aliEnd": 199,
            "score": "0.00051",
            "database": "pfam",
            "identifier": "RRM_1",
            "end": 203,
            "start": 138,
            "aliStart": 138,
            "accession": "PF00076",
            "description": "RNA recognition motif. (a.k.a. RRM, RBD, or RNP domain)",
            "type": "Domain"
          },
          "href": "/family/PF00076",
          "aliEnd": 199,
          "startStyle": "curved",
          "modelStart": 1,
          "modelLength": 70,
          "end": 203,
          "start": 138,
          "modelEnd": 59,
          "aliStart": 138
        },
        {
          "startStyle": "curved",
          "modelStart": 1,
          "modelLength": 70,
          "href": "/family/PF00076",
          "aliEnd": 296,
          "modelEnd": 69,
          "aliStart": 233,
          "end": 297,
          "start": 233,
          "text": "RRM_1",
          "display": null,
          "endStyle": "curved",
          "type": "pfama",
          "metadata": {
            "description": "RNA recognition motif. (a.k.a. RRM, RBD, or RNP domain)",
            "type": "Domain",
            "database": "pfam",
            "score": "1.4e-07",
            "aliEnd": 296,
            "scoreName": "e-value",
            "aliStart": 233,
            "accession": "PF00076",
            "end": 297,
            "identifier": "RRM_1",
            "start": 233
          },
          "colour": "#2dcf00"
        },
        {
          "modelLength": 80,
          "modelStart": 3,
          "startStyle": "curved",
          "aliEnd": 523,
          "href": "/family/PF14709",
          "aliStart": 447,
          "modelEnd": 80,
          "start": 445,
          "end": 523,
          "display": null,
          "endStyle": "curved",
          "text": "DND1_DSRM",
          "type": "pfama",
          "metadata": {
            "aliEnd": 523,
            "scoreName": "e-value",
            "database": "pfam",
            "score": "2.2e-21",
            "start": 445,
            "end": 523,
            "identifier": "DND1_DSRM",
            "accession": "PF14709",
            "aliStart": 447,
            "type": "Domain",
            "description": "double strand RNA binding domain from DEAD END PROTEIN 1"
          },
          "colour": "#ff5353"
        }
      ],
      "motifs": [
        {
          "start": 1,
          "end": 12,
          "colour": "#cccccc",
          "type": "disorder",
          "metadata": {
            "type": "disorder",
            "end": 12,
            "start": 1,
            "database": "IUPred"
          },
          "display": null
        },
        {
          "display": null,
          "type": "disorder",
          "metadata": {
            "database": "IUPred",
            "start": 16,
            "end": 17,
            "type": "disorder"
          },
          "end": 17,
          "colour": "#cccccc",
          "start": 16
        },
        {
          "display": null,
          "metadata": {
            "end": 37,
            "start": 34,
            "type": "disorder",
            "database": "IUPred"
          },
          "type": "disorder",
          "colour": "#cccccc",
          "end": 37,
          "start": 34
        },
        {
          "metadata": {
            "database": "IUPred",
            "start": 40,
            "end": 47,
            "type": "disorder"
          },
          "type": "disorder",
          "end": 47,
          "colour": "#cccccc",
          "start": 46,
          "display": null
        },
        {
          "metadata": {
            "type": "low_complexity",
            "start": 40,
            "end": 45,
            "database": "segmasker"
          },
          "type": "low_complexity",
          "start": 40,
          "colour": "#86bcff",
          "end": 45,
          "display": null
        },
        {
          "type": "low_complexity",
          "metadata": {
            "start": 310,
            "end": 320,
            "type": "low_complexity",
            "database": "segmasker"
          },
          "colour": "#86bcff",
          "end": 320,
          "start": 310,
          "display": null
        },
        {
          "display": null,
          "end": 410,
          "colour": "#86bcff",
          "start": 385,
          "metadata": {
            "database": "segmasker",
            "start": 385,
            "end": 410,
            "type": "low_complexity"
          },
          "type": "low_complexity"
        },
        {
          "display": null,
          "start": 533,
          "colour": "#86bcff",
          "end": 541,
          "metadata": {
            "database": "segmasker",
            "type": "low_complexity",
            "start": 533,
            "end": 541
          },
          "type": "low_complexity"
        },
        {
          "type": "disorder",
          "metadata": {
            "type": "disorder",
            "end": 47,
            "start": 40,
            "database": "IUPred"
          },
          "end": 39,
          "colour": "#00ffff",
          "start": 40,
          "display": false
        }
      ]
    }
  ],
  "cnv": {
    "gain": {
      "max": 11,
      "range": [
        [
          1,
          265,
          10,
          "https://cancer.sanger.ac.uk/cosmic/cnv/details?all_data=&chr=10&cnv=gain&coords=AA%3AAA&dr=&end=50828295&gd=&id=285572&ln=A1CF&seqlen=603&start=50850665"
        ],
        [
          265,
          603,
          11,
          "https://cancer.sanger.ac.uk/cosmic/cnv/details?all_data=&chr=10&cnv=gain&coords=AA%3AAA&dr=&end=50806880&gd=&id=285572&ln=A1CF&seqlen=603&start=50820552"
        ]
      ]
    },
    "loss": {
      "max": 4,
      "range": [
        [
          1,
          603,
          4,
          "https://cancer.sanger.ac.uk/cosmic/cnv/details?all_data=&chr=10&cnv=loss&coords=AA%3AAA&dr=&end=50806880&gd=&id=285572&ln=A1CF&seqlen=603&start=50850665"
        ]
      ]
    }
  },
  "id": "285572"
}
*/


const TP53 = {
  "cnv": {
    "loss": {
      "max": 18,
      "range": [
        [
          1,
          157,
          18,
          "https://cancer.sanger.ac.uk/cosmic/cnv/details?all_data=&chr=4&cnv=loss&coords=AA%3AAA&dr=&end=4860368&gd=&id=249727&ln=TP53&seqlen=&start=4859666"
        ]
      ]
    },
    "gain": {
      "range": [
        [
          1,
          157,
          21,
          "https://cancer.sanger.ac.uk/cosmic/cnv/details?all_data=&chr=4&cnv=gain&coords=AA%3AAA&dr=&end=4860368&gd=&id=249727&ln=TP53&seqlen=&start=4859666"
        ]
      ],
      "max": 21
    }
  },
  "export": "json",
  "start": 1,
  "cds_seq": "ATGGAGGAGCCGCAGTCAGATCCTAGCGTCGAGCCCCCTCTGAGTCAGGAAACATTTTCAGACCTATGGAAACTACTTCCTGAAAACAACGTTCTGTCCCCCTTGCCGTCCCAAGCAATGGATGATTTGATGCTGTCCCCGGACGATATTGAACAATGGTTCACTGAAGACCCAGGTCCAGATGAAGCTCCCAGAATGCCAGAGGCTGCTCCCCCCGTGGCCCCTGCACCAGCAGCTCCTACACCGGCGGCCCCTGCACCAGCCCCCTCCTGGCCCCTGTCATCTTCTGTCCCTTCCCAGAAAACCTACCAGGGCAGCTACGGTTTCCGTCTGGGCTTCTTGCATTCTGGGACAGCCAAGTCTGTGACTTGCACGTACTCCCCTGCCCTCAACAAGATGTTTTGCCAACTGGCCAAGACCTGCCCTGTGCAGCTGTGGGTTGATTCCACACCCCCGCCCGGCACCCGCGTCCGCGCCATGGCCATCTACAAGCAGTCACAGCACATGACGGAGGTTGTGAGGCGCTGCCCCCACCATGAGCGCTGCTCAGATAGCGATGGTCTGGCCCCTCCTCAGCATCTTATCCGAGTGGAAGGAAATTTGCGTGTGGAGTATTTGGATGACAGAAACACTTTTCGACATAGTGTGGTGGTGCCCTATGAGCCGCCTGAGGTTGGCTCTGACTGTACCACCATCCACTACAACTACATGTGTAACAGTTCCTGCATGGGCGGCATGAACCGGAGGCCCATCCTCACCATCATCACACTGGAAGACTCCAGTGGTAATCTACTGGGACGGAACAGCTTTGAGGTGCGTGTTTGTGCCTGTCCTGGGAGAGACCGGCGCACAGAGGAAGAGAATCTCCGCAAGAAAGGGGAGCCTCACCACGAGCTGCCCCCAGGGAGCACTAAGCGAGCACTGCCCAACAACACCAGCTCCTCTCCCCAGCCAAAGAAGAAACCACTGGATGGAGAATATTTCACCCTTCAGATCCGTGGGCGTGAGCGCTTCGAGATGTTCCGAGAGCTGAATGAGGCCTTGGAACTCAAGGATGCCCAGGCTGGGAAGGAGCCAGGGGGGAGCAGGGCTCACTCCAGCCACCTGAAGTCCAAAAAGGGTCAGTCTACCTCCCGCCATAAAAAACTCATGTTCAAGACAGAAGGGCCTGACTCAGACTGA",
  "expn": {
    "down": {
      "range": [
        [
          1,
          0,
          0,
          "https://cancer.sanger.ac.uk/cosmic/cnv/details?all_data=&chr=&coords=AA%3AAA&dr=&end=&expn=under&gd=&id=249727&ln=TP53&seqlen=&start=1"
        ]
      ]
    },
    "up": {
      "range": [
        [
          1,
          0,
          325,
          "https://cancer.sanger.ac.uk/cosmic/cnv/details?all_data=&chr=&coords=AA%3AAA&dr=&end=&expn=over&gd=&id=249727&ln=TP53&seqlen=&start=1"
        ]
      ]
    },
    "max": 325
  },
  "gene": "TP53",
  "meth": {
    "hyper": {
      "range": [
        [
          -1619888,
          -1619888,
          null,
          "https://cancer.sanger.ac.uk/cosmic/meth/details?all_data=&chr=4&coords=AA%3AAA&dr=&end=&gd=&id=&ln=TP53&meth=H&seqlen=&start="
        ],
        [
          -1619888,
          -1619888,
          null,
          "https://cancer.sanger.ac.uk/cosmic/meth/details?all_data=&chr=4&coords=AA%3AAA&dr=&end=&gd=&id=&ln=TP53&meth=H&seqlen=&start="
        ],
        [
          -1619888,
          -1619888,
          null,
          "https://cancer.sanger.ac.uk/cosmic/meth/details?all_data=&chr=4&coords=AA%3AAA&dr=&end=&gd=&id=&ln=TP53&meth=H&seqlen=&start="
        ],
        [
          208,
          208,
          "1",
          "https://cancer.sanger.ac.uk/cosmic/meth/details?all_data=&chr=4&coords=AA%3AAA&dr=&end=4860288&gd=&id=&ln=TP53&meth=H&seqlen=&start=4860288"
        ],
        [
          -1619888,
          -1619888,
          null,
          "https://cancer.sanger.ac.uk/cosmic/meth/details?all_data=&chr=4&coords=AA%3AAA&dr=&end=&gd=&id=&ln=TP53&meth=H&seqlen=&start="
        ],
        [
          -1619888,
          -1619888,
          null,
          "https://cancer.sanger.ac.uk/cosmic/meth/details?all_data=&chr=4&coords=AA%3AAA&dr=&end=&gd=&id=&ln=TP53&meth=H&seqlen=&start="
        ]
      ]
    },
    "hypo": {
      "range": [
        [
          -1619888,
          -1619888,
          null,
          "https://cancer.sanger.ac.uk/cosmic/meth/details?all_data=&chr=4&coords=AA%3AAA&dr=&end=&gd=&id=&ln=TP53&meth=L&seqlen=&start="
        ],
        [
          -1619888,
          -1619888,
          null,
          "https://cancer.sanger.ac.uk/cosmic/meth/details?all_data=&chr=4&coords=AA%3AAA&dr=&end=&gd=&id=&ln=TP53&meth=L&seqlen=&start="
        ],
        [
          -1619888,
          -1619888,
          null,
          "https://cancer.sanger.ac.uk/cosmic/meth/details?all_data=&chr=4&coords=AA%3AAA&dr=&end=&gd=&id=&ln=TP53&meth=L&seqlen=&start="
        ],
        [
          208,
          208,
          null,
          "https://cancer.sanger.ac.uk/cosmic/meth/details?all_data=&chr=4&coords=AA%3AAA&dr=&end=4860288&gd=&id=&ln=TP53&meth=L&seqlen=&start=4860288"
        ],
        [
          -1619888,
          -1619888,
          null,
          "https://cancer.sanger.ac.uk/cosmic/meth/details?all_data=&chr=4&coords=AA%3AAA&dr=&end=&gd=&id=&ln=TP53&meth=L&seqlen=&start="
        ],
        [
          -1619888,
          -1619888,
          null,
          "https://cancer.sanger.ac.uk/cosmic/meth/details?all_data=&chr=4&coords=AA%3AAA&dr=&end=&gd=&id=&ln=TP53&meth=L&seqlen=&start="
        ]
      ]
    },
    "max": 1
  },
  "mut_url": "https://cancer.sanger.ac.uk/cosmic/mutation/overview",
  "ins": {
    "pos": {
      "123": [
        [
          "p.L123Tfs*52 (c.366dup)",
          "T",
          1,
          "101404408",
          "-"
        ]
      ],
      "28": [
        [
          "p.G28Rfs*147 (c.80dup)",
          "R",
          1,
          "101404328",
          "-"
        ]
      ]
    },
    "max": 3
  },
  "pfam": [
    {
      "metadata": {
        "taxid": "9606",
        "organism": "Homo sapiens (Human)",
        "description": "Cellular tumor antigen p53",
        "database": "uniprot",
        "identifier": "P53_HUMAN",
        "accession": "P04637"
      },
      "length": 393,
      "regions": [
        {
          "startStyle": "straight",
          "modelStart": 1,
          "aliStart": 6,
          "modelEnd": 25,
          "type": "pfama",
          "modelLength": 25,
          "end": 30,
          "aliEnd": 30,
          "href": "/family/PF08563",
          "metadata": {
            "start": 6,
            "database": "pfam",
            "identifier": "P53_TAD",
            "type": "Motif",
            "end": 30,
            "aliEnd": 30,
            "aliStart": 6,
            "scoreName": "e-value",
            "score": "2.6e-08",
            "description": "P53 transactivation motif",
            "accession": "PF08563"
          },
          "colour": "#2dcf00",
          "endStyle": "straight",
          "display": null,
          "text": "P53_TAD",
          "start": 6
        },
        {
          "text": "TAD2",
          "display": null,
          "start": 35,
          "colour": "#ff5353",
          "endStyle": "straight",
          "href": "/family/PF18521",
          "metadata": {
            "start": 35,
            "database": "pfam",
            "identifier": "TAD2",
            "end": 59,
            "type": "Domain",
            "aliEnd": 59,
            "aliStart": 35,
            "scoreName": "e-value",
            "score": "5.2e-14",
            "description": "Transactivation domain 2",
            "accession": "PF18521"
          },
          "type": "pfama",
          "modelLength": 25,
          "end": 59,
          "modelEnd": 25,
          "aliEnd": 59,
          "modelStart": 1,
          "aliStart": 35,
          "startStyle": "straight"
        },
        {
          "aliStart": 100,
          "modelStart": 2,
          "startStyle": "curved",
          "endStyle": "curved",
          "colour": "#5b5bff",
          "start": 99,
          "display": null,
          "text": "P53",
          "aliEnd": 288,
          "type": "pfama",
          "modelLength": 194,
          "end": 289,
          "modelEnd": 193,
          "metadata": {
            "accession": "PF00870",
            "aliStart": 100,
            "score": "1.8e-57",
            "scoreName": "e-value",
            "description": "P53 DNA-binding domain",
            "identifier": "P53",
            "type": "Domain",
            "end": 289,
            "aliEnd": 288,
            "start": 99,
            "database": "pfam"
          },
          "href": "/family/PF00870"
        },
        {
          "colour": "#ebd61d",
          "endStyle": "straight",
          "text": "P53_tetramer",
          "display": null,
          "start": 319,
          "modelLength": 42,
          "type": "pfama",
          "modelEnd": 41,
          "end": 358,
          "aliEnd": 357,
          "href": "/family/PF07710",
          "metadata": {
            "accession": "PF07710",
            "description": "P53 tetramerisation motif",
            "score": "1.2e-14",
            "scoreName": "e-value",
            "aliStart": 319,
            "aliEnd": 357,
            "type": "Motif",
            "end": 358,
            "identifier": "P53_tetramer",
            "database": "pfam",
            "start": 319
          },
          "modelStart": 1,
          "aliStart": 319,
          "startStyle": "straight"
        }
      ],
      "markups": [
        {
          "v_align": "bottom",
          "lineColour": "#333333",
          "residue": "C",
          "type": "Metal ion binding",
          "metadata": {
            "start": 238,
            "database": "UniProt",
            "description": "C Metal ion binding:Zinc"
          },
          "colour": "#ad8bfe",
          "start": 238,
          "headStyle": "circle",
          "display": null
        },
        {
          "colour": "#ad8bfe",
          "start": 179,
          "display": null,
          "headStyle": "circle",
          "type": "Metal ion binding",
          "metadata": {
            "start": 179,
            "database": "UniProt",
            "description": "H Metal ion binding:Zinc"
          },
          "residue": "H",
          "lineColour": "#333333",
          "v_align": "bottom"
        },
        {
          "v_align": "bottom",
          "lineColour": "#333333",
          "residue": "C",
          "metadata": {
            "description": "C Metal ion binding:Zinc",
            "database": "UniProt",
            "start": 242
          },
          "type": "Metal ion binding",
          "display": null,
          "headStyle": "circle",
          "start": 242,
          "colour": "#ad8bfe"
        },
        {
          "start": 176,
          "headStyle": "circle",
          "display": null,
          "colour": "#ad8bfe",
          "metadata": {
            "database": "UniProt",
            "description": "C Metal ion binding:Zinc",
            "start": 176
          },
          "type": "Metal ion binding",
          "lineColour": "#333333",
          "residue": "C",
          "v_align": "bottom"
        }
      ],
      "motifs": [
        {
          "start": 1,
          "display": null,
          "colour": "#cccccc",
          "metadata": {
            "end": 23,
            "type": "disorder",
            "start": 1,
            "database": "IUPred"
          },
          "type": "disorder",
          "end": 5
        },
        {
          "type": "disorder",
          "end": 27,
          "metadata": {
            "end": 27,
            "type": "disorder",
            "start": 25,
            "database": "IUPred"
          },
          "colour": "#00ffff",
          "start": 25,
          "display": false
        },
        {
          "metadata": {
            "end": 31,
            "type": "disorder",
            "database": "IUPred",
            "start": 30
          },
          "end": 31,
          "type": "disorder",
          "display": false,
          "start": 31,
          "colour": "#00ffff"
        },
        {
          "colour": "#cccccc",
          "start": 89,
          "display": null,
          "type": "disorder",
          "end": 97,
          "metadata": {
            "database": "IUPred",
            "start": 33,
            "type": "disorder",
            "end": 97
          }
        },
        {
          "colour": "#00ffff",
          "start": 157,
          "display": false,
          "end": 161,
          "type": "disorder",
          "metadata": {
            "end": 161,
            "type": "disorder",
            "start": 157,
            "database": "IUPred"
          }
        },
        {
          "metadata": {
            "database": "IUPred",
            "start": 174,
            "end": 175,
            "type": "disorder"
          },
          "end": 175,
          "type": "disorder",
          "display": false,
          "start": 174,
          "colour": "#00ffff"
        },
        {
          "colour": "#00ffff",
          "start": 216,
          "display": false,
          "type": "disorder",
          "end": 217,
          "metadata": {
            "end": 217,
            "type": "disorder",
            "start": 216,
            "database": "IUPred"
          }
        },
        {
          "start": 290,
          "display": null,
          "colour": "#cccccc",
          "metadata": {
            "database": "IUPred",
            "start": 281,
            "type": "disorder",
            "end": 329
          },
          "end": 318,
          "type": "disorder"
        },
        {
          "display": null,
          "start": 359,
          "colour": "#cccccc",
          "metadata": {
            "end": 393,
            "type": "disorder",
            "start": 343,
            "database": "IUPred"
          },
          "type": "disorder",
          "end": 393
        },
        {
          "start": 62,
          "display": null,
          "colour": "#86bcff",
          "metadata": {
            "start": 62,
            "database": "segmasker",
            "type": "low_complexity",
            "end": 88
          },
          "end": 88,
          "type": "low_complexity"
        },
        {
          "display": null,
          "start": 33,
          "colour": "#cccccc",
          "metadata": {
            "start": 33,
            "database": "IUPred",
            "type": "disorder",
            "end": 97
          },
          "type": "disorder",
          "end": 34
        },
        {
          "start": 60,
          "display": null,
          "colour": "#cccccc",
          "metadata": {
            "start": 33,
            "database": "IUPred",
            "type": "disorder",
            "end": 97
          },
          "end": 61,
          "type": "disorder"
        }
      ]
    }
  ],
  "end": 0,
  "id": "348585",
  "seqlen": "",
  "indels": {
    "total": 6
  },
  "sub": {
    "max": 4,
    "pos": {
      "242": [
        [
          "p.A242T (c.724G>A)",
          "T",
          1,
          "101404588",
          ""
        ]
      ],
      "281": [
        [
          "p.A281T (c.841G>A)",
          "T",
          1,
          "101404787",
          ""
        ]
      ],
      "195": [
        [
          "p.Q195R (c.584A>G)",
          "R",
          1,
          "101404213",
          ""
        ],
        [
          "p.Q195K (c.583C>A)",
          "K",
          1,
          "101404416",
          ""
        ]
      ],
      "151": [
        [
          "p.F151L (c.453C>G)",
          "L",
          1,
          "101404880",
          ""
        ]
      ],
      "266": [
        [
          "p.A266V (c.797C>T)",
          "V",
          2,
          "101403889",
          ""
        ]
      ],
      "199": [
        [
          "p.I199= (c.597C>T)",
          "I",
          1,
          "101404086",
          ""
        ]
      ],
      "282": [
        [
          "p.A282T (c.844G>A)",
          "T",
          1,
          "101404427",
          ""
        ]
      ],
      "294": [
        [
          "p.H294= (c.882T>C)",
          "H",
          1,
          "101404839",
          ""
        ]
      ],
      "134": [
        [
          "p.A134= (c.402C>A)",
          "A",
          1,
          "101404568",
          ""
        ]
      ],
      "192": [
        [
          "p.R192C (c.574C>T)",
          "C",
          1,
          "101403565",
          ""
        ]
      ],
      "272": [
        [
          "p.Y272* (c.816C>A)",
          "*",
          1,
          "101404127",
          ""
        ]
      ],
      "176": [
        [
          "p.R176W (c.526C>T)",
          "W",
          1,
          "101403829",
          ""
        ],
        [
          "p.R176Q (c.527G>A)",
          "Q",
          3,
          "101403869",
          ""
        ]
      ],
      "153": [
        [
          "p.P153= (c.459G>A)",
          "P",
          1,
          "101404094",
          ""
        ]
      ],
      "46": [
        [
          "p.D46N (c.136G>A)",
          "N",
          2,
          "101404452",
          ""
        ]
      ],
      "296": [
        [
          "p.G296C (c.886G>T)",
          "C",
          1,
          "101404351",
          ""
        ]
      ],
      "298": [
        [
          "p.S298N (c.893G>A)",
          "N",
          1,
          "101404231",
          ""
        ]
      ],
      "170": [
        [
          "p.K170R (c.509A>G)",
          "R",
          1,
          "101404277",
          ""
        ]
      ],
      "224": [
        [
          "p.R224C (c.670C>T)",
          "C",
          1,
          "101403605",
          ""
        ],
        [
          "p.R224= (c.672C>T)",
          "R",
          2,
          "101403658",
          ""
        ]
      ],
      "156": [
        [
          "p.A156V (c.467C>T)",
          "V",
          2,
          "101404177",
          ""
        ]
      ],
      "77": [
        [
          "p.E77* (c.229G>T)",
          "*",
          2,
          "101404460",
          ""
        ]
      ],
      "56": [
        [
          "p.P56S (c.166C>T)",
          "S",
          1,
          "101404038",
          ""
        ]
      ],
      "89": [
        [
          "p.A89= (c.267G>A)",
          "A",
          1,
          "101404443",
          ""
        ]
      ],
      "159": [
        [
          "p.L159= (c.477G>A)",
          "L",
          1,
          "101403689",
          ""
        ]
      ],
      "239": [
        [
          "p.K239N (c.717G>C)",
          "N",
          1,
          "101403516",
          ""
        ]
      ],
      "115": [
        [
          "p.L115= (c.345C>A)",
          "L",
          1,
          "101404266",
          ""
        ],
        [
          "p.L115= (c.345C>T)",
          "L",
          3,
          "101403671",
          ""
        ]
      ],
      "158": [
        [
          "p.R158= (c.474G>A)",
          "R",
          1,
          "101404033",
          ""
        ],
        [
          "p.R158W (c.472C>T)",
          "W",
          1,
          "101403492",
          ""
        ]
      ],
      "270": [
        [
          "p.S270L (c.809C>T)",
          "L",
          2,
          "101403963",
          ""
        ]
      ],
      "268": [
        [
          "p.G268S (c.802G>A)",
          "S",
          1,
          "101404687",
          ""
        ]
      ],
      "260": [
        [
          "p.P260H (c.779C>A)",
          "H",
          1,
          "101403550",
          ""
        ],
        [
          "p.P260T (c.778C>A)",
          "T",
          1,
          "101404066",
          ""
        ]
      ],
      "182": [
        [
          "p.A182T (c.544G>A)",
          "T",
          1,
          "101404779",
          ""
        ]
      ],
      "171": [
        [
          "p.T171= (c.513G>A)",
          "T",
          1,
          "101403880",
          ""
        ],
        [
          "p.T171M (c.512C>T)",
          "M",
          2,
          "101404502",
          ""
        ]
      ],
      "218": [
        [
          "p.I218K (c.653T>A)",
          "K",
          2,
          "101403556",
          ""
        ]
      ],
      "138": [
        [
          "p.E138K (c.412G>A)",
          "K",
          1,
          "101404288",
          ""
        ]
      ],
      "99": [
        [
          "p.P99L (c.296C>T)",
          "L",
          2,
          "101404303",
          ""
        ]
      ],
      "179": [
        [
          "p.F179C (c.536T>G)",
          "C",
          1,
          "101404541",
          ""
        ]
      ],
      "186": [
        [
          "p.A186V (c.557C>T)",
          "V",
          1,
          "101403665",
          ""
        ]
      ],
      "257": [
        [
          "p.L257= (c.771C>T)",
          "L",
          1,
          "101403841",
          ""
        ]
      ],
      "241": [
        [
          "p.A241D (c.722C>A)",
          "D",
          1,
          "101403737",
          ""
        ]
      ],
      "155": [
        [
          "p.P155L (c.464C>T)",
          "L",
          1,
          "101404813",
          ""
        ]
      ],
      "275": [
        [
          "p.S275C (c.824C>G)",
          "C",
          1,
          "101404858",
          ""
        ],
        [
          "p.S275= (c.825T>A)",
          "S",
          1,
          "101403791",
          ""
        ]
      ],
      "189": [
        [
          "p.R189= (c.567C>T)",
          "R",
          1,
          "101404259",
          ""
        ],
        [
          "p.R189H (c.566G>A)",
          "H",
          1,
          "101403723",
          ""
        ]
      ],
      "258": [
        [
          "p.G258= (c.774C>T)",
          "G",
          1,
          "101404706",
          ""
        ]
      ],
      "193": [
        [
          "p.Q193K (c.577C>A)",
          "K",
          1,
          "101404656",
          ""
        ]
      ],
      "4": [
        [
          "p.A4= (c.12T>A)",
          "A",
          1,
          "101403894",
          ""
        ]
      ],
      "167": [
        [
          "p.R167P (c.500G>C)",
          "P",
          2,
          "101403956",
          ""
        ],
        [
          "p.R167C (c.499C>T)",
          "C",
          1,
          "101404693",
          ""
        ]
      ],
      "277": [
        [
          "p.P277L (c.830C>T)",
          "L",
          1,
          "101404575",
          ""
        ],
        [
          "p.P277S (c.829C>T)",
          "S",
          1,
          "101404533",
          ""
        ]
      ],
      "208": [
        [
          "p.S208= (c.624G>A)",
          "S",
          2,
          "101403774",
          ""
        ],
        [
          "p.S208L (c.623C>T)",
          "L",
          2,
          "101404192",
          ""
        ]
      ],
      "34": [
        [
          "p.S34R (c.102C>A)",
          "R",
          2,
          "101404519",
          ""
        ]
      ],
      "200": [
        [
          "p.A200D (c.599C>A)",
          "D",
          1,
          "101403784",
          ""
        ],
        [
          "p.A200P (c.598G>C)",
          "P",
          1,
          "101403948",
          ""
        ]
      ],
      "160": [
        [
          "p.S160N (c.479G>A)",
          "N",
          1,
          "101404605",
          ""
        ]
      ],
      "223": [
        [
          "p.R223C (c.667C>T)",
          "C",
          1,
          "101403618",
          ""
        ]
      ],
      "28": [
        [
          "p.G28= (c.84C>T)",
          "G",
          1,
          "101403921",
          ""
        ]
      ],
      "225": [
        [
          "p.A225T (c.673G>A)",
          "T",
          1,
          "101403644",
          ""
        ]
      ],
      "21": [
        [
          "p.F21L (c.61T>C)",
          "L",
          1,
          "101404671",
          ""
        ]
      ]
    },
    "total": 67
  },
  "res_type": "AA",
  "aa_seq": "MEEPQSDPSVEPPLSQETFSDLWKLLPENNVLSPLPSQAMDDLMLSPDDIEQWFTEDPGPDEAPRMPEAAPPVAPAPAAPTPAAPAPAPSWPLSSSVPSQKTYQGSYGFRLGFLHSGTAKSVTCTYSPALNKMFCQLAKTCPVQLWVDSTPPPGTRVRAMAIYKQSQHMTEVVRRCPHHERCSDSDGLAPPQHLIRVEGNLRVEYLDDRNTFRHSVVVPYEPPEVGSDCTTIHYNYMCNSSCMGGMNRRPILTIITLEDSSGNLLGRNSFEVRVCACPGRDRRTEEENLRKKGEPHHELPPGSTKRALPNNTSSSPQPKKKPLDGEYFTLQIRGRERFEMFRELNEALELKDAQAGKEPGGSRAHSSHLKSKKGQSTSRHKKLMFKTEGPDSD*",
  "del": {
    "pos": {
      "27": [
        [
          "p.G27Efs*133 (c.80del)",
          "E",
          1,
          "101404826",
          "+"
        ]
      ],
      "3": [
        [
          "p.P3Rfs*5 (c.8del)",
          "R",
          1,
          "101404390",
          "+"
        ]
      ],
      "162": [
        [
          "p.P162Qfs*55 (c.485del)",
          "Q",
          3,
          "101403636",
          "+"
        ]
      ],
      "10": [
        [
          "p.L10Cparse error: Invalid numeric literal at line 2, column 0 fs*150 (c.29del)",
          "C",
          1,
          "101404155",
          "+"
        ]
      ]
    },
    "max": 3
  },
  "url": "https://cancer.sanger.ac.uk/cosmic/gene/analysis"
}

export default TP53;
