
/*
 * MODIFIED
 *    $Date: Wed Jun 23 03:00:31 IDT 2021$
 */

import React from 'react';
import Helmet from 'react-helmet';
import ReactDOMServer from 'react-dom/server';

//import construction from './crane-construction-svgrepo-com.svg'
//import email from './email-svgrepo-com.svg'
//import database from './database.svg'
//import MAGELEMBLlogo from './MAGEL2-EMBLlogo.png'
import externalLinkIcon from '../Disease/external-link-ltr-icon.svg';
//import { fundamentals } from '../../config/settings';
import fig1 from './fig1.png';
import backToTop from './up-bow-svgrepo-com.svg';
import './FAQ.css';

const announcements = [{
    img: null,
    short: 'whatIsIt',
    title: 'What is FABRIC and what is this portal about?',
    date: new Date('2020-06-11'),
  content: <><span className="c5">FABRIC (<b>F</b>unctional <b>A</b>lteration <b>B</b>ias <b>R</b>ecovery <b>I</b>n <b>C</b>oding-regions) is a modeling framework for
    detecting and quantifying gene selection in coding regions. In
    the context of cancer, it can analyze somatic mutations to
    identify protein-coding genes under positive selection, namely
    driver genes. The</span> <span className="c7">FABRIC Cancer
    Portal</span><span className="c5">&nbsp;provides a public catalogue
    of coding human genes in cancer based on the FABRIC framework. It
    lists</span> <span className="c5">18,053</span><span className=
    "c5">&nbsp;human protein-coding genes across 33 cancer types (and
    an additional pan-cancer view) based on the genetic data of
    somatic mutations in cancer provided by</span> <span className=
    "c14"><a className="c2" target="_blank" href=
    "https://portal.gdc.cancer.gov" rel="noopener noreferrer">
    TCGA<img src={externalLinkIcon} alt='external link' /></a></span><span className="c5">&nbsp;(over 2.2 million mutations
  in coding regions).</span> 
  <div>For further information on the packages, please refer to the <a href="/Cite">source paper and the GitHub repo</a></div><div style={{ lineHeight: '3em' }}>A gentle introduction to FABRIC is presented in this 10 minute video:</div>
  <div style={{ }}><iframe title="FABRIC intro video" style={{ backgroundColor: '#777', border: '1px solid #ccc', margin: '0 auto', display: 'block' }} width="560" height="315" src="https://www.youtube.com/embed/GUPmRZiLMUw" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> </div>
</>
  },
  {
    img: null,
    short: 'whatSignalDeceted',
    title: 'What kind of genetic signal is detected by FABRIC, and how is it different from other frameworks in detecting cancer genes?',
    date: new Date('2020-06-10'),
    content: <><span className="c3">FABRIC tests genes according to
  the mutations observed in their coding regions. If the mutations
  affecting a gene appear to be more damaging than expected at
  random, the gene is considered to be under positive selection
  (and if they are less damaging than expected, it will be
  considered under negative selection). Unlike most other
  algorithms that aim to detect driver genes, which give much
  weight to the frequency of mutations within each gene, FABRIC
  doesn't consider the number of mutations as evidence. Rather it relies
  on their functional outcome only. FABRIC was designed to provide
  solid, unbiased estimates and minimize false
  discoveries.</span></>
  },
  {
    img: null,
    short: 'howFabricWorks',
    title: 'How does FABRIC work?',
    date: new Date('2020-06-08'),
    content: <><div><span className="c5">(</span><span className="c7">A</span><span className="c5">) First, all the mutations within a
  particular gene are collected from a variety of samples.
  Single-nucleotide variants (SNVs) within protein-coding regions
  are analyzed to study their effects on the protein sequence
  (synonymous, missense or nonsense), while all other variant types
  are discarded. (</span><span className="c7">B</span><span className=
  "c5">) Using <span className="c14">
  <a className="c2" target="_blank" href="https://github.com/nadavbra/firm" rel="noopener noreferrer">FIRM<img src={externalLinkIcon} alt='external link' /></a>
  </span>, the underlying</span> <span className="c14">
  machine-learning model</span>
  <span className="c5">, FABRIC
  assigns each mutation a score for its effect on the protein
  biochemical function, with lower scores indicating mutations that
  are more likely harmful. (</span><span className="c7">C</span><span className="c5">) In parallel, a precise null
  background score distribution is constructed. This background
  distribution describes the effect scores we would expect to see
  at random, if SNVs were distributed uniformly across the gene’s
  coding region, while taking into account the observed nucleotide
  frequencies of the observed mutations (both the reference and
  alternative nucleotide of each mutation). (</span><span className=
  "c7">D</span><span className="c5">) By comparing the observed scores
  to their expected distribution, we calculate</span> <span className=
  "c5">z</span><span className="c5">-values for the mutations, and
  overall</span> <a href="#whatAreTheMetrics"><span className="c5">z</span><span className="c5">-value
    and p-value</span></a><span className="c5"> for the gene (more details on the metrics can be
  found in the relevant section). The complete technical detail and
  analysis of FABRIC are found in</span> <span className=
  "c14"><a className="c2" href="/Cite">
  this publication</a></span><span className="c5">.</span></div>
    <div>A graphical description of the method appears below:</div>
  <div className="c8" style={{ textAlign: 'center', marginTop: 15 }}><span style={{
    overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '1.00px solid #ccc', borderRadius: 5, transform: 'rotate(0.00rad) translateZ(0px)',
    /* -webkit-transform: rotate(0.00rad) translateZ(0px), */
    width: 624, height: 517 }} >
    <img src={fig1} style={{ width: 624, height: 517, marginLeft: 'auto', marginTop: 0, transform: 'rotate(0.00rad) translateZ(0px)', /* -webkit-transform: rotate(0.00rad) translateZ(0px);" */ }} title="" alt='figure 1' /></span></div>
    </>
  },
  {
    img: null,
    short: 'canIuseElsewhere',
    title: 'Can FABRIC be used in other contexts (other than cancer)?',
    date: new Date('2020-06-07'),
    content: <>
  <span className="c5">Yes! FABRIC can take as input any
  collection of variants or mutations, and output the selection signal
  of each gene. For example, it has been used on the genetic
  variants from the</span> <span className="c14">
  <a className="c2" target="_blank" href="https://gnomad.broadinstitute.org/" rel="noopener noreferrer">ExAC/gnomAD database<img src={externalLinkIcon} alt='external link' /></a>
  </span><span className="c5">&nbsp;</span>
  <span className="c5">to quantify the natural
  selection of all human protein-coding genes throughout human
  evolution</span><span className="c3">. It can also be used to analyze
  a list of de-novo or rare mutations (e.g. observed in a cohort of
  trios with probands having a specific disease such as
  autism-spectrum disorders, to detect genes potentially involved
  with the disease).</span>
    </>,
  },
  {
    img: null,
    short: 'howCanIrepurpose',
    title: 'How can I use FABRIC with my own data?',
    date: new Date('2020-06-06'),
    content: <>
  <span className="c5">FABRIC is available on </span>
  <span className="c14"><a className="c2" target="_blank" href=
  "https://github.com/nadavbra/fabric" rel="noopener noreferrer">GitHub<img src={externalLinkIcon} alt='external link' /></a></span><span className="c5">&nbsp;as an open-source
  software with a simple command line interface. After installing
  it, you will be able to analyze any dataset.</span>
    </>
  },
  {
    img: null,
    short: 'whatAreTheMetrics',
    title: 'What metrics do I see across the website?',
    date: new Date('2020-06-05'),
    content: <>
  <div className="c11"><span className="c3">For each gene and cancer type,
  FABRIC provides the relevant summary statistics:</span></div>
  <ul className="c16 lst-kix_ulrvhguwj3ij-0 start">
    <li className="c11 c12"><span className=
    "c7">q-value/p-value</span><span className="c5">: The statistical
    significance of the gene according to FABRIC. The q-value is
    obtained from the p-value following</span> <span className="c14">
    family-wise correction for multiple testing with <a className="c2" target="_blank" href="https://en.wikipedia.org/wiki/False_discovery_rate" rel="noopener noreferrer">
    FDR<img src={externalLinkIcon} alt='external link' /></a></span><span className="c3">.</span></li>
    <li className="c11 c12"><span className="c7">z-value</span><span className=
    "c3">: Measures the selection strength. It is the average
    difference between the observed to expected effect scores for
    the observed mutations (in units of standard-deviations).
    Negative z-values indicate that the observed mutations have
    lower effect scores, namely that they are more damaging to the
    protein function than would be expected at random.</span></li>
    <li className="c11 c12"><span className="c7"># observed
    mutations</span><span className="c3">: The number of
    single-nucleotide variants observed in the coding region of the
    gene. These are the mutations that are analyzed by FABRIC and
    produce all the other metrics.</span></li>
    <li className="c11 c12"><span className="c7">mutation
    frequency</span><span className="c3">: The number of observed
    mutations (as defined above) divided by the length of the
    gene’s coding region (i.e. the sum length of its exons, after
    excluding the 5’ and 3’ UTRs; measured in nucleotides). Note
    that unlike most other frameworks, FABRIC doesn’t take the
    number or frequency of mutations into account (we only report
    this number for better interpretability of the
    results).</span></li>
  </ul>
    </>
  },
  {
    img: null,
    short: 'howToCite',
    title: 'How do I cite this portal?',
    date: new Date('2020-05-30'),
    content: <>
  <span>The portal is not published in a
  journal yet. In the mean time we ask that you cite the <a href='/Cite'>FABRIC framework itself</a></span><span className="c9">.</span>
    </>
  },
  {
    img: null,
    short: 'noGene',
    title: 'I cannot find my gene of interest when browsing the cancer type heat maps',
    date: new Date('2020-06-03'),
    modified: new Date('2020-06-25'),
    content: <>
    <div className="pb-2">
      The heat map for the pan-cancer view or specific cancer types  (e.g. <a href="/disease/BRCA"><b>BRCA</b></a> or <a href="/disease/UCS"><b>UCS</b></a>) will show at most 100 genes, ranked by significance. It is, therefore, likely that your gene of interest won't be listed there. The gene PIK3CA, for example, is quite prominent in a multitude of cancer types, but ranked 6910 when listed in the context of <a href="/disease/OV?focus=P42336">Ovarian Serous Cystadenocarcinoma (<b>OV</b>)</a>. To look for a specific gene, you can simply use the <a href="/search">search page</a>. Once you are on the page of a specific gene (e.g. <a href="/gene/GBA"><b>GBA</b></a>), you can click on a specific cancer-type row (within the table at the bottom of the gene page) to focus on that gene in the heat map.
    </div>
    <div className="pb-2">
      Genes that have no mutations observed in them (<a href="/gene/GAGE12F"><b>GAGE12F</b></a>) may also occur in our database. The fact that no mutations were recorded will be noted on the Gene's page.
    </div>
    </>
  },
  {
    img: null,
    short: 'noGeneSymbol',
    title: 'I cannot find my gene of interest when searching for it',
    date: new Date('2020-06-03'),
    modified: new Date('2020-06-25'),
    content: <>
    <div style={{whiteSpace: 'pre-line'}} className="pb-2">
      The <a href="/search">search page</a> allows free text search for coding human genes, using a variety of identifiers. These identifiers include gene symbol, gene name, UniProt ID, RefSeq ID, and even genomic coordinates (in version hg38/GRCh38). If you haven’t found a specific gene there could be two main reasons for it:
    </div>

      <ol>
	<li>
	  Some genes have multiple symbols. It might happen that you are searching for a gene symbol that is different from the one listed in our database. Try searching for one of the aliases of that  gene symbol, or use an unambiguous identifier such as UniProt ID.
	</li>
	<li>
	  Your gene of interest may not be included in the FABRIC Portal. This can happen, for example, in the rare event when the genomic DNA sequence of a gene doesn't match its amino-acid sequence reported on UniProt. The portal currently lists 18,052 out of the approximate number of 20,000 protein-coding human genes.
	</li>
      </ol>
    </>
  },
  {
    img: null,
    short: 'UCSC',
    title: 'My gene of interest is in a different assembly, like human hg19 or NCBI36 (hg18)',
    date: new Date('2020-06-02'),
    modified: new Date('2020-06-25'),
    content: <>
    <div>
      The database detects gene IDs in several formats, such as RefSeq, UniProt ID, Gene Symbol or even UCSC genomic coordinates.</div>
    <div>When using your assembly of choice, the coordinates you present on the portal must adhere to UCSC's hg38 assembly. Conversion methods exist on the web and
      you are responsible to lift these genomic coordinates over to/from hg38:</div>
    <div className="tab-promoted">
      To convert from your assembly to hg38 use
      <ul style={{ listStyle: 'none' }}>
	<li>In Kent tools <a href="https://genome.ucsc.edu/cgi-bin/hgLiftOver" target="_blank" rel="noopener noreferrer">liftOver<img src={externalLinkIcon} alt='external link' /></a> select <b>hg38</b> as the target (under <i>New Assembly</i>).</li>
	<li>In NCBI's <a href="https://www.ncbi.nlm.nih.gov/genome/tools/remap" target="_blank" rel="noopener noreferrer">remap<img src={externalLinkIcon} alt='external link' /></a> the selected target genome (<i>Target Assembly</i>) should be <b>GRCh38 (hg38)</b>.</li>
      </ul>
      later, you can key the resulting coordinates inside the <a href="/search">search</a> box to locate the gene.
    </div>
    </>
  },
  {
    img: null,
    short: 'developerEmail',
    title: 'I have other questions. How do I get hold of the developers or the research team?',
    date: new Date('2020-06-01'),
    content: <>
    <div>Any inquiries about the FABRIC Cancer portal <i>or</i> the FABRIC framework can be addressed to the contact points listed <a href="/Contact">here</a>.
    </div>
    </>
  },
  {
    img: null,
    short: 'areThereNegative',
    title: 'Are there any cancer-demoting genes at all?',
    date: new Date('2020-06-01'),
    content: <>
    <span>Yes! Genes exist where internal mutations cause a positive effect on the health of the patient by reducing the damage or risk of cancer. Examples are scarce, but still exist. Here's one: <a href="/gene/FLG2"><b>FLG2</b></a>
    </span></>
  }

]


const [ textUpdated, ...rest ] = announcements
          .filter(({modified}) => modified)
	  .sort( (a,b) => b.modified - a.modified)
	  .map( ({modified}) => modified );

const htmlToText = (html) => {
    //remove code brakes and tabs
  html = html
    .replace(/\n/g, "")
    .replace(/\t/g, "")

    //keep html brakes and tabs
    .replace(/<\/td>/g, "\t")
    .replace(/<\/table>/g, "\n")
    .replace(/<\/tr>/g, "\n")
    .replace(/<\/p>/g, "\n")
    .replace(/<\/div>/g, "\n")
    .replace(/<\/h>/g, "\n")
    .replace(/<br>/g, "\n")
    .replace(/<br( )*\/>/g, "\n");

    //parse html into text
    var dom = (new DOMParser()).parseFromString('<!doctype html><body>' + html, 'text/html');
    return dom.body.textContent;
}

const HEAD = () => (
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": announcements
	.sort( (a,b) => b.date - a.date)
	.map( ({img, title, date, content, short},i) => {
	  const tmpString = ReactDOMServer.renderToString(content)
	  return(
	  {
	    "@type" : "Question",
	    "name": title,
	    "acceptedAnswer": {
		"@type": "Answer",
		"text": tmpString
	    }
	  })
	}
	)
    }
);

const FAQ = () => <>
  <Helmet
    script=
    {[
      { type : "application/ld+json",
	innerHTML : JSON.stringify(HEAD())
      }]} >
  </Helmet>
  <div className="fabricWatermark">
  <h1 className="h4" style={{ marginTop: 10 }}>Frequently Asked Questions</h1>
  <div className="fabricPageContainer">
    <h4>FAQ <font style={{ fontSize: 9 }}>updated {textUpdated.toDateString()}</font></h4>
    <ul className="faqList">
      { announcements
	  .sort( (a,b) => b.date - a.date)
	  .map( ({img, title, date, content, short},i) =>
	    <li key={`0${i}`} style={{ marginBottom: 5 }}><a href={`#${short}`}><b>{title}</b></a></li>
	  )
      }
    </ul>
    <h4>&nbsp;</h4>
  <hr/>
  <br/>
    <ul style={{ listStyleType: 'none' }}>
      { announcements
	  .sort( (a,b) => b.date - a.date)
	  .map( ({img, title, date, content, short},i) =>
	<li  style={{ width: '95%' }} key={i} id={short}> <strong>{title}</strong>&nbsp;
	<div style={{ textAlign: 'justify', width: '95%' }}>{content}</div>
	<br/>
	<a className="backToTop" href="#top"><span  style={{ backgroundImage: `url(${backToTop})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>&nbsp;&nbsp;&nbsp;</span> Top</a>
	<hr/>
      </li>
      )}
    </ul>
  </div>;
</div></>;

export default FAQ;
