/*
 * MODIFIED
 *    $Date: Sun Jul 26 00:00:34 IDT 2020$
 */

//import React from 'react';
import _ from 'lodash';

// sort an array where the lowercase elements are listed first,
// then the uppercase ones
const sortLC1st = (a, b) => {
    if ( (a[0] === a[0].toLocaleLowerCase() && b[0] === b[0].toLocaleLowerCase() ) ||
        ( a[0] === a[0].toLocaleUpperCase() && b[0] === b[0].toLocaleUpperCase() )) {
        return a.localeCompare(b);
    }
    if (a[0] === a[0].toLocaleLowerCase()) {
        return -1;
    }
    return 1;
};

// note this is not a general converter - it creates triplets of
// columns for the expected 3 parameters of each gene-disease
const convertToCSV = ( tableData, delimiter = ',') => {
  if (!Array.isArray(tableData) || !tableData.length) {
    throw new Error('Table data should be a non-empty array of column/value rows');
  }

  const colNames = [
		      ...new Set( tableData
		      .map( _.keys )
		      .flat() )
		   ].sort( sortLC1st );

  const colWidth = 4 // width (in csv columns) of each disease data
  const cw = colWidth // abbr
  const a = new Array(colNames.filter( o => /^[A-Z]/.test(o) ).length*cw).fill(0)
  const b = colNames.filter( o => /^[A-Z]/.test(o) )
  const c = a.map( (r,i) => i % cw === (cw-1) ? b[parseInt(i/cw)] : "" )
  const newColNames = colNames.filter( o => /^[a-z]/.test(o) ) + c
  console.log('CCCCCC=',newColNames)

  const cols = [] // additional column line
  const rows = tableData.map((rowData,i) => {
    const row = [];
    colNames.forEach(key => {
      // I noticed that members column is an array, not sure how you handle it
      // but here it joins the members with comma and wraps the whole thing in
      // double quotes
      const rd = rowData[key]
      if (_.isArray(rd) && rd.length) {
	if (i === 0) cols.push(delimiter.repeat(rd.length - 1));
	row.push(rd.join(delimiter));
      } else if (_.isObject(rd) && !_.isEmpty(rd)) {
	if (i === 0) cols.push(_.keys(rd).join(delimiter));
	row.push(_.values(rd).join(delimiter));
      } else if (rd === undefined) {
	if (i === 0) cols.push(delimiter.repeat(cw-1));
	row.push(delimiter.repeat(cw-1));
      } else {
	if (i === 0) cols.push('');
	row.push(JSON.stringify(rd));
      }
    })
    return row.join(delimiter);
  });
  rows.unshift(cols.join(delimiter));
  rows.unshift(newColNames);
  return rows.join("\n")
}



export default convertToCSV;
