/*
 * MODIFIED
 *    $Date: $
 */

import React from 'react';

const Loading = ({
  subject, ...rest
}) => (
  <div className="WIP-box" {...rest} >Loading {subject}...</div> 
);

export default Loading;
