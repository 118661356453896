/*
 * MODIFIED
 *    $Date: Sat Jul 25 23:56:01 IDT 2020$
 */

//import React from 'react';
import { useLocation } from 'react-router-dom';

// A custom hook that builds on useLocation to parse
// the query string for you.
export default function useQuery() {
  return new URLSearchParams(useLocation().search);
}

