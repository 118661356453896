/* @flow */

/*
 * This file lists the actions to retrieve data aggregates from the
 * database. It makes read-only calls to the loopback API
 *
 * MODIFIED
 *   $Date: Mon Mar 30 00:57:34 IDT 2020$
 *
 */

import axios from 'axios';

import type { Dispatch, GetState, ThunkAction, ReduxState } from '../types';

// const API_URL = 'https://jsonplaceholder.typicode.com/users';

const shuffleArr = arr => {
  const ind = [...Array(arr.length).keys()].sort(() => 0.5 - Math.random());
  return ind.map(i => arr[ind[i]]);
};

// Export this for unit testing more easily
/* istanbul ignore next */
export const fetchTotals = ({
  URI,
  prefix = '',
  shuffle = false
}): ThunkAction => async (dispatch: Dispatch) => {
  dispatch({ type: `${prefix}TOTALS_REQUESTING`, prefix });

  try {
    const { data } = await axios.get(URI);

    let data_ = data;
    if (data_) {
      if (!data_.length) {
        // no data for this experiment
        // throw { type: 'myException', message: 'no data'}; // generates an exception
        dispatch({ type: `${prefix}TOTALS_SUCCESS`, prefix, data: data_ });
        return;
      }

      if (shuffle) {
        // generate a random sample of indices in the array
        data_ = shuffleArr(data_);
      }

      // if (size > 0) data_ = data_.slice(0, size);
    }
    // else dispatch with the non-manipulated data
    /* istanbul ignore next */
    dispatch({ type: `${prefix}TOTALS_SUCCESS`, prefix, data: data_ });
  } catch (err) {
    /* istanbul ignore next */
    dispatch({ type: `${prefix}TOTALS_FAILURE`, prefix, err: err.message });
  }
};

/* istanbul ignore next */
const shouldFetchTotals = (state: ReduxState, prefix = ''): boolean => {
  console.log('ACTIONS state', state);
  const pattern = new RegExp(`^${prefix}TOTALS_(REQUESTING|SUCCESS)`);
  const ind = state.totals.readyStatus.findIndex(p => pattern.test(p));
  if (ind >= 0) return false;

  return true;
};

/* istanbul ignore next */
export const fetchTotalsIfNeeded = (opts = { prefix: 'obs' }): ThunkAction => (
  dispatch: Dispatch,
  getState: GetState
) => {
  const { api } = getState();
  const API_URL = `${api.apiHost}${api.apiUrl}/totals/n_obs/`;
  let URI = API_URL;
  let override = false; // true - always get a fresh set of images
  const query = [];
  if (opts) {
    URI = `${opts.URL ? opts.URL : API_URL}`;
    // const token = opts.token ? `access_token=${opts.token}` : '';
    //query.push(token);
    //query.push(opts.filter);
    //URI += query.join('&');
    override = opts.override ? opts.override : false;
  }
  console.log('query:', query);
  console.log('opts:', opts);
  // const prefix = opts && opts.prefix ? opts.prefix : 'RECORDS';
  /* istanbul ignore next */
  if (override || shouldFetchTotals(getState(), opts.prefix))
    return dispatch(fetchTotals({ URI, ...opts }));

  /* istanbul ignore next */
  return new Promise(res => res(new Error('no totals this time')));
};


/* istanbul ignore next */
export const fetchTotalGenesIfNeeded = (opts = { prefix: 'genes', disease: 'NULL' }): ThunkAction => (
  dispatch: Dispatch,
  getState: GetState
) => {
  const { api } = getState();
  const API_URL = `${api.apiHost}${api.apiUrl}/totals/n_gene/`; // ${opts.disease}/`;
  let URI = API_URL;
  let override = false; // true - always get a fresh set of images
  const query = [];
  if (opts) {
    URI = `${opts.URL ? opts.URL : API_URL}`;
    // const token = opts.token ? `access_token=${opts.token}` : '';
    //query.push(token);
    //query.push(opts.filter);
    //URI += query.join('&');
    override = opts.override ? opts.override : false;
  }
  console.log('query:', query);
  console.log('opts:', opts);
  // const prefix = opts && opts.prefix ? opts.prefix : 'RECORDS';
  /* istanbul ignore next */
  if (override || shouldFetchTotals(getState(), opts.prefix))
    return dispatch(fetchTotals({ URI, ...opts }));

  /* istanbul ignore next */
  return new Promise(res => res(new Error('no totals this time')));
};
