/*
 * MODIFIED
 *    $Date: Wed Jun 23 02:58:41 IDT 2021$
 */

import React from "react";
import {
  Navbar, Nav, NavDropdown
} from "react-bootstrap";
//import logo from './fabric-cloth.jpg';
import Helmet from 'react-helmet';
// from https://www.google.com/search?q=fabric+cloth&tbm=isch&ved=2ahUKEwiEnoXMzY3oAhUVG-wKHbgdAeMQ2-cCegQIABAA&oq=fabric+cloth&gs_l=img.3..0l10.854.854..1251...0.0..0.108.108.0j1......0....1..gws-wiz-img.GWPtVrEWhNI&ei=GVNmXsStJJW2sAe4u4SYDg&bih=707&biw=1379&rlz=1C5CHFA_enIL728IL728#imgrc=2JlUIqSi5mnPcM
import { useLocation, useRouteMatch } from "react-router";
import { useHistory } from "react-router-dom";
import "./Header.css";
import magnifierLogo from './magnifier.svg';
import questionLogo from './questionMark.png';

const AboutIcon = <div style={{ display: "inline-block" }}><img style={{ width:14 }} src={questionLogo} alt='about'/>About</div>;
const Header = () => {
  let location = useLocation();
  let history = useHistory();
  let match = useRouteMatch();
  console.log('LOCAT', location);

  // find the components of the route for setting the page title
  let pretty = location.pathname.match(/\/g\/([^/]+)([/]([^/]+))?/,);
  if (pretty !== null && pretty.length > 0)
    pretty = pretty.filter(a => !/\//.test(a));
  else pretty = [ 'home' ];

  return (
    <>
    <Helmet
      title={["FABRIC Cancer Portal - ", ...pretty].join(' ')}
      meta={[ {
	name: 'og:twitter-description', content: "FABRIC cancer portal: a visual representation of the FABRIC framework results for genes that promote or demote cancer. Cancer types are given by TCGA classification"
      } ]}
    >
      {` /*
      <meta keywords="FABRIC cancer, cancer, amino acid, synonymous mutation, nonsynonymous mutation, somatic mutations, primary site, variation, functional alteration, positive selection, search a gene, search for a gene, Pan-cancer, Breast Cancer, BRCA, Breast Invasive Carcinoma, Lung Cancer, LUAD, Lung Adenocarcinoma, Colon Cancer, COAD, Colon Adenocarcinoma, Brain Cancer, Brain Tumor, LGG, Brain Lower Grade Glioma, TCGA, coding sequence, coding region, protein coding genes, stringdb, cancer.sanger.ac.uk, COSMIC, Gene Cards, Gene Symbol" />
      <meta name="description" content="FABRIC cancer portal: a visual representation of the FABRIC framework results for genes that promote or demote cancer. Cancer types are given by TCGA classification" />
	  <meta name="google-site-verification" content="q0ECdE7ZKeunM3HHgZkCLTzKu4zhkHQXqUVDQIneMX8" /> */ `}
    </Helmet>
      <Navbar bg="light" variant="light">
        <Navbar.Brand href={`${match.url}`}><span>FABRIC</span>
        </Navbar.Brand>
        <Nav
          activeKey="/"
          onSelect={selectedKey => {
            console.log(`selected ${selectedKey}`);
	    if ( /^Contact|Cite|Crossref|FAQ$/.test(selectedKey) )
	      history.push(`/${selectedKey}`);
          }
          }
        >
          <Nav.Item>
            <Nav.Link
	      href="/disease/PanC"
	      disabled={ /\/g\/disease\/PanC/.test(location.pathname) }
              eventKey="link-1"
            >Cancer types</Nav.Link>
          </Nav.Item>
	  { /*<Nav.Item>
            <Nav.Link
	      href="/gene/KRAS"
	      disabled={ /\/g\/gene\/KRAS/.test(location.pathname) }
              eventKey="link-2"
            >Genes</Nav.Link>
	  </Nav.Item> */ }
          <Nav.Item>
	    <Nav.Link
	      href="/search"
	      eventKey="search"
	      disabled={ location.pathname.match(/\/g\/search/i) }
	    ><img className="svgLogoNav" src={magnifierLogo} alt='search' />Search a gene</Nav.Link>
          </Nav.Item>
	  <NavDropdown title={AboutIcon} id="nav-dropdown">
            <NavDropdown.Item eventKey="Contact">Contact us</NavDropdown.Item>
            <NavDropdown.Item eventKey="Cite">Cite us</NavDropdown.Item>
            <NavDropdown.Item eventKey="Crossref">Cross references</NavDropdown.Item>
            <NavDropdown.Item eventKey="FAQ">FAQ section</NavDropdown.Item>
            { /* <NavDropdown.Item eventKey="4.3">Something else here</NavDropdown.Item>
    <NavDropdown.Divider />
    <NavDropdown.Item eventKey="4.4">Separated link</NavDropdown.Item> */ }
          </NavDropdown>

        </Nav>
      </Navbar>
      { history.length > 0 &&
    <div className="header-button"><button onClick={history.goBack}>&lt; Go Back</button></div> }
    </>
  );
};

export default Header;
