/*
 * MODIFIED
 *    $Date: Sat Jul 25 19:54:09 IDT 2020$
 */

import React, { Component, Fragment } from 'react';

import Select from 'react-select';

// import { Note } from './styled-components';
import './select.css';
// import PropTypes from 'prop-types';

const colourOptions = [
  { value: 'ACC', label: 'ACC', color: '#5243AA' },
  { value: 'BLCA', label: 'BLCA', color: '#0052CC', isDisabled: false },
  { value: 'BRCA', label: 'BRCA', color: '#00B8D9', isFixed: true },
  { value: 'CESC', label: 'CESC', color: '#5243AA' },
  { value: 'CHOL', label: 'CHOL', color: '#5243AA' },
  { value: 'COAD', label: 'COAD', color: '#5243AA' },
  { value: 'DLBC', label: 'DLBC', color: '#5243AA' },
  { value: 'ESCA', label: 'ESCA', color: '#5243AA' },
  { value: 'GBM', label: 'GBM', color: '#5243AA' },
  { value: 'HNSC', label: 'HNSC', color: '#5243AA' },
  { value: 'KICH', label: 'KICH', color: '#5243AA' },
  { value: 'KIRC', label: 'KIRC', color: '#5243AA' },
  { value: 'KIRP', label: 'KIRP', color: '#5243AA' },
  { value: 'LAML', label: 'LAML', color: '#5243AA' },
  { value: 'LGG', label: 'LGG', color: '#5243AA' },
  { value: 'LIHC', label: 'LIHC', color: '#5243AA' },
  { value: 'LUAD', label: 'LUAD', color: '#5243AA' },
  { value: 'LUSC', label: 'LUSC', color: '#5243AA' },
  { value: 'MESO', label: 'MESO', color: '#5243AA' },
  { value: 'OV', label: 'OV', color: '#5243AA' },
  { value: 'PAAD', label: 'PAAD', color: '#5243AA' },
  { value: 'PCPG', label: 'PCPG', color: '#5243AA' },
  { value: 'PRAD', label: 'PRAD', color: '#5243AA' },
  { value: 'READ', label: 'READ', color: '#5243AA' },
  { value: 'SARC', label: 'SARC', color: '#5243AA' },
  { value: 'SKCM', label: 'SKCM', color: '#5243AA' },
  { value: 'STAD', label: 'STAD', color: '#5243AA' },
  { value: 'TGCT', label: 'TGCT', color: '#5243AA' },
  { value: 'THCA', label: 'THCA', color: '#5243AA' },
  { value: 'THYM', label: 'THYM', color: '#5243AA' },
  { value: 'UCEC', label: 'UCEC', color: '#5243AA' },
  { value: 'UCS', label: 'UCS', color: '#5243AA' },
  { value: 'UVM', label: 'UVM', color: '#5243AA' },

];

// const Checkbox = props => <input type="checkbox" {...props} />;

type State = {
  isClearable: boolean,
  isDisabled: boolean,
  isLoading: boolean,
  isRtl: boolean,
  isSearchable: boolean,
  selected: Object
};

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    height: state.selectProps.height,
    borderBottom: '1px dotted pink',
    color: state.selectProps.menuColor,
    padding: 20,
  }),

  /*
  control: (_, { selectProps: { width }}) => ({
    width: '200px'
  }), */

  valueContainer: (_, { selectProps: { width }}) => ({
    width: '100px'
  }),

  indicatorsContainer: (_, { selectProps: { width }}) => ({
    width: '200px'
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}

export default class SingleSelect extends Component<*, State> {

  constructor (props) {
    super(props)
    this.state = {
      isClearable: false,
      isDisabled: false,
      isLoading: false,
      isRtl: false,
      isSearchable: true,
      selected: props.selected ? { value: props.selected, label: props.selected } : null
    };
  }

  /*
  toggleClearable = () =>
    this.setState(state => ({ isClearable: !state.isClearable }));
  toggleDisabled = () =>
    this.setState(state => ({ isDisabled: !state.isDisabled }));
  toggleLoading = () =>
    this.setState(state => ({ isLoading: !state.isLoading }));
  toggleRtl = () => this.setState(state => ({ isRtl: !state.isRtl }));
  toggleSearchable = () =>
    this.setState(state => ({ isSearchable: !state.isSearchable }));
  */

  handleInputChange = (newValue) => {
    const { getSelected } = this.props
    //const inputValue = newValue.replace(/\W/g, '');
    this.setState({ selected: newValue });
    if (getSelected) getSelected(newValue.value)
    return newValue;
  };

  render() {
    const {
      isClearable,
      isSearchable,
      isDisabled,
      isLoading,
      isRtl,
      selected
    } = this.state;
    const { title } = this.props
    const thisSelected = (selected) ? selected : colourOptions[0];
    return (
      <Fragment>
	<strong>{title || 'disease'}</strong>
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={thisSelected}
          isDisabled={isDisabled}
          isLoading={isLoading}
          isClearable={isClearable}
          isRtl={isRtl}
          isSearchable={isSearchable}
          name="color"
          options={colourOptions}
	  onChange={this.handleInputChange}
        />
	{/*
        <Note Tag="label">
          <Checkbox
            checked={isClearable}
            onChange={this.toggleClearable}
            id="cypress-single__clearable-checkbox"
          />
          Clearable
        </Note>
        <Note Tag="label" style={{ marginLeft: '1em' }}>
          <Checkbox
            checked={isSearchable}
            onChange={this.toggleSearchable}
            id="cypress-single__searchable-checkbox"
          />
          Searchable
        </Note>
        <Note Tag="label" style={{ marginLeft: '1em' }}>
          <Checkbox
            checked={isDisabled}
            onChange={this.toggleDisabled}
            id="cypress-single__disabled-checkbox"
          />
          Disabled
        </Note>
        <Note Tag="label" style={{ marginLeft: '1em' }}>
          <Checkbox
            checked={isLoading}
            onChange={this.toggleLoading}
            id="cypress-single__loading-checkbox"
          />
          Loading
        </Note>
        <Note Tag="label" style={{ marginLeft: '1em' }}>
          <Checkbox
            type="checkbox"
            checked={isRtl}
            onChange={this.toggleRtl}
            id="cypress-single__rtl-checkbox"
          />
          RTL
	</Note> */}
      </Fragment>
    );
  }
}


