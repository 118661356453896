/*
 * MODIFIED
 *    $Date: $
 */

import SearchPage from './SearchPage.js';

export default SearchPage;


