/* @flow */

/*
 *
 * WARNING: THIS IS A COMPLETE HACK
 *   We need a way to pass the API url from the server to the client.
 *   The current infrastructure takes configuration info from a
 *   file named config/default.js. The data there is processed on
 *   the server and the client separately, so the environment
 *   variables from package.json, that target the client, cannot
 *   be set there because the client reads empty sets. So, we have
 *   hacked the store's initial state so that the API url's are
 *   passed into window.__INITIAL_STATE__ (in utils/renderHtml)
 *   using the tag 'api', and when the store is created on the
 *   client side, we use this reducer to announce to redux that it
 *   should expect a reducer named 'api'. This file is a mock up
 *   since there are no actions associated with the 'api' reducer.
 *
 *   Adapted from Genetics/vistav3-curation-site
 *
 * CREATED
 *   Wed Jan 30 20:04:44 PST 2019
 *
 * MODIFIED
 *   $Date: Sun Mar 15 03:48:10 IST 2020$
 *
 */
import _ from 'lodash';

import type { Home, Action } from '../types';

type State = Home;

const isDev = process.env.NODE_ENV === 'development';
const apiHost = isDev ? 'http://localhost:8000' : '';
let api;
api = { apiHost, apiUrl: '/api' }

const initialState = {
  readyStatus: 'API_SUCCESS',
  err: null,
  list: [],
  ...api
};

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'API_REQUESTING':
      return _.assign({}, state, {
        readyStatus: 'API_REQUESTING'
      });
    case 'API_FAILURE':
      return _.assign({}, state, {
        readyStatus: 'API_FAILURE',
        err: action.err
      });
    case 'API_SUCCESS':
      return _.assign({}, state, {
        readyStatus: 'API_SUCCESS',
        list: [action.data]
      });
    default:
      return state;
  }
};
